import { ref } from "vue";
import { defineStore } from "pinia";
export const userNotificationStore = defineStore(
  "notificationStore",
  () => {
    const userNotificationCount = ref("");

    function setNotificationCount(val) {
      userNotificationCount.value = val;
    }
    return { userNotificationCount, setNotificationCount };
  },
  {
    persist: true,
  }
);
