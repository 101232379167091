import { defineStore } from 'pinia'
// import { getPlatforms } from '@ionic/vue';

//  import { useMqttStore } from '../stores/mqtt'
//  import { useRouter } from "vue-router";

export const useWelcomeStore = defineStore({
  id: 'welcome',
  state: () => ({
    platform: 'web',
    showWelcome:true,
    showLaunchPad:true, 
  }),
  actions: {
    setShowWelcome(val)
    {
        this.showWelcome = val;
    },
    setShowLaunchPad(val)
    {
        this.showLaunchPad = val;
    },
    logout() {
      
    }
  },
  persist: true, 
},
)

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
// }
