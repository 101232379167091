<template>
  <ion-grid>
    <ion-row style="">
      <ion-col
        size="6"
        v-for="(item, index) in boxData"
        :key="index"
        :value="item"
      >
        <basicbox :data="item"></basicbox>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { IonGrid, IonRow, IonCol, loadingController } from "@ionic/vue";
import basicbox from "../components/rpmMainBasicbox.vue";

import { useMqttStore } from "../stores/mqtt";

export default defineComponent({
  name: "MainBasic",
  components: { basicbox, IonGrid, IonRow, IonCol },
  setup() {
    const mqttStore = useMqttStore();
    const activityVal = ref(mqttStore.patientMetrics?.AVGTEMP);

    const showLoading = async () => {
      const loading = await loadingController.create({
        message: "Loading...",
        //duration: 3000,
        spinner: "circles",
        id: "loadingController",
      });
      loading.present();
    };

    onMounted(() => {
      if (activityVal.value == 0 || activityVal.value == undefined)
        showLoading();

      const toggle = document.querySelector("ion-tab-bar");
      // console.log('Toggle123');
      if (toggle !== null && toggle.selectedTab == "") {
       // console.log("In basic box view page.");
        toggle.setAttribute("selected-tab", "home");
      }
    });

    return { mqttStore, showLoading, activityVal };
  },
  watch: {
    activityVal: {
      handler: function (val, oldVal) {
        // console.log('Watch mypatients val' + JSON.stringify(val));
        console.log("Watch mystaff oldval" + JSON.stringify(oldVal));
        // console.log(oldVal);
        if (val > 0) {
          const toggle = document.querySelector("#loadingController");
          if (toggle !== null) toggle.dismiss();
          const tab = document.querySelector("ion-tab-bar");
          if (tab !== null) {
            tab.setAttribute("selected-tab", "home");
          }
        }
      },

      deep: true,
    },
  },
  computed: {
    boxData() {
      let bd = [];
      if (this.mqttStore.patientMetrics) {
        const activityValue = ref(this.mqttStore.patientMetrics.AVGTEMP);

        const aVGSPO2Value = ref(this.mqttStore.patientMetrics.AVGSPO2);
        const aVGHEARTRATEValue = ref(
          this.mqttStore.patientMetrics.AVGHEARTRATE
        );
        const aVGRESPRATE = ref(this.mqttStore.patientMetrics.AVGRESPRATE);
        const aVGBP = ref(this.mqttStore.patientMetrics.AVGBP);

        if (
          activityValue.value > 0 ||
          aVGSPO2Value.value > 0 ||
          aVGHEARTRATEValue.value > 0 ||
          aVGRESPRATE.value > 0 ||
          aVGBP.value > 0
        ) {
          const toggle = document.querySelector("#loadingController");
          if (toggle !== null) toggle.dismiss();

          const tab = document.querySelector("ion-tab-bar");
          if (tab !== null && tab.selectedTab == "") tab.setAttribute("selected-tab", "home");
        }

        const activityValue7dayAverage = ref(
          this.mqttStore.messages.patientData7?.t[0]?.y
        );
        const aVGSPO2Value7dayAverage = ref(
          this.mqttStore.messages.patientData7?.spo2[0]?.y
        );
        const aVGHEARTRATEValue7dayAverage = ref(
          this.mqttStore.messages.patientData7?.hr[0]?.y
        );
        const aVGRESPRATE7dayAverage = ref(
          this.mqttStore.messages.patientData7?.rr[0]?.y
        );
        const aVGBP7dayAverage = ref(
          this.mqttStore.messages.patientData7?.bp[0]?.y
        );

        bd = [
          {
            id: 1,
            abbrevation: "t",
            icon: require("../assets/Activity.svg"),
            firstLabel: "Activity",
            secondLabel:
              activityValue.value == "" || activityValue.value == undefined
                ? 0
                : activityValue.value,
            thirdLabel:
              activityValue7dayAverage.value == "" ||
              activityValue7dayAverage.value == undefined
                ? "avg 0"
                : "avg " + activityValue7dayAverage?.value,
            navigateTo: "/boxDescription",
          },
          {
            id: 2,
            abbrevation: "spo2",
            icon: require("../assets/OxygenSaturation.svg"),
            firstLabel: "Oxygen Saturation",
            secondLabel:
              aVGSPO2Value.value == "" || aVGSPO2Value.value == undefined
                ? 0
                : aVGSPO2Value.value,
            thirdLabel:
              aVGSPO2Value7dayAverage.value == "" ||
              aVGSPO2Value7dayAverage.value == undefined
                ? "avg 0"
                : "avg " + aVGSPO2Value7dayAverage?.value,
            navigateTo: "/boxDescription",
          },
          {
            id: 3,
            abbrevation: "hr",
            icon: require("../assets/heartrateIcon.svg"),
            firstLabel: "Heart Rate",
            secondLabel:
              aVGHEARTRATEValue.value == "" ||
              aVGHEARTRATEValue.value == undefined
                ? 0
                : aVGHEARTRATEValue.value,
            thirdLabel:
              aVGHEARTRATEValue7dayAverage.value == "" ||
              aVGHEARTRATEValue7dayAverage.value == undefined
                ? "avg 0"
                : "avg " + aVGHEARTRATEValue7dayAverage?.value,
            navigateTo: "/boxDescription",
          },
          {
            id: 4,
            abbrevation: "sd",
            icon: require("../assets/SleepDuration.svg"),
            firstLabel: "Sleep Duration",
            secondLabel: "23",
            thirdLabel:
              aVGHEARTRATEValue7dayAverage.value == "" ||
              aVGHEARTRATEValue7dayAverage.value == undefined
                ? "avg 0"
                : "avg " + aVGHEARTRATEValue7dayAverage?.value,
            navigateTo: "/boxDescription",
          },
          {
            id: 5,
            abbrevation: "rr",
            icon: require("../assets/RespiratoryRate.svg"),
            firstLabel: "Respiratory Rate",
            secondLabel:
              aVGRESPRATE.value == "" || aVGRESPRATE.value == undefined
                ? 0
                : aVGRESPRATE.value,
            thirdLabel:
              aVGRESPRATE7dayAverage.value == "" ||
              aVGRESPRATE7dayAverage.value == undefined
                ? "avg 0"
                : "avg " + aVGRESPRATE7dayAverage?.value,
            navigateTo: "/boxDescription",
          },
          {
            id: 6,
            abbrevation: "bp",
            icon: require("../assets/blood-pressure.svg"),
            firstLabel: "Blood Pressure",
            secondLabel:
              aVGBP.value == "" || aVGBP.value == undefined ? 0 : aVGBP.value,
            thirdLabel:
              aVGBP7dayAverage.value == "" ||
              aVGBP7dayAverage.value == undefined
                ? "avg 0"
                : "avg " + aVGBP7dayAverage?.value,
            navigateTo: "/boxDescription",
          },
        ];
      }
      //console.log('bd',bd)
      return bd;
    },
  },
});
</script>
<style scoped>
.ion-grid-row {
  background: #f5f5f5;
  height: 80%;
}
</style>
