<template>


   <!-- <ion-header> -->
  <ion-toolbar style="margin-top: 50px;">
    <ion-title> Digital Triage Assistant </ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" @click="closeModal">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <!-- </ion-header>  -->
  <ion-content
    ref="content"
    @ionScroll="onScroll"
    :scroll-y="true"
    :scrollEvents="true"
  >
    <ion-list v-if="messages && messages.length > 0">
      <ion-item
        lines="none"
        v-for="message in messages"
        :key="message.timestamp"
        :message="message"
      >
        <ion-avatar v-if="message.PICTURE" slot="start">
          <img :src="message.PICTURE" />
        </ion-avatar>
        <ion-label
          v-if="message.contentType && message.contentType == 'PlainText'"
          class="ion-text-wrap"
          >{{ message.text }}</ion-label
        >
        <ion-card
          v-else-if="
            message.contentType && message.contentType == 'ImageResponseCard'
          "
        >
          <ion-card-header>
            <ion-card-title>{{ message.card.title }}</ion-card-title>
            <ion-card-subtitle v-if="message.card.subtitle">{{
              message.card.subtitle
            }}</ion-card-subtitle>
            <ion-card-content>
              <ion-chip
                size="small"
                fill="solid"
                color="primary"
                @click="onRespondeCardButtonClick(button)"
                v-for="button in message.card.buttons"
                :key="button.value"
                >{{ button.text }}</ion-chip
              >
            </ion-card-content>
          </ion-card-header>
        </ion-card>
        <ion-label v-else class="ion-text-wrap">{{ message.text }}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label style="minheight: 250px"></ion-label>
      </ion-item>
    </ion-list>
  </ion-content> 
    <ion-footer>
    <ion-toolbar>
      <ion-item>
        <ion-input
          @keydown.enter="onSendClick"
          v-model="typedInput"
          placeholder="type message"
        ></ion-input>
        <ion-button @click="onSendClick">
          <ion-icon name="send"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import { defineComponent } from "vue";
import {
  LexRuntimeV2Client,
  RecognizeTextCommand,
} from "@aws-sdk/client-lex-runtime-v2";

import {
  IonButtons,
  //IonButton,
  IonAvatar,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonFooter,
  IonContent,
  IonChip,
  IonButton,
  IonInput,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue";

import { v4 as uuidv4 } from "uuid";
import { useMqttStore } from "@/stores/mqtt";
import { useUserStore } from "@/stores/user";
import { useKsqlStore } from '../stores/KSQL';

export default defineComponent({
  name: "digitalTriageAssistant",
  components: {
    IonButtons,
    //IonButton,
    IonAvatar,
    IonIcon,
    IonTitle,
    IonToolbar,
    IonFooter,
    IonContent,
    IonChip,
    IonButton,
    IonInput,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonList,
    IonItem,
    IonLabel,
  },
  props: {
    message: Object,
    setShowData: {
      type: Function,
    },
  },
  setup() {
    const ksqlStore = useKsqlStore();
    const mqttStore = useMqttStore();
    const userStore = useUserStore();

    function closeModal()
    {
      const toggle = document.querySelector("#DigitalModal");
      if (toggle !== null)      
        toggle.dismiss();      
      
      //console.log('toggle' + toggle);
    }

    return {
      mqttStore,
      userStore,
      closeModal,
      ksqlStore
    };
  },
  data() {
    return {
      lexClient: null,
      lexKey: "AKIA2VAYXL24VIX5XFK2",
      lexSecret: "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",
      lexRegion: "us-east-1",
      lexSessionId: "",
      lexSessionAttributes: null,
      botName: "SD9NBPLGX7",
      botAlias: "AXM639OM47",
      messages: [],
      typedInput: "",
    };
  },
  watch: {
    message(newVal) {
      console.log('newVal' + JSON.stringify(newVal));
      this.lexSessionId = newVal.lexSessionId;
      this.messages = null;
      this.messages = [];
      let m = {
        text: newVal.UTTERANCE,
        userId: newVal.USER_ID,
        username: newVal.NAME,
        picture: newVal.PICTURE,
        lexSessionId: newVal.lexSessionId,
      };
      this.messages.push(m);
      this.sendToLex(m);
    },
  },
  mounted() {
    this.initLex();
    let vm = this;
    console.log("Mounted Props" + JSON.stringify(vm.$props.message));
    if (vm.$props.message !== null) {
      this.lexSessionId = vm.$props.message.lexSessionId;
      this.messages = null;
      this.messages = [];
      let m = {
        text: vm.$props.message.UTTERANCE,
        userId: vm.$props.message.USER_ID,
        username: vm.$props.message.NAME,
        picture: vm.$props.message.PICTURE,
        lexSessionId: vm.$props.message.lexSessionId,
      };
      this.messages.push(m);
      this.sendToLex(m);
    }
  },
  methods: {
    onScroll(e) {
      console.log(e);
    },
    scrollToBottom() {
      // extra ion-item at end is required because this doesn't scroll up enough the see all of last one
      this.$refs.content.$el.scrollToBottom(500);
    },
    publishDigitalTriageAction(topic, m) {
      // const topic = 'actions/'+m.userId

      this.mqttStore.publishMqtt(topic, m);
    },
    initLex() {
      //console.log('lex user',this.lexUserId)
      const awsCredentials = {
        credentials: {
          accessKeyId: this.lexKey,
          secretAccessKey: this.lexSecret,
        },
        region: this.lexRegion,
      };
      this.lexClient = new LexRuntimeV2Client(awsCredentials);
      //console.log('lex client',this.lexClient)
      //this.startConversation('High Alert for Patient '+this.lexUserId)
    },
    async sendToLex(m) {
      if (m.text == "")
      return;
      let input = {
        botId: this.botName,
        botAliasId: this.botAlias,
        sessionId: m.lexSessionId,
        localeId: "en_US",
        text: m.text,
      };
      //console.log('sendtolex',input)
      const command = new RecognizeTextCommand(input);
      const response = await this.lexClient.send(command);
      //console.log('lex reponse',response)
      // console.log('lex state',response.sessionState.intent.state)
      /*  content: "Would you like to review the patient's vitals and medical records?"
     contentType: "PlainText"
     imageResponseCard: undefined */

      if (response.messages && response.messages.length > 0) {
        response.messages.forEach((message) => {
          let m;
          if (message.contentType == "PlainText") {
            m = {
              contentType: message.contentType,
              text: message.content,
            };
          } else if (message.contentType == "ImageResponseCard") {
            m = {
              contentType: message.contentType,
              card: message.imageResponseCard,
            };
          }

          this.messages.push(m);
          this.scrollToBottom();

          // calling actions
          this.ksqlStore.pullActions(this.userStore.userId);

        });
      }
      /* Valid Values: Failed | Fulfilled | InProgress | ReadyForFulfillment | Waiting | FulfillmentInProgress*/
      if (response.sessionState.intent.state === "InProgress") {
        console.log("InProgress");
      }
      if (response.sessionState.intent.state === "Waiting") {
        console.log("Waiting");
      }
      if (response.sessionState.intent.state === "ReadyForFulfillment") {
        console.log("ReadyForFulfillment");
      }
      if (response.sessionState.intent.state === "FulfillmentInProgress") {
        console.log("FulfillmentInProgress");
      }
      if (response.sessionState.intent.state === "Fulfilled") {
        console.log("Fullfilled");
      }
      if (response.sessionState.intent.state === "Failed") {
        console.log("Failed");
      }

      if (response.sessionState.sessionAttributes.loadData == "true") {
        // this.loadPatientData(true)
      } else {
        //this.loadPatientData(false)
      }
      let riskLevel = "";
      let userId = "";
      if (response.sessionState.intent.name == "TriageAssistant") {
        if (response.sessionState.intent.slots) {
          if (response.sessionState.intent.slots.riskLevel) {
            if (
              response.sessionState.intent.slots.riskLevel.value.originalValue
            ) {
              riskLevel =
                response.sessionState.intent.slots.riskLevel.value
                  .originalValue;
            }
          }
          if (response.sessionState.intent.slots.userId) {
            if (response.sessionState.intent.slots.userId.value.originalValue) {
              userId =
                response.sessionState.intent.slots.userId.value.originalValue;
            }
          }
          if (response.sessionState.intent.slots.HighRiskActions) {
            if (
              response.sessionState.intent.slots.HighRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.HighRiskActions.value
                  .originalValue
              );
            }
          }
          if (response.sessionState.intent.slots.ModerateRiskActions) {
            if (
              response.sessionState.intent.slots.ModerateRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.ModerateRiskActions.value
                  .originalValue
              );
            }
          }
          if (response.sessionState.intent.slots.MediumRiskActions) {
            if (
              response.sessionState.intent.slots.MediumRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.MediumRiskActions.value
                  .originalValue
              );
            }
          }
          if (response.sessionState.intent.slots.LowRiskActions) {
            if (
              response.sessionState.intent.slots.LowRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.LowRiskActions.value
                  .originalValue
              );
            }
          }
        }
      }
    },
    /*  loadPatientData(val) {
        this.setShowData(val)
    }, */
    processTreatmentAction(userId, riskLevel, choice) {
      let m = {
        id: uuidv4(),
        userId: userId,
        riskLevel: riskLevel,
        action: choice,
        status: "New",
        staffUser: this.userStore.userId,
        timestamp: Date.now(),
      };
      console.log("lex process treatment", m);
      let topic = `digitaltriage_actions/${userId}`;
      //console.log('lex publish action',topic)
      this.publishDigitalTriageAction(topic, JSON.stringify(m));
    },
    onSendClick() {
      // console.log('typed',this.typedInput)
      let m = {
        text: this.typedInput,
        lexSessionId: this.lexSessionId,
      };

      this.messages.push(m);
      this.sendToLex(m);
      this.typedInput = "";
    },
    onRespondeCardButtonClick(button) {
      let m = {
        text: button.value,
        lexSessionId: this.lexSessionId,
      };
      this.messages.push(m);
      this.sendToLex(m);
    },
  },
});
</script>

<style scoped>
</style>
