<template>
  <ion-content class="content-transparent">
    <v-form @submit="onSubmit" :initialValues="formData">
      <ion-grid>
        <ion-row style="text-align: center">
          <ion-col size="10">
            <ion-label class="Topheading">Scheduling a meeting</ion-label>
          </ion-col>
          <ion-col size="2">
            <ion-icon :icon="closeCircle" @click="dismiss" style="zoom: 1.5"> </ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-list v-if="this.loggedInUserRole == 'staff'">
        <ion-item>
          <ion-label class="heading">Add Doctor</ion-label>
          <ion-toggle
            slot="end"
            @ionChange="enableDoctor($event)"
            v-model="this.isDoctorEnabled"
          ></ion-toggle>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-label style="margin-top: 5px">{{
            this.loggedInUserRole == "staff" ? "Patient" : "Staff"
          }}</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label class="heading">{{
            this.scheduleStore.selectedSchedule?.NICKNAME
          }}</ion-label>
        </ion-item>
      </ion-list>
      <ion-list v-if="this.isDoctorEnabled">
        <ion-list-header>
          <ion-label style="margin-top: 5px">Doctor's details</ion-label>
        </ion-list-header>

        <ion-item>
          <v-field name="Doctordetails" v-slot="{ field }" :rules="isRequired">
            <ion-textarea
              v-bind="field"
              name="Doctordetails"
              placeholder="Enter doctor details here"
              :auto-grow="true"
            ></ion-textarea>
          </v-field>
        </ion-item>
        <v-error-message as="div" name="Doctordetails" v-slot="{ message }" class="error">
          <div v-if="message">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ `Error: Doctor Details ${message}` }}
          </div>
        </v-error-message>
      </ion-list>
      <ion-list v-if="this.isDoctorEnabled">
        <ion-list-header>
          <ion-label style="margin-top: 5px">Doctor's location</ion-label>
        </ion-list-header>
        <ion-item>
          <v-field name="Doctorlocation" v-slot="{ field }" :rules="isRequired">
            <ion-textarea
              v-bind="field"
              placeholder="Enter doctor location here"
              :auto-grow="true"
            ></ion-textarea>
          </v-field>
        </ion-item>
        <v-error-message
          as="div"
          name="Doctorlocation"
          v-slot="{ message }"
          class="error"
        >
          <div v-if="message">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ `Error: Doctor location ${message}` }}
          </div>
        </v-error-message>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-label style="margin-top: 5px">From</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-input
            :value="
              DateTime.fromISO(this.selectedFromDate, {
                setZone: false,
              }).toLocaleString(DateTime.DATETIME_MED)
            "
            id="fromdate"
            style="--padding-start: 13px"
          ></ion-input>
          <ion-popover trigger="fromdate" size="cover">
            <ion-datetime
              :is-date-enabled="isWeekday"
              :min="currentDate"
              v-model="this.selectedFromDate"
              :show-default-buttons="true"
            ></ion-datetime>
          </ion-popover>

          <!-- </button> -->
          <!-- <ion-datetime :is-date-enabled="isWeekday" :min="currentDate"></ion-datetime> -->
          <!-- <v-field name="dateFrom" v-slot="{ field }">
            <ion-datetime-button
              displayFormat="MM.DD.YYYY"
              datetime="datetimeFrom"
            ></ion-datetime-button>
            <ion-modal :keep-contents-mounted="true">
              <ion-datetime
                displayFormat="MM.DD.YYYY"
                name="dateFrom"
                v-bind="field"
                @ionChange="selectFromDate($event)"
                :is-date-enabled="isWeekday"
                id="datetimeFrom"
                :min="currentDate"
                v-model="this.selectedFromDate"
              ></ion-datetime>
            </ion-modal>
          </v-field> -->
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-label style="margin-top: 5px">To</ion-label>
        </ion-list-header>

        <ion-item>
          <ion-input
            :value="
              DateTime.fromISO(this.selectedToDate, {
                setZone: true,
              }).toLocaleString(DateTime.DATETIME_MED)
            "
            id="toDate"
            style="--padding-start: 13px"
          ></ion-input>
          <ion-popover trigger="toDate" size="cover">
            <ion-datetime
              :is-date-enabled="isWeekday"
              :min="currentDate"
              v-model="this.selectedToDate"
              :show-default-buttons="true"
            ></ion-datetime>
          </ion-popover>
          <!-- <ion-datetime-button
            displayFormat="MM.DD.YYYY"
            datetime="datetimeTo"
          ></ion-datetime-button>
          <ion-modal :keep-contents-mounted="true">
            <ion-datetime
              displayFormat="MM.DD.YYYY"
              @ionChange="selectToDate($event)"
              id="datetimeTo"
              :is-date-enabled="isWeekday"
              :min="currentDate"
              v-model="this.selectedToDate"
            ></ion-datetime>
          </ion-modal>-->
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-label style="margin-top: 5px">Comments (If any)</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-textarea
            placeholder="Type something here"
            :auto-grow="true"
            v-model="this.commentsText"
          ></ion-textarea>
        </ion-item>
      </ion-list>
      <ion-list lines="none" style="text-align: center">
        <ion-button
          type="submit"
          style="--border-radius: 40px; width: 70%"
          :disabled="submitButtonDisabled"
        >
          <ion-label class="button">Submit</ion-label>
        </ion-button>
      </ion-list>
    </v-form>
  </ion-content>
</template>

<script>
import { closeCircle } from "ionicons/icons";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { defineComponent, ref } from "vue";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
import { useScheduleStore } from "../stores/schedule";
import * as V from "vee-validate/dist/vee-validate";
import successModal from "../components/SuccessModalPopup.vue";
import { useKsqlStore } from "../stores/KSQL";
import {
  IonIcon,
  IonToggle,
  IonListHeader,
  IonTextarea,
  IonDatetime,
  // IonDatetimeButton,
  //IonModal,
  IonItem,
  IonList,
  IonContent,
  IonInput,
  IonButton,
  IonGrid,
  IonLabel,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";

export default defineComponent({
  name: "schedulingModal",
  components: {
    IonIcon,
    IonCol,
    IonLabel,
    IonToggle,
    IonListHeader,
    IonTextarea,
    IonDatetime,
    IonInput,
    IonItem,
    IonList,
    IonContent,
    IonButton,
    IonGrid,
    IonRow,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  methods: {
    onSubmit(a) {
      let userdetails;
      let topic = "";

      userdetails = this.scheduleStore.selectedSchedule;

      if (userdetails == null || userdetails == undefined) {
        // error while saving the record
        console.log("There is some error while saving the user details.");
        return;
      }

      let selectFromDateLocale = DateTime.fromISO(this.selectedFromDate, {
        setZone: false,
      });

      let selectToDateLocale = DateTime.fromISO(this.selectedToDate, {
        setZone: false,
      });
      let message = {
        id: uuidv4(),
        PATIENTUSERID:
          this.userStore.role == "staff"
            ? userdetails.USERID
            : this.userStore.user["com.rpmone.app/userId"],
        patient: this.userStore.role == "staff" ? userdetails : this.userStore.user,
        staff: this.userStore.role == "staff" ? this.userStore.user : userdetails,
        STAFFUSERID:
          this.userStore.role == "staff"
            ? this.userStore.user["com.rpmone.app/userId"]
            : userdetails.STAFFUSERID,
        comments: this.commentsText,
        meetingStartTime: selectFromDateLocale,
        meetingEndTime: selectToDateLocale,
        timestamp: Date.now(),
        type: "ScheduleNotification",
      };

      // const serverOffset = new Date().getTimezoneOffset();
      // const serverOffsetMillis = 60 * 1000 * serverOffset;
      // console.log(serverOffsetMillis);

      // const da = Date.now();
      // console.log(da);

      Object.defineProperty(message.staff, "STAFFUSERID", {
        value: message.STAFFUSERID,
      });

      Object.defineProperty(message.patient, "PATIENTUSERID", {
        value: message.PATIENTUSERID,
      });

      if (this.loggedInUserRole == "staff" && this.isDoctorEnabled) {
        (message.Doctordetails = a.Doctordetails !== undefined ? a.Doctordetails : ""),
          (message.Doctorlocation =
            a.Doctorlocation !== undefined ? a.Doctorlocation : "");
      }

      let options = {
        qos: 1,
      };

      topic =
        this.loggedInUserRole == "staff"
          ? `schedule/${message.PATIENTUSERID}`
          : `schedule/${message.STAFFUSERID}`;
      message.topic = topic;

      // notification message
      let notificationMessage = {
        USERID:
          this.userStore.role == "staff" ? message.PATIENTUSERID : message.STAFFUSERID,
        NOTIFICATION: message,
        TIMESTAMP: message?.timestamp,
        STATUS: "open",
        NOTIFICATIONTYPE: message.type,
      };

      this.mqttStore.mqttClient.publish(
        topic,
        JSON.stringify(message),
        options,
        (err) => {
          if (err) {
            console.log(err);
            console.log("this.mqttStore.mqttClient.publish" + err);
            // open success modal pop up
            // this.openModal('Failure', 'There is some issue in Scheduling the meeting! Please contact Administrator.');
            // return;
          } else {
            if (this.loggedInUserRole == "staff") {
              // notification topic
              let notificationTopic = `notifications/${message.PATIENTUSERID}`;
              this.mqttStore.mqttClient.publish(
                notificationTopic,
                JSON.stringify(notificationMessage),
                options,
                (err) => {
                  if (err) {
                    console.log(err);
                    console.log("this.mqttStore.mqttClient.publish" + err);
                  } else {
                    console.log(
                      "notification sent to patient " + notificationMessage.userId
                    );
                  }
                }
              );
            } else if (this.loggedInUserRole == "patient") {
              let notifyTopic = `notifications/${message.STAFFUSERID}`;
              this.mqttStore.mqttClient.publish(
                notifyTopic,
                JSON.stringify(notificationMessage),
                options,
                (err) => {
                  if (err) {
                    console.log(err);
                    console.log("this.mqttStore.mqttClient.publish" + err);
                  } else {
                    console.log(
                      "notification sent to Staff " + notificationMessage.userId
                    );
                  }
                }
              );
            }

            // open success modal pop up
            this.openModal("Success", "Meeting details has been successfully submitted!");

            // clear all fields
            this.isDoctorEnabled = false;
            this.selectedUser = "";
            this.commentsText = "";
            this.selectedFromDate = DateTime.now();
            this.selectedToDate = DateTime.now();

            if (this.loggedInUserRole == "staff") {
              console.log(
                `calling this.ksqlStore.pullScheduleStaff(${message.STAFFUSERID});`
              );
              this.ksqlStore.pullScheduleStaff(message.STAFFUSERID);
            } else {
              console.log(
                `calling this.ksqlStore.pullSchedulePatient(${message.STAFFUSERID});`
              );
              this.ksqlStore.pullSchedulePatient(message.PATIENTUSERID);
            }

            const toggle = document.querySelector("ion-tab-bar");
            toggle.setAttribute("selected-tab", "scheduling");

            //return;
          }
        }
      );

      // console.log(a);
    },

    async openModal(headingText, contentText) {
      const modal = await modalController.create({
        id: "successModal",
        component: successModal,
        cssClass: "modalSuccess",
        animated: true,
        componentProps: {
          heading: headingText,
          content: contentText,
        },
      });
      modal.present();

      modal.onDidDismiss().then((data) => {
        var d = JSON.stringify(data);
        console.log("Dismiss Data " + d);
        return;
      });
    },

    enableDoctor(obj) {
      this.isDoctorEnabled = obj.detail.checked;
    },
    getloggedInUserRole() {
      let loggedInUserRole = this.userStore.user["com.rpmone.app/roles"][0];
      if (loggedInUserRole.includes("patient")) return false;

      return true;
    },
    selectUser(obj) {
      this.selectedUser = obj.detail.value;
    },
    selectFromDate(obj) {
      this.selectedFromDate = obj.detail.value;
    },

    selectToDate(obj) {
      //console.log("ToF Date val" + JSON.stringify(obj.detail.value));\
      this.selectedToDate = obj.detail.value;
    },
    dismiss() {
      return modalController.dismiss(null, "dismiss");
    },
    confirm() {
      return modalController.dismiss(this.name, "confirm");
    },
  },
  watch: {
    selectedUser: {
      handler: function (val) {
        if (val == "" || val === undefined) {
          this.submitButtonDisabled = true;
          return;
        } else if (this.selectedFromDate >= this.selectedToDate) {
          this.submitButtonDisabled = true;
          return;
        }
        this.submitButtonDisabled = false;
      },

      deep: true,
    },
    selectedFromDate: {
      handler: function (val) {
        if (this.selectedUser == "" || this.selectedUser === undefined) {
          this.submitButtonDisabled = true;
          return;
        } else if (val >= this.selectedToDate) {
          this.submitButtonDisabled = true;
          return;
        }
        this.submitButtonDisabled = false;
      },

      deep: true,
    },
    selectedToDate: {
      handler: function (val) {
        if (this.selectedUser == "" || this.selectedUser === undefined) {
          this.submitButtonDisabled = true;
          return;
        } else if (val <= this.selectedFromDate) {
          this.submitButtonDisabled = true;
          return;
        }
        this.submitButtonDisabled = false;
      },

      deep: true,
    },
  },
  mounted() {
    var mypatients = this.mqttStore.messages.events.ui.staff.mypatients;
    if (mypatients.length > 0) {
      this.staffPatients.value = [
        ...new Map(mypatients.map((item) => [item["USER_ID"], item])).values(),
      ];
      // console.log(
      //   "this.mypatientDistinctList.value",
      //   JSON.stringify(this.staffPatients.value)
      // );
    }
    let loggedInUserRole = this.userStore.user["com.rpmone.app/roles"][0];
    if (loggedInUserRole.includes("patient")) {
      this.loggedInUserRole = "patient";
      this.selectPlaceHolderText = "Select Staff";
    } else if (loggedInUserRole.includes("staff")) {
      this.loggedInUserRole = "staff";
      this.selectPlaceHolderText = "Select Patient";
    }

    // console.log('this.mqttStore.messages.events.ui.staff.staffPatients' + JSON.stringify(this.mqttStore.messages.events.ui.staff.staffPatients));
    // console.log('this.mqttStore.messages.events.ui.patient.patientstaff' + JSON.stringify(this.mqttStore.messages.events.ui.patient.patientstaff));
  },
  unmounted() {
    console.log("on unmounted scheduling modal pop up.");
    const toggle = document.querySelector("ion-tab-bar");
    toggle.setAttribute("selected-tab", "scheduling");
  },
  data() {
    return {
      commentsText: "",
      selectPlaceHolderText: "",
    };
  },
  setup() {
    // toFormat('MM-dd-yyyy')
    const ksqlStore = useKsqlStore();
    const userStore = useUserStore();
    const scheduleStore = useScheduleStore();
    const mqttStore = useMqttStore();
    const currentDate = ref(DateTime.now());
    const selectedUser = ref(scheduleStore.selectedSchedule);
    const selectedFromDate = ref(DateTime.now());
    const selectedToDate = ref(DateTime.now());
    const isDoctorEnabled = ref(false);
    const submitButtonDisabled = ref(true);
    const comment = ref("");
    const loggedInUserRole = ref("");
    const staffPatients = ref([]);
    const mindate = () => {
      const date = DateTime.now();
      return date;
    };

    const currDate = ref(DateTime.now());

    // custom validator
    const isRequired = (value) => {
      //console.log("isRequired", value);
      if (!value) {
        return "is a required field";
      }
      return true;
    };

    const isWeekday = (dateString) => {
      const date = new Date(dateString);
      const utcDay = date.getUTCDay();
      //  var previousDay = new Date(date.getDate() - 1);

      /**
       * Date will be enabled if it is not
       * Sunday or Saturday
       */
      return utcDay !== 0 && utcDay !== 6;
    };

    console.log(currentDate);
    //console.log(mindate);

    return {
      modalController,
      mindate,
      isWeekday,
      currentDate,
      submitButtonDisabled,
      selectedUser,
      selectedFromDate,
      selectedToDate,
      mqttStore,
      userStore,
      isDoctorEnabled,
      comment,
      isRequired,
      loggedInUserRole,
      staffPatients,
      ksqlStore,
      DateTime,
      scheduleStore,
      currDate,
      closeCircle,
      // onSubmit
    };
  },
});
</script>
<style scoped>
ion-input {
  --padding-start: 13px;
  font-family: "OpenSansRegular";
  font-size: 15px;
  background-color: var(--ion-color-step-300, #edeef0);
  border-radius: 8px;
  text-transform: capitalize;
}

ion-datetime {
  box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;
  font-family: "OpenSansRegular";
  font-size: 15px;
}

ion-list-header {
  font-size: 18px;
  font-family: "OpenSansRegular";
}

.heading {
  font-size: 18px;
  font-family: "OpenSansRegular";
  font-weight: 700;
}

.Topheading {
  font-size: 20px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
}

ion-textarea {
  font-size: 16px;
  font-family: "OpenSansRegular";
}

.button {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}
</style>
