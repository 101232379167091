<template>
  <ion-app>
    <ion-header>
      <ion-toolbar>
        <img slot="start" src="assets/rpmone_gold48.png" @click="openModal" />
        <ion-buttons slot="end">
          <ion-avatar
            v-if="userStore?.isAuthenticated"
            @click="setOpen(true)"
            :style="{ height: '48px', width: '48px' }"
          >
            <img
              v-if="userStore.user && userStore.user.picture"
              :src="userStore.user.picture"
            />
          </ion-avatar>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-tabs
        name="myTabsNav"
        v-if="
          userStore.isAuthenticated &&
          userStore.tabs &&
          userStore.tabs.length > 0
          
        "
      >
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom" v-if="!welcomeStore.showWelcome">
          <ion-tab-button
            v-for="tab in userStore.tabs"
            :key="tab.tab"
            :tab="tab.tab"
            :href="tab.href"            
          >
            <ion-icon :icon="tab.icon"> </ion-icon>
            <ion-badge
              v-if="
                (this.mqttStore.messages.notifications?.length > 0) &&
                tab.label == 'Notifications'
              "
              id="notifications-badge"
              color="danger"
              >{{ this.mqttStore.messages.notifications.length }}</ion-badge
            >

            <!-- {{ this.mqttStore.messages.notifications }} -->
            <ion-badge
              v-if="
                (this.mqttStore.messages.notifications.filter(x => x.NOTIFICATIONTYPE == 'chatNotification')?.length > 0) &&
                tab.label == 'Chat'
              "
              id="notifications-badge"             
              color="danger"
              >{{ this.mqttStore.messages.notifications.filter(x => x.NOTIFICATIONTYPE == "chatNotification")?.length }}</ion-badge
            >

            <ion-label>{{ tab.label }}</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>

      <ion-modal :is-open="isOpen">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button color="primary" @click="logoff">Logout</ion-button>
            </ion-buttons>
            <ion-title>User Profile</ion-title>
            <ion-buttons slot="end">
              <ion-button color="primary" @click="setOpen(false)"
                >Close</ion-button
              >
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <UserProfile />
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-app>
</template>

<script>
import "../public/assets/css/modalpopup.css";
import "../public/assets/css/modal-success.css";
import "../public/assets/css/modal-alert.css";
import "../public/assets/css/modalPatientVital.css";
import "../public/assets/css/modal-SkinTone.css";
import "../public/assets/css/modal-calendarEvent.css";
import { userThemeStore } from "../src/stores/theme";
import { useAuth0 } from "@auth0/auth0-vue";
import { Browser } from "@capacitor/browser";
import { App as CapApp } from "@capacitor/app";
import UserProfile from "./components/UserProfile.vue";
import modalpopup from "./components/rpmMainModalPopUp.vue";
import { userNotificationStore } from "./stores/notification";
import { useIonRouter } from "@ionic/vue";

import {
  IonModal,
  IonTitle,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonAvatar,
  IonApp,
  IonRouterOutlet,
  IonTabs,
  IonTabButton,
  IonTabBar,
  IonIcon,
  IonLabel,
  modalController,
  IonBadge,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useUserStore } from "./stores/user";
import { useWelcomeStore } from "./stores/welcome";
import { useMqttStore } from "./stores/mqtt";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonRouterOutlet,
    IonTabs,
    IonTabButton,
    IonTabBar,
    IonIcon,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonAvatar,
    IonModal,
    IonTitle,
    UserProfile,
    IonBadge,
  },
  setup() {
    const store = userNotificationStore();
    const {
      user,
      isAuthenticated,
      buildLogoutUrl,
      logout,
      buildAuthorizeUrl,
      handleRedirectCallback,
      loginWithRedirect,
      idTokenClaims,
      error,
    } = useAuth0();

    const userStore = useUserStore();
    const mqttStore = useMqttStore();
    const ionRouter = useIonRouter();
    const themeStore = userThemeStore();
    const welcomeStore = useWelcomeStore();

   // console.log('WelcomeStore' + JSON.stringify(welcomeStore));

    //const auth0Redirect = window.location.origin //web or local ionic serve
    //const auth0Redirect = 'com.rpmone.app://rpmone.us.auth0.com/capacitor/com.rpmone.app/callback' //native mobile

    userStore.setPlatform();
    return {
      ionRouter,
      store,
      userStore,
      mqttStore,
      user,
      isAuthenticated,
      buildAuthorizeUrl,
      handleRedirectCallback,
      loginWithRedirect,
      idTokenClaims,
      error,
      themeStore,
      welcomeStore,
      async login() {
        if (userStore.platform == "web") {
          //await loginWithRedirect()
          //authguard om routes redirects to login
          this.setUser();
        } else {
          const url = await buildAuthorizeUrl();

          await Browser.open({
            url,
          });
          CapApp.addListener("appUrlOpen", async ({ url }) => {
            if (
              url.includes("state") &&
              (url.includes("code") || url.includes("error"))
            ) {
              await handleRedirectCallback(url);
            }
            await Browser.close();
            console.log("call set user");
            this.setUser();
          });
        }
      },
      async openModal() {
        if (userStore.role == "staff") return;
        const modal = await modalController.create({
          component: modalpopup,
          cssClass: "modal",
        });
        modal.present();

        modal.onDidDismiss().then((data) => {
          //const user = data['data']; // Here's your selected user!
          var d = JSON.stringify(data);
          console.log("Dismiss Data " + d);
          return;
        });
      },
      async logOut() {
        let logoutUri;
        if (userStore.platform == "web") {
          console.log("logoutweb");
          logout({
            returnTo: window.location.origin,
          });
        } else {
          console.log("logoutnative");
          logoutUri =
            "com.rpmone.app://rpmone.us.auth0.com/capacitor/com.rpmone.app/callback";

          await Browser.open({
            url: buildLogoutUrl({
              returnTo: logoutUri,
            }),
          });

          // Ask the SDK to log out locally, but not do the redirect
          logout({
            localOnly: true,
          });
        }
      },
    };
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    if (this.themeStore.darkTheme) {
      document.body.classList.toggle("dark", true);
    } else {
      document.body.classList.toggle("light", true);
    }
    this.login();

    
  },
  methods: {
    async closeChatNotification() {      
      if (this.mqttStore.messages?.notifications !== undefined || this.mqttStore.messages?.notifications?.length > 0) {
        let result  = this.mqttStore.messages.notifications.filter(x => x.NOTIFICATIONTYPE == 'chatNotification');
        let len = result?.length;
        if (len == 0)
        return;
        for (var i = 0; i < len; i++) {
          let notificationMessage = {
            USERID: this.userStore.userId,
            NOTIFICATION: result[i]?.NOTIFICATION,
            TIMESTAMP: result[i]?.TIMESTAMP,
            STATUS: "closed",
            NOTIFICATIONTYPE: result[i]?.NOTIFICATIONTYPE,            
          };
         

          let options = {
            qos: 1,
          };

          let notificationTopic = `notifications/${notificationMessage.USERID}`;
          let that = this;
          await this.mqttStore.mqttClient.publish(
            notificationTopic,
            JSON.stringify(notificationMessage),
            options,
            (err) => {
              if (err) {
                console.log(err);
                console.log("this.mqttStore.mqttClient.publish" + err);
              } else {
                console.log(
                  "notification sent to patient " +
                    JSON.stringify(notificationMessage)
                );
                const indexOfObject =
                  that.mqttStore.messages.notifications.findIndex((object) => {

                    let notificationTypeString;
                    notificationTypeString = typeof object?.NOTIFICATION;
                    let n = notificationTypeString == "string" ? JSON.parse(object?.NOTIFICATION) : object?.NOTIFICATION;

                    let notificationMessageString;
                    notificationMessageString = typeof notificationMessage?.NOTIFICATION;
                    let ns = notificationMessageString == "string" ? JSON.parse(notificationMessage?.NOTIFICATION) : notificationMessage?.NOTIFICATION;

                    return (
                      n?.timestamp ===
                      ns?.timestamp
                    );
                  });

                //console.log(indexOfObject);
                if (indexOfObject !== -1)
                  this.mqttStore.messages.notifications.splice(
                    indexOfObject,
                    1
                  );
              }
            }
          );
        }
      }
    },
    setUser() {
      console.log("setuser");
      this.userStore.setUser(
        this.user,
        this.isAuthenticated,
        this.idTokenClaims
      );
    },
    setOpen(isOpen) {
      this.isOpen = isOpen;
      if (this.isOpen) this.ionRouter.push("/", "");
      else this.ionRouter.push("/home", "");
    },
    logoff() {
      this.setOpen(false);
      this.logOut();
      window.setTimeout(() => {
        this.login();
      }, 2000);
    },
  },
});
</script>
<style>
#notifications-badge {
  background-color: red;
  position: absolute;
  border-radius: 100%;
}

@font-face {
  font-family: "OpenSansLight";
  src: local("OpenSansLight"),
    url(./fonts/open-sans/OpenSans-Light.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
    url(./fonts/open-sans/OpenSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSansBold";
  src: local("OpenSansBold"),
    url(./fonts/open-sans/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-slabLight";
  src: local("roboto-slabLight"),
    url(./fonts/roboto-slab/RobotoSlab-Light.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-slabRegular";
  src: local("roboto-slabRegular"),
    url(./fonts/roboto-slab/RobotoSlab-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-slabBold";
  src: local("roboto-slabBold"),
    url(./fonts/roboto-slab/RobotoSlab-Bold.ttf) format("truetype");
}
</style>
