<template>
  <ion-page>
    <ion-content>
      <LexBot></LexBot>
    </ion-content>
  </ion-page>
</template>
  <script>
import { defineComponent } from "vue";
import { IonPage, IonContent } from "@ionic/vue";
import LexBot from "../../components/LexBot.vue";

export default defineComponent({
  name: "rpmLexBotView",
  components: { IonContent, IonPage, LexBot },
  setup() {
    return { LexBot };
  },
});
</script>
  