import { defineStore, acceptHMRUpdate } from "pinia";

//import { useMqttStore } from './mqtt'
//import { useUserStore } from '../stores/user'

export const useScheduleStore = defineStore({
  id: "schedule",
  state: () => ({
    selectedSchedule: null,
  }),
  actions: {
    setSelectedSchedule(selectedItem) {
      console.log("selected schedule", selectedItem);
      this.selectedSchedule = selectedItem;
    },
  },
  persist: false,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useScheduleStore, import.meta.hot));
}
