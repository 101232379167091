<template>
  <ion-page>    
    <ion-content v-if="userStore" :fullscreen="true">
          <chatUsers  v-if="userStore.role == 'staff' && this.mypatients?.value?.length > 0" :key="componentKey"/>
          <chatStaff  v-if="userStore.role == 'patient' && this.myStaff?.value?.length > 0" :key="componentKey"/>
    </ion-content>
  </ion-page>
</template>


<script>
import { defineComponent, ref, reactive, watchEffect } from "vue";
import chatUsers from "../components/chatListUsers.vue";
import chatStaff from "../components/chatListStaff.vue";
import { IonPage, IonContent} from "@ionic/vue";
import { useUserStore } from '../stores/user'
import { useMqttStore } from "../stores/mqtt";
export default defineComponent({
  name: "chatUsersView",
  components: { chatUsers,IonPage, chatStaff, IonContent },
  setup() {
    const userStore = useUserStore();
    const mqttStore = useMqttStore();
    const mypatients = reactive(mqttStore.messages.events.ui.staff.mypatients);
    const myStaff = reactive(mqttStore.messages.events.ui.patient.patientstaff);
    const componentKey = ref(0);
    const forceRerender = () => {
      componentKey.value += 1;
    };
    watchEffect(() => {
      mypatients.value = mqttStore.messages.events.ui.staff.mypatients;
      myStaff.value = mqttStore.messages.events.ui.patient.patientstaff;
    });

    return {userStore, forceRerender, mqttStore, mypatients, myStaff, chatUsers, chatStaff, componentKey}
  },
  mounted() {
    //console.log('abc');
    this.forceRerender();
  },
});
</script>
