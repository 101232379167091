<template>
  <ion-button
    class="box-button"
    @click="setBasicValue()"
    routerDirection="root"
  >
    <ion-grid>
      <ion-row align-items-center>
        <ion-col width-50>
          <ion-label class="ion-label-first" text-wrap>
            {{ data.firstLabel }}</ion-label
          >
        </ion-col>
      </ion-row>
      <ion-row align-items-center>
        <ion-col>
          <!-- <img class="ion-icon-image" :src=data.icon /> -->
          <ion-icon class="ion-icon-image" :src="data.icon"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row align-items-center>
        <ion-col>
          <ion-label class="ion-label-second">{{ data.secondLabel }}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row align-items-center>
        <ion-col>
          <ion-label class="ion-label-third">{{ data.thirdLabel }}</ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-button>
</template>

<script>
import { defineComponent } from "vue";
import { IonButton, IonLabel, IonGrid, IonCol, IonRow } from "@ionic/vue";
import { userpmBasicStore } from "../stores/rpmBasic";
import { calendar } from "ionicons/icons";
import { useIonRouter, IonIcon, loadingController } from "@ionic/vue";
import { useUserStore } from "../stores/user";
//import { useRouter } from "vue-router";

export default defineComponent({
  name: "MainBasicbox",
  components: { IonButton, IonLabel, IonGrid, IonCol, IonRow, IonIcon },
  props: {
    data: Object,
  },
  mounted() {
    console.log("rpmMainBasicBox props" + JSON.stringify(this.$props.data));
  },
  watch: {
    data: {
      handler: function (val) {
        // console.log('Watch mypatients val' + JSON.stringify(val));
        // console.log("Watch rpmMainBasicBox val" + JSON.stringify(val));
        // console.log("Watch rpmMainBasicBox oldval" + JSON.stringify(oldVal));

        if (val?.secondLabel > 0) {
          // console.log("Watch rpmMainBasicBox value greater than 0" + JSON.stringify(val));
          // console.log("Watch rpmMainBasicBox value greater than 0" + JSON.stringify(val));
          //this.showLoading().loading.dismiss();
          // let loading =  document.querySelector("#Digitalloading");
          // if (loading !== null)
          // loading.dismiss();
        }
        // else
        // {
        //   this.showLoading();
        // }
        // console.log(oldVal);
        // if (val.length > 0) {
        //   val.forEach((user) => {
        //     //console.log('calling unsubscrible for user : ' + JSON.stringify(user));
        //     this.mqttStore.unsubscribeMqtt(`chat/${user.STAFFUSERID}`);
        //   });
        //   //console.log("Chat List Users mounted.");
        // }
      },

      deep: true,
    },
  },
  methods: {},
  setup(props) {
    const ionRouter = useIonRouter();
    const userStore = useUserStore();
    const store = userpmBasicStore();

    const showLoading = async () => {
      const loading = await loadingController.create({
        id: "Digitalloading",
        message: "Loading...",
        duration: 3000,
        spinner: "circles",
      });

      loading.present();
    };

    function setBasicValue() {
      if (userStore.role == "staff") return;
      store.setRpmBasic(props.data.abbrevation);
      ionRouter.push("/boxDescription", "");
    }

    return {
      setBasicValue,
      store,
      calendar,
      ionRouter,
      userStore,
      showLoading,
    };
  },
});
</script>
<style scoped>
.ion-grid-row {
  background: #f5f5f5;
  height: 80%;
}

.ion-icon-image {
  height: 41px;
  width: 41px;
}

.box-button {
  width: 163px;
  height: 164px;
  left: 19px;
  top: 209px;
  /* background-color: white; */

  /*  background: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
  padding: 3px;
  padding-left: 0px;
  padding-top: 0px;
}

ion-button {
  --background: white;
  --background-hover: hsla(150, 3%, 77%, 0.171);
  --background-activated: hsla(150, 3%, 77%, 0.171);
  --background-focused: hsla(150, 3%, 77%, 0.171);

  /* 
    --border-radius: 0;
    --border-color: #000;
    --border-style: solid;
    --border-width: 1px; */

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --ripple-color: deeppink;

  /* --padding-top: 10px;
    --padding-bottom: 10px; */
}

.ion-label-first {
  color: black;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* identical to box height, or 27px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  display: grid;
  justify-items: center;
  text-transform: none;
  width: 150px;
}

.ion-label-second {
  color: black;

  height: 24px;

  font-family: "roboto-slabBold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* or 38px */

  display: grid;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
}
.ion-label-third {
  /* width: 79px; */
  height: 21px;

  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: grid;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;

  color: #ef9116;
}
</style>
