<template>
  <ion-content>
    <ion-page no-padding>
      <!-- <ion-header> -->
      <ion-toolbar>
        <ion-title class="TitleText ion-text-wrap">{{
          state.toplabel
        }}</ion-title>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
      <!-- </ion-header> -->

      <ion-grid
        style="
          width: 100%;

          padding: 0px;
          height: 100%;
        "
      >
        <ion-row style="width: 100%; height: 64px">
          <ion-col col-6 no-padding style="text-align: center">
            <ion-label class="TopLabel">{{
              this.avgValue == undefined ? "0%" : ((state.toplabel !== "Activity" && state.toplabel !== "Oxygen Saturation")  ? this.avgValue :  this.avgValue + "%")
            }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row style="width: 100%" class="TopLabelDesc">
          <ion-col col-6 no-padding>
            <!-- <ion-label>{{ state.toplabel }}</ion-label> -->
          </ion-col>
        </ion-row>
        <ion-row style="width: 100%">
          <ion-col col-6 no-padding> </ion-col>
        </ion-row>
        <ion-row style="width: 100%">
          <ion-col style="text-align: right" no-padding>
            <ion-label class="leftLabel">98</ion-label>
          </ion-col>
          <ion-col style="text-align: left" no-padding> </ion-col>

          <ion-col style="text-align: left" col-6 no-padding> </ion-col>
          <ion-col style="text-align: left" col-6 no-padding>
            <ion-label class="rightLabel" style="text-align: left"
              >1%</ion-label
            >
          </ion-col>
        </ion-row>
        <ion-row style="width: 100%">
          <ion-col style="text-align: left; padding-left: 10%" col-6 no-padding>
            <ion-label class="labeldescription">Baseline</ion-label>
          </ion-col>
          <ion-col
            style="text-align: right; padding-right: 10%"
            col-6
            no-padding
          >
            <ion-label class="labeldescription">VS.Baseline</ion-label>
          </ion-col>
        </ion-row>
        <ion-row style="width: 100%">
          <ion-col style="text-align: left" col-6 no-padding> </ion-col>
        </ion-row>
        <ion-row class="fancy-row">
          <ion-col col-6 style="padding-left: 20px; padding-right: 20px">
            <ion-label class="labelHeading"> Definition: </ion-label>
            <ion-label class="labelText">
              {{ state.definition }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row class="card fancy-row">
          <ion-col col-6 style="padding-left: 20px; padding-right: 20px">
            <ion-label class="labelHeading"> Basics: </ion-label>
            <ion-label class="labelText">
              {{ state.basics }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row ref="darkTheme" class="fancy-row">
          <ion-col col-6 style="padding-left: 20px; padding-right: 20px">
            <ion-label class="labelHeading"> Interpretation: </ion-label>
            <ion-label class="labelText">
              {{ state.interpretation }}
            </ion-label>
          </ion-col>
        </ion-row>

        <ion-row ref="darkTheme" class="fancy-row-button">
          <ion-col col-2 no-padding style="">
            <ion-button
              class="btnLearn"
              color="dark"
              shape="round"
              expand="block"
              >LEARN MORE</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-page>
  </ion-content>
</template>
<script>
import { defineComponent, ref, computed, toRaw, reactive } from "vue";
import { userpmBasicStore } from "../stores/rpmBasic";
import { useMqttStore } from "../stores/mqtt";
import {
  IonPage,
  IonLabel,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  //IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
} from "@ionic/vue";

export default defineComponent({
  name: "BoxDescription",
  components: {
    IonPage,
    IonLabel,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    //IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    //IonCard, IonCardContent
  },
  setup() {
    const mqttStore = useMqttStore();
    const state = reactive({
      toplabel: "",
      definition: "",
      basics: "",
      interpretation: "",
    });

    const avgValue = ref("");

    const data = ref([
      {
        id: 1,
        abbrevation: "t",
        TopLabel: avgValue.value,
        TopLabelDescription: "Activity",
        LeftLabel: "98%",
        LeftLabelDescription: "Baseline",
        RightLabel: "1%",
        RightLabelDescription: "VS.Baseline",
        Definition:
          "The Activity Score represents your daily physical activity versus the goal set by your care provider. The scores are presented from  0 (no physical activity) to 100 (activity goal accomplished or exceeded).",
        Basics:
          "Physical activity is a metric that has been shown to be correlated with various health outcomes and diseases. Activity is not exclusive to exercise but includes all physical activities throughout the day.",
        Interpretation:
          "An activity threshold has been set by your care provider. It is recommended that you attempt to achieve an Activity Score of 80 or greater each day.",
      },
      {
        id: 2,
        abbrevation: "spo2",
        TopLabel: avgValue.value,
        TopLabelDescription: "Oxygen Saturation",
        LeftLabel: "98%",
        LeftLabelDescription: "Baseline",
        RightLabel: "1%",
        RightLabelDescription: "VS.Baseline",
        Definition:
          "Oxygen saturation is the percentage of hemoglobin currently bound to oxygen in the blood stream.",
        Basics:
          "Oxygen is required for metabolic processes which provide energy for vital functions. Factors that may influence SpO2 include high altitude exposure, sleep apnea, or infections.",
        Interpretation:
          "Oxygen saturation values greater than or equal to 95% are considered normal. Values below 90% (hypoxemia) typically require medical attention. For individuals with chronic lung conditions or breathing related issues, these normal ranges do not apply.",
      },
      {
        id: 3,
        abbrevation: "hr",
        TopLabel: avgValue.value,
        TopLabelDescription: "Heart Rate",
        LeftLabel: "98",
        LeftLabelDescription: "Baseline",
        RightLabel: "1",
        RightLabelDescription: "VS.Baseline",
        Definition:
          "Heart rate is defined as the number of contractions of the heart in beats per minute (bpm) at rest. ",
        Basics:
          "Heart rate may change in response to stressors to increase circulation of blood. Chronically increased resting heart rate has been correlated with various diseases and outcomes.",
        Interpretation:
          "A normal resting heart rate is 60 to 100 beats per minute. Factors that may influence resting heart rate include fitness level, room temperature, body position, stress, body composition, and use of medications.",
      },
      {
        id: 4,
        abbrevation: "sd",
        TopLabel: avgValue.value,
        TopLabelDescription: "Sleep Duration",
        LeftLabel: "98",
        LeftLabelDescription: "Baseline",
        RightLabel: "1",
        RightLabelDescription: "VS.Baseline",
        Definition:
          "Sleep duration is the amount of sleep time achieved per night.",
        Basics:
          "Sleep is important for regulation of biological processes. Adequate sleep is therefore important to health management.",
        Interpretation:
          "The American Academy of Sleep Medicine recommends approximately 7 to 9 hours of sleep per night for adults aged 18+.",
      },
      {
        id: 5,
        abbrevation: "rr",
        TopLabel: avgValue.value,
        TopLabelDescription: "Respiratory Rate",
        LeftLabel: "98",
        LeftLabelDescription: "Baseline",
        RightLabel: "1",
        RightLabelDescription: "VS.Baseline",
        Definition:
          "Respiratory rate is defined as the number of breathing cycles (inhalation and exhalation) per minute, expressed as breaths per minute (brpm).",
        Basics:
          "Respiratory rate is primarily controlled without thought in response to environmental demands. Respiratory rate should remain relatively stable under normal resting conditions.",
        Interpretation:
          "The normal range for respiratory rate at rest is 12 to 20 breaths per minute (brpm). However, certain factors such as respiratory illness, high levels of fatigue, or infection can cause significant changes in respiratory rate.",
      },
      {
        id: 6,
        abbrevation: "bp",
        TopLabel: avgValue.value,
        TopLabelDescription: "Blood Pressure",
        LeftLabel: "98",
        LeftLabelDescription: "Baseline",
        RightLabel: "1",
        RightLabelDescription: "VS.Baseline",
        Definition:
          "Blood pressure is the pressure of circulating blood against the walls of blood vessels. It is measured in millimeters of mercury (mmHg) for systolic (during heartbeat) and diastolic (rest between heart beats).",
        Basics:
          "Blood pressures measures the force that your heart uses to pump blood throughout your body. High blood pressure is associated with poor lifestyle habits and certain conditions including diabetes and obesity.",
        Interpretation:
          "A normal blood pressure level is less than 120/80 mmHg. High blood pressure, also called hypertension, is typically defined as blood pressure consistently higher than 140/90 mmHg.",
      },
    ]);

    const store = userpmBasicStore();
    return { data, state, store, mqttStore, avgValue };
  },

  mounted() {
    const toggle = document.querySelectorAll("ion-tab-button");
    toggle[0].selected = true;

    if (this.store.rpmbasic == "t")
      this.avgValue = this.mqttStore.messages.patientData7?.t[0]?.y;
    else if (this.store.rpmbasic == "spo2")
      this.avgValue = this.mqttStore.messages.patientData7?.spo2[0]?.y;
    else if (this.store.rpmbasic == "hr")
      this.avgValue = this.mqttStore.messages.patientData7?.hr[0]?.y;
    else if (this.store.rpmbasic == "rr")
      this.avgValue = this.mqttStore.messages.patientData7?.rr[0]?.y;
    else if (this.store.rpmbasic == "bp")
      this.avgValue = this.mqttStore.messages.patientData7?.bp[0]?.y;
    else this.avgValue = undefined;

    const getTodos = computed(() => {
      return this.data.filter(
        (item) => item.abbrevation === this.store.rpmbasic
      );
    });

    const val = toRaw(getTodos.value);
    //console.log('value ' + val);
    if (val !== null && val.length > 0) {
      this.state.toplabel = val[0].TopLabelDescription;
      this.state.basics = val[0].Basics;
      this.state.definition = val[0].Definition;
      this.state.interpretation = val[0].Interpretation;
    }
  },
});
</script>
<style scoped>
.TitleText {
  font-family: "roboto-slabBold";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  /* identical to box height */
  width: 104%;
  text-align: center;

  /* Main heading */

  /* color: #1c212b; */
}
ion-button {
  /* --background: black; */
}

.btnLearn {
font-family: roboto-slabRegular;
font-size: 16px;
line-height: 160%;
letter-spacing: .5px;
text-transform: uppercase;
color: #fefefe;
float: bottom;
bottom: 10px;
position: fixed;
bottom: 0px;
right: 5%;
bottom: 16px;
left: 5%;
}

.labelHeading {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  letter-spacing: 0.5px;

  /* color: #000000; */
}
.labelText {
  font-family: "OpenSansLight";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* or 27px */
  letter-spacing: 0.5px;
  /* color: #000000; */
}
.card {
  width: 100%;
  padding-left: 0px;

  /* Light Accent */

  /* background: #faae38; */
}
.labeldescription {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 27px */

  letter-spacing: 1px;

  /* Sub-Heading */

  /* color: #606b7c; */
}
.TopLabel {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 1px;
  color: #0dba61;
}

.leftLabel {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 38px */

  align-items: center;
  text-align: center;
  letter-spacing: 1px;
}

.rightLabel {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 38px */

  align-items: center;
  text-align: center;
  letter-spacing: 1px;

  /* color/success */

  color: #0dba61;
}

.TopLabelDesc {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  align-items: center;
  text-align: center;
  letter-spacing: 1px;

  /* Sub-Heading */

  /* color: #606b7c; */
}
</style>
