


import { defineStore } from "pinia";
import axios from 'axios';
import { useMqttStore } from '../stores/mqtt'
import { useUserStore } from "../stores/user";
  export const useKsqlStore = defineStore({
  id: 'ksql',
  state: () => ({
    ksqlFunction:'https://confluentconnectors.azurewebsites.net/api/ksql?code=mXPUwVDFMqUEa1VUeH1SsOFrU9zLURYZA3bQMMT1ANmgAzFuZvILbQ==',
    user:{},
    users:[],
    alerts:[]
    
  }),
  actions: {
    async pullOpenNotifications(userId) {
      const mqttStore = useMqttStore()
      const userStore = useUserStore()
      //WHERE ARRAY_LENGTH(STATUS) = '1'
       const query = `SELECT * FROM NOTIFICATION_TABLE WHERE STATUS = 'open' and USERID = '${userId}';` // Having 14 items with Open Status
      // const query = `SELECT * FROM NOTIFICATION_TABLE WHERE  ARRAY_LENGTH(STATUS) = 1;` // Having 14 items with Open Status
        // const query = `Select * FROM notificationstream WHERE STATUS = 'open' ;` // Having 13 items with Open Status
        let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {
               // console.log('alert response',response);
                response.data.shift()
               
                  let mappedData = response.data.map((item) => {
                
                  let m = {
                        "userId":item[0],
                        "NOTIFICATIONTYPE":item[4],
                        "NOTIFICATION": item[5],
                        "STATUS":item[6],
                        "TIMESTAMP":item[1],
           
                    }
                  return m 
                 
            })
            console.log('notifications',mappedData); 
            mqttStore.messages.notifications = mappedData
            mqttStore.subscribeToNotifications(userStore.userId);
            })
            .catch(function (error) {
                console.log('axios error', error);
            });
    },
    async pullAlerts() {
      const mqttStore = useMqttStore()
        const query = `SELECT * FROM  ALERTSTREAM_USER WHERE ASSIGNEDSTAFF = 'unassigned';`
        let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {
               // console.log('alert response',response);
                response.data.shift()
                
                 let mappedData = response.data.map((item) => {
                
                  let m = {
                        "USERID":item[0],
                        "USER_ID":item[1],
                        "RISKSCORE":item[2],
                        "RISKLEVEL":item[3],
                        "UTTERANCE":item[4],
                        "HEARTRATE":item[5],
                        "RESPRATE":item[6],
                        "SPO2":item[7],
                        "TEMP":item[8],
                        "BLOODPRESSURE":item[9],
                        "ASSIGNEDSTAFF":item[10],
                        "STAFFUSER":item[11],
                        "ASSIGNEDDATE":item[12],
                        "TIMESTAMP":item[13],
                        "NAME":item[14],
                        "NICKNAME":item[15],
                        "PICTURE":item[16],
                        "EMAIL":item[17]
                    }
                  return m 
                 
            })
          //  console.log('alerts',mappedData); 
            mqttStore.messages.events.digitalTriage.alert = mappedData
            //mqttStore.subscribeToAlerts()
            })
            .catch(function (error) {
                console.log('axios error', error);
            });
    },
    async pullAlertsAssigned(staffUserId) {
      const mqttStore = useMqttStore()
        const query = `SELECT * FROM ALERTS_TABLE WHERE ARRAY_LENGTH(ASSIGNEDSTAFF) > 1 AND ASSIGNEDSTAFF[ARRAY_LENGTH(ASSIGNEDSTAFF)] = '${staffUserId}';`
        let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {
                console.log(response);
                response.data.shift()
                
                 let mappedData = response.data.map((item) => {
                
                  let m = {
                        "USERID":item[0],
                        "USER_ID":item[1],
                        "RISKSCORE":item[2],
                        "RISKLEVEL":item[3],
                        "UTTERANCE":item[4],
                        "HEARTRATE":item[5],
                        "RESPRATE":item[6],
                        "SPO2":item[7],
                        "TEMP":item[8],
                        "BLOODPRESSURE":item[9],
                        "ASSIGNEDSTAFF":item[10],
                        "STAFFUSER":item[11],
                        "ASSIGNEDDATE":item[12],
                        "TIMESTAMP":item[13],
                        "NAME":item[14],
                        "NICKNAME":item[15],
                        "PICTURE":item[16],
                        "EMAIL":item[17]
                    }
                  return m 
                 
                 
            })
            //console.log('my alerts',mappedData); 
            //this.alerts = mappedData
            mqttStore.messages.events.ui.staff.mypatients = mappedData
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    async pullAlertsPatient(patientUserId) {
      const mqttStore = useMqttStore()
        const query = `SELECT * FROM ALERTS_TABLE WHERE ARRAY_LENGTH(ASSIGNEDSTAFF) > 1 AND USERID = '${patientUserId}';`
        let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {
               // console.log('patient alerts',response);
                response.data.shift()
                
                 let mappedData = response.data.map((item) => {
                
                  let m = {
                         "PATIENTUSERID":item[0],
                        "ASSIGNEDSTAFF":item[10],
                        "STAFFUSER":item[11]
                    }
                  return m 
                 
            })
            
            
            //console.log('mapped patient alerts',mappedData)
            if(mappedData.length > 0){

            let sp = []
            mappedData.forEach( (item ) => {
                let p = {
                  PATIENTUSERID: item.PATIENTUSERID,
                  STAFFUSERID: item.STAFFUSER.STAFFUSERID,
                  NAME: item.STAFFUSER.NAME,
                  NICKNAME: item.STAFFUSER.NICKNAME,
                  PICTURE: item.STAFFUSER.PICTURE,

                }
             
                sp.push(p)
            
            })

           // console.log('patient staff list from alerts',sp);
            
            mqttStore.messages.events.ui.patient.patientstaff = sp
          }
            
            
          })
            .catch(function (error) {
                console.log(error);
            });
    },

     async pullSchedulePatient(patientUserId) {
      const mqttStore = useMqttStore()
        const query = `SELECT * FROM SCHEDULESTREAM WHERE PATIENTUSERID = '${patientUserId}';`
        let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {
                //console.log('schedule',response);
                 response.data.shift()
                
                 let mappedData = response.data.map((item) => {
                
                  let m = {
                        "IDKEY":item[0],
                        "ID":item[1],
                        "PATIENTUSERID":item[2],
                        "PATIENT":item[3],
                        "STAFF":item[4],
                        "STAFFUSERID":item[5],
                        "COMMENTS":item[6],
                        "MEETINGSTARTTIME":item[7],
                        "MEETINGENDTIME":item[8],
                        "TIMESTAMP":item[9],
                        "DOCTORDETAILS":item[10],
                        "DOCTORLOCATION":item[11]
                    }
                  return m 
                 
                }) 
           console.log('schedule',mappedData);
            //this.alerts = mappedData
            mqttStore.messages.events.ui.patient.schedule = mappedData
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    async pullScheduleStaff(staffUserId) {
      const mqttStore = useMqttStore()
        const query = `SELECT * FROM SCHEDULESTREAM WHERE STAFFUSERID = '${staffUserId}';`
        let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {
               
                 response.data.shift()
                
                 let mappedData = response.data.map((item) => {
                
                  let m = {
                        "IDKEY":item[0],
                        "ID":item[1],
                        "PATIENTUSERID":item[2],
                        "PATIENT":item[3],
                        "STAFF":item[4],
                        "STAFFUSERID":item[5],
                        "COMMENTS":item[6],
                        "MEETINGSTARTTIME":item[7],
                        "MEETINGENDTIME":item[8],
                        "TIMESTAMP":item[9],
                        "DOCTORDETAILS":item[10],
                        "DOCTORLOCATION":item[11]
                    
                    }
                  return m 
                 
            }) 
           // console.log('schedule',mappedData);
            //this.alerts = mappedData
            mqttStore.messages.events.ui.staff.schedule = mappedData
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    async pullActions(staffUserId) {
      const mqttStore = useMqttStore()
        const query = `SELECT * FROM ACTIONSTREAM_USER WHERE STAFFUSER = '${staffUserId}';`
        let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {

                 response.data.shift()
                //console.log('action resp',response)
                 let mappedData = response.data.map((item) => {
                
                  let m = {
                        "IDKEY":item[0],
                        "ID":item[1],
                        "USERID":item[2],
                        "RISKLEVEL":item[3],
                        "ACTION":item[4],
                        "STAFFUSER":item[5],
                        "TIMESTAMP":item[6],
                        "NAME":item[7],
                        "NICKNAME":item[8],
                        "PICTURE":item[9],
                        "EMAIL":item[10],

                    }
                  return m 
                 
            }) 
            console.log('actions',mappedData);
            //this.alerts = mappedData
            mqttStore.messages.commands.digitalTriage.action = mappedData
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    async pullUser(userId) {
        const query = `SELECT * FROM USERS_TABLE WHERE USERID = '${userId}';`
     let payload = {
          query:query
        }
        // Default options are marked with *
            return await axios.post(this.ksqlFunction,payload)
            .then(function (response) {
                console.log('user',response);
                //response.data.shift()
            })
    },
    pullUsers() {
        const query = 'SELECT * FROM USERS_TABLE;'
        this.postData(query)
        .then((response) => {
            console.loeg('users',response.data); 
            this.users = response.data
        }); 
    },
  },
  persist: false, 
},
)
