<template>
   <ion-page>
  <div>
    <ion-list>
      <ion-item lines="full">
        <ion-icon slot="start" name="moon"></ion-icon>
        <ion-label> Dark Theme </ion-label>
        <ion-toggle
          :checked="toggle"
          @ionChange="onClick($event)"
          id="themeToggle"
          slot="end"
        ></ion-toggle>
      </ion-item>
    </ion-list>

    <ion-text>
      <h2>Welcome, {{ userStore.user.name }}</h2>
    </ion-text>
    <ion-item lines="none" style="--padding-start: 0px">
      <ion-text slot="start">
        <h4>Skin Tone color </h4>
      </ion-text>
      <ion-button :disabled="true"
        slot=""
        id="skinbutton"
        shape="circle"
        ref="refButton"
        :style="`--background: ${skinToneColor}`"
      ></ion-button>
    </ion-item>

    <!-- <ion-textarea>
      {{ JSON.stringify(userStore.userClaims) }}
    </ion-textarea> -->
  </div>
</ion-page>
</template>
<script>
import { onMounted, ref } from "vue";
import { useUserStore } from "../stores/user";
import { userThemeStore } from "../stores/theme";
import { useskinToneStore } from "../stores/skinTone";
import rpmSkinTone from "../components/rpmSkinTonePicker.vue";
import { addIcons } from "ionicons";
import { moon } from "ionicons/icons";
import { useMqttStore } from "../stores/mqtt";
import {
  IonText,
  IonButton,
  modalController,
  IonList,
  IonItem,
  IonIcon,
  IonToggle,
  IonLabel,
  IonPage,
} from "@ionic/vue";

export default {
  setup() {
 
    const mqttStore = useMqttStore();
    const themeStore = userThemeStore();
    const refButton = ref(null);
    const userStore = useUserStore();
    const userskinTone = useskinToneStore();
    const skinToneColor = ref(userskinTone.skinToneValue);
   
    const toggle = ref(themeStore.darkTheme);

    function onClick(event) {
      let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
      console.log("systemDark " + systemDark);
      var a = document.body.classList.toggle("dark", event.detail.checked);
      console.log("systemDark a " + a);
      // systemDark.addListener(this.colorTest);
      themeStore.setDarkTheme(event.detail.checked);
      if (event.detail.checked) {
        // themeStore.setTheme('dark');
        document.body.classList.toggle("dark", event.detail.checked);
      } else {
        document.body.classList.toggle("light", event.detail.checked);
      }
    }

    onMounted(() => {
      skinToneColor.value = "#" + userskinTone.skinToneValue;
      console.log('color ' + "#" + userskinTone.skinToneValue);
    });

    async function openModal() {
      const modal = await modalController.create({
        component: rpmSkinTone,
        cssClass: "modalSkin",
      });
      modal.present();

      modal.onDidDismiss().then((data) => {
        console.log(data);
        var obj = mqttStore.messages.events.ui.patient.skintone;
        if (obj !== undefined && obj.length == 0) {
          console.log(
            "userskinTone.skinToneValue : " + userskinTone.skinToneValue
          );
          skinToneColor.value = "#" + userskinTone.skinToneValue;
          return;
        }
        var skinSort = obj.sort(
          (a, b) => parseFloat(b.timestamp) - parseFloat(a.timestamp)
        );
        var LastSkin = skinSort[0];
        skinToneColor.value = "#" + LastSkin.value;
        return;
      });
    }

    return {
      userStore,
      userskinTone,
      skinToneColor,
      openModal,
      refButton,
      mqttStore,
      onClick,
      themeStore,
      toggle,
      
    };
  },

  components: {
    IonText,
    IonButton,
    IonList,
    IonItem,
    IonIcon,
    IonToggle,
    IonLabel,
    IonPage,
  },
  created() {
    addIcons({
      moon: moon,
    });
  },
};
</script>
<style>
ion-button[shape="circle"] {
  --border-radius: 50%;
  width: 56px;
  height: 56px;
  /* --background:blue; */
}
</style>
