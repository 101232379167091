<template>
  <ion-page>
    <ion-toolbar>
      <ion-title style="width: auto">
        <ion-label class="titleText">
          Schedule >
          {{ this.scheduleStore.selectedSchedule?.NICKNAME }}</ion-label
        >
      </ion-title>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-buttons
        slot="end"
        style="padding-right: 10px"
        v-if="this.loggedInUserRole == 'staff'"
      >
      </ion-buttons>
    </ion-toolbar>
    <ion-content
      ref="content"
      @ionScroll="onScroll"
      :scroll-y="true"
      :scrollEvents="true"
    >
      <ion-accordion-group :multiple="true" :value="['first', 'second']">
        <ion-accordion
          value="first"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" style="font-size: xx-small">
            <ion-label class="accordionLabel">Upcoming Meetings</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-accordion-group ref="accordionGroup" lines = "none" text-wrap>
              <ion-accordion
                style="background-color: black"
                v-for="user in this.scheduleData.filter((x) => DateTime.fromISO(x?.MEETINGSTARTTIME) > Date.now())"
                :key="user.PATIENTUSERID"
                :value="user.TIMESTAMP"
                :toggleIcon="chevronDownOutline"
              >
                <ion-item slot="header" lines="none">
                  <ion-label
                    style="white-space: inherit"
                    class="labelcontent"
                    v-if="this.loggedInUserRole.includes('staff')"
                  >
                    Meeting Scheduled with {{ user?.PATIENT?.NICKNAME }} <br />
                    <b>Start :</b>
                    {{
                      DateTime.fromISO(user?.MEETINGSTARTTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                    <br />
                    <b>End :</b>
                    {{
                      DateTime.fromISO(user?.MEETINGENDTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                  </ion-label>
                  <ion-label
                    style="white-space: inherit"
                    class="labelcontent"
                    v-if="this.loggedInUserRole.includes('patient')"
                  >
                    You have a below Meeting Scheduled with
                    {{ user?.STAFF?.NICKNAME }} <br />
                    <b>Start :</b>
                    {{
                      DateTime.fromISO(user?.MEETINGSTARTTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                    <br />
                    <b>End&nbsp;&nbsp;:</b>
                    {{
                      DateTime.fromISO(user?.MEETINGENDTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                  </ion-label>
                </ion-item>
                <div slot="content" text-wrap>
                  <ion-item slot="header" lines = "none" class="contentItem">
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Comments : {{ user?.COMMENTS }} <br />
                    </ion-label>
                  </ion-item>
                  <ion-item
                    slot="header"
                    class="contentItem"
                    v-if="user.DOCTORDETAILS !== null"
                  >
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Doctor Details : {{ user?.DOCTORDETAILS }} <br />
                    </ion-label>
                  </ion-item>
                  <ion-item
                    slot="header"
                    class="contentItem"
                    v-if="user.DOCTORLOCATION !== null"
                  >
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Doctor Location : {{ user?.DOCTORLOCATION }} <br />
                    </ion-label>
                  </ion-item>
                  <ion-item slot="header" class="contentItem">
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Saved on :
                      <!-- {{
                        DateTime.fromISO(user?.TIMESTAMP).toLocaleString(
                          DateTime.DATETIME_MED
                        )
                      }} -->
                      
                         {{
                        DateTime.fromISO(user?.TIMESTAMP, {setzone : false}).toLocaleString(
                          DateTime.DATETIME_MED
                        )
                      }}
                    </ion-label>
                  </ion-item>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </div>
        </ion-accordion>
        <ion-accordion
          value="second"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" lines="none" style="font-size: xx-small">
            <ion-label class="accordionLabel">Previous Meetings</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-accordion-group ref="accordionGroup" text-wrap>
              <ion-accordion
                style="background-color: black"
                v-for="user in this.scheduleReverseData.filter((x) => DateTime.fromISO(x?.MEETINGSTARTTIME) < DateTime.now())"
                :key="user.PATIENTUSERID"
                :value="user.TIMESTAMP"
                :toggleIcon="chevronDownOutline"
              >
                <ion-item slot="header" lines="none">
                  <ion-label
                    style="white-space: inherit"
                    class="labelcontent"
                    v-if="this.loggedInUserRole.includes('staff')"
                  >
                    Meeting Scheduled with {{ user?.PATIENT?.NICKNAME }}<br />
                    <b>Start :</b>
                    {{
                      DateTime.fromISO(user?.MEETINGSTARTTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                    <br />
                    <b>End :</b>
                    {{
                      DateTime.fromISO(user?.MEETINGENDTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                  </ion-label>
                  <ion-label
                    style="white-space: inherit"
                    class="labelcontent"
                    v-if="this.loggedInUserRole.includes('patient')"
                  >
                    You have a below Meeting Scheduled with
                    {{ user?.STAFF?.NICKNAME }} <br />
                    <b>Start :</b>
                    <!-- {{ user?.MEETINGSTARTTIME  }} -->
                    <!-- <br/>current date : {{ DateTime.now() }}
                    <br/>ab : {{  DateTime.fromISO(user?.MEETINGSTARTTIME) < DateTime.now() }} -->
                    {{
                      DateTime.fromISO(user?.MEETINGSTARTTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                    <br />
                    <b>End&nbsp;&nbsp;:</b>
                    {{
                      DateTime.fromISO(user?.MEETINGENDTIME).toLocaleString(
                        DateTime.DATETIME_MED
                      )
                    }}
                  </ion-label>
                </ion-item>
                <div slot="content" text-wrap>
                  <ion-item slot="header" class="contentItem">
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Comments : {{ user?.COMMENTS }} <br />
                    </ion-label>
                  </ion-item>
                  <ion-item
                    slot="header"
                    class="contentItem"
                    v-if="user.DOCTORDETAILS !== null"
                  >
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Doctor Details : {{ user?.DOCTORDETAILS }} <br />
                    </ion-label>
                  </ion-item>
                  <ion-item
                    slot="header"
                    class="contentItem"
                    v-if="user.DOCTORLOCATION !== null"
                  >
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Doctor Location : {{ user?.DOCTORLOCATION }} <br />
                    </ion-label>
                  </ion-item>
                  <ion-item slot="header" class="contentItem">
                    <ion-label
                      style="white-space: inherit"
                      class="labelcontent"
                    >
                      Saved on :
                      {{
                        DateTime.fromISO(user?.TIMESTAMP).toLocaleString(
                          DateTime.DATETIME_MED
                        )
                      }}
                    </ion-label>
                  </ion-item>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </div>
        </ion-accordion>
      </ion-accordion-group>

      <div style="position: fixed; bottom: 4%; right: 4%">
        <ion-button style="--border-radius: 40px" @click="openModal">
          <ion-icon :icon="addOutline" style="zoom: 1.3"></ion-icon>
          <ion-label class="NewMeeting">New Meeting</ion-label>
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent, ref, watchEffect } from "vue";
import schedulingModal from "../components/SchedulingModalPopup.vue";
import { addOutline, chevronForward, caretUpOutline, chevronDownOutline } from "ionicons/icons";
import {
  // IonFab, IonFabButton,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonButtons,
  IonPage,
  IonIcon,
  IonLabel,
  IonButton,
  modalController,
  //IonList,
  IonItem,
  IonContent,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/vue";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
import { useScheduleStore } from "../stores/schedule";
export default defineComponent({
  name: "schedulingUsersView",
  components: {
    //IonFab, IonFabButton,
    IonToolbar,
    IonTitle,
    IonBackButton,
    IonButtons,
    IonAccordion,
    IonAccordionGroup,
    IonPage,
    IonIcon,
    IonLabel,
    IonButton,
    //IonList,
    IonItem,
    IonContent,
  },
  props: {
    PATIENTUSERID: String,
  },
  //   props: ["PATIENTUSERID"],
  setup(props) {
    const userIdValue = ref(props.PATIENTUSERID);
    const userStore = useUserStore();
    const scheduleStore = useScheduleStore();
    const mqttStore = useMqttStore();
    //const data = ref([]);
    const loggedInUserRole = ref(userStore?.user["com.rpmone.app/roles"][0]);
    const scheduleData = ref([]);
    const scheduleReverseData = ref([]);

    watchEffect(() => {
      if (
        scheduleStore.selectedSchedule == null ||
        scheduleStore.selectedSchedule == undefined
      )
        return;

      let data = loggedInUserRole?.value.includes("staff")
        ? mqttStore.messages.events.ui.staff.schedule.filter(
            (x) =>
              x.PATIENTUSERID == scheduleStore.selectedSchedule?.USERID &&
              x.STAFFUSERID == userStore.userId
          )
        : mqttStore.messages.events.ui.patient.schedule;
      //console.log("scheduleData Before Sort" + JSON.stringify(data));

      let RevData = data.slice();
      scheduleData.value = data.sort(function (a, b) {
        return (
          DateTime.fromISO(a?.MEETINGSTARTTIME) -
          DateTime.fromISO(b?.MEETINGSTARTTIME)
        );
      });

      scheduleReverseData.value = RevData.sort(function (a, b) {
        return (
          DateTime.fromISO(b?.MEETINGSTARTTIME) -
          DateTime.fromISO(a?.MEETINGSTARTTIME)
        );
      });

      

      // console.log(
      //   "scheduleData After sort" + JSON.stringify(scheduleData.value)
      // );
    });

    return {
      addOutline,
      schedulingModal,
      mqttStore,
      scheduleData,
      scheduleReverseData,
      userStore,
      loggedInUserRole,
      DateTime,
      scheduleStore,
      userIdValue,
      chevronForward,
      chevronDownOutline,
      caretUpOutline
    };
  },
  mounted() {
    // filter the schedule records for staff and selected patient
    if (this.userIdValue !== undefined) {
      //this.filterScheduleData(this.userIdValue.PATIENTUSERID);
      let data =
        this.loggedInUserRole == "staff"
          ? this.filterScheduleData()
          : this.mqttStore.messages.events.ui.patient.schedule;
      console.log("scheduleData Before Sort" + JSON.stringify(data));
      let RevData = data.slice();
      this.scheduleData.value = data.sort(function (a, b) {
        return (
          DateTime.fromISO(a?.MEETINGSTARTTIME) -
          DateTime.fromISO(b?.MEETINGSTARTTIME)
        );
      });

      this.scheduleReverseData.value = RevData.sort(function (a, b) {
        return (
          DateTime.fromISO(b?.MEETINGSTARTTIME) -
          DateTime.fromISO(a?.MEETINGSTARTTIME)
        );
      });
      console.log(
        "scheduleData After sort" + JSON.stringify(this.scheduleData.value)
      );
      const toggle = document.querySelector("ion-tab-bar");
      toggle.setAttribute("selected-tab", "scheduling");

      // this.scrollToBottom();
    }
  },
  methods: {
    filterScheduleData() {
      let that = this;
      return this.mqttStore.messages.events.ui.staff.schedule.filter(function (
        el
      ) {
        return (
          el.PATIENTUSERID == that.scheduleStore.selectedSchedule.USERID &&
          el.STAFFUSERID == that.userStore.userId
        );
      });
    },
    async openModal() {
      const modal = await modalController.create({
        id: "schedulingModel",
        component: schedulingModal,
        cssClass: "modalPatientVital",
      });
      modal.present();
      modal.onDidDismiss().then((data) => {
        var d = JSON.stringify(data);
        console.log("Dismiss Data " + d);
        // const toggle = document.querySelector("ion-tab-bar");
        // toggle.setAttribute("selected-tab", "scheduling");
        return;
      });
    },
  },
});
</script>
<style scoped>

.accordionLabel {
  font-size: 12px;
  /* left: 10px; */
  /* float: left; */
  font-family: "OpenSansRegular";
  font-weight: 700;
}
.NewMeeting {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.labelcontent {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  /* align-items: center;
  text-align: center; */
  letter-spacing: 1px;
  /* text-transform: capitalize; */
}
</style>
