<template>
  <ion-page>
    <ion-content v-if="userStore" :fullscreen="true">
      
    <welcome v-if="welcomeStore.showWelcome && userStore.role == 'patient'"/>

    <patientdata v-if="!welcomeStore.showWelcome && userStore.role == 'patient'" />
    <WelcomeStaff v-if="welcomeStore.showWelcome && userStore.role == 'staff'"/>    
    <staffdata v-if="!welcomeStore.showWelcome && userStore.role == 'staff'"/> 
      
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';
import { useWelcomeStore } from '../stores/welcome'
import { useUserStore } from '../stores/user'
import Welcome from '../views/patient/Welcome.vue'
import WelcomeStaff from '../views/staff/WelcomePage.vue'
import staffReviewAlert from "../views/staff/rpmReviewAlert.vue";
import rpmPatientData from "../views/patient/rpmPatientData.vue";

export default defineComponent({
  name: 'HomeView',
  components: {welcome:Welcome,patientdata:rpmPatientData, IonContent, IonPage, staffdata:staffReviewAlert, WelcomeStaff  },
  setup() {
    const welcomeStore = useWelcomeStore()
    const userStore = useUserStore()
    return {welcomeStore, userStore}
  }
});
</script>
