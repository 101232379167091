import { ref } from "vue";
import { defineStore } from "pinia";
import { useUserStore } from "./user";
import { useMqttStore } from "./mqtt";

export const useskinToneStore = defineStore("skinToneStore", () => {
  const skinToneValue = ref("");

  function setSkinTone(val) {
    skinToneValue.value = val;
    
    //publish selection
    const userStore = useUserStore();
    const mqttStore = useMqttStore();
      let m = {
        userId: userStore.userId,
        ui:'skintone',
        value: val,
        timestamp: Date.now()
      }
      let topic = `patient_skintone/${m.userId}`
      let message = JSON.stringify(m)
      //console.log('m',message)
      mqttStore.publishMqtt(topic,message)
  }

  return { skinToneValue, setSkinTone };
},
  {
    persist: true,
  }
);
