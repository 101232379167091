<template>
  <realtimechart :chartdata="chartData" :chartname = "chartname"></realtimechart>
</template>

<script>
import {
    useMqttStore
} from '../stores/mqtt'
import { defineComponent, ref, onMounted } from "vue";
import realtimechart from "./rpmMainLiveRealTimeChart.vue";

import {
  // IonLabel,
  // IonGrid,
  // IonCol,
  // IonRow,
  // IonCard,
  // IonCardContent,
  // IonTabBar,
  // IonTabButton,
  // IonTabs,
  // IonRouterOutlet,
  //IonList,
  //IonItem
  // IonContent,
} from "@ionic/vue";

export default defineComponent({
  name: "MainLive",
  components: {
    realtimechart
  },
  props:
  {
    chartType : String
  },

  setup(props) {
    const mqttStore = useMqttStore();
    var chartname = ref(props.chartType);
    var chartData = ref([]);
   

    var liveDataToday = ref({
      t : mqttStore.messages.patientData.t,
      hr : mqttStore.messages.patientData.hr,
      rr : mqttStore.messages.patientData.rr,
      bp : mqttStore.messages.patientData.bp,
      spo2   : mqttStore.messages.patientData.spo2
    });

    var liveDataWeek = ref({
      t : mqttStore.messages.patientData7.t,
      hr : mqttStore.messages.patientData7.hr,
      rr : mqttStore.messages.patientData7.rr,
      bp : mqttStore.messages.patientData7.bp,
      spo2   : mqttStore.messages.patientData7.spo2
    });

    var liveDataMonth = ref({
      t : mqttStore.messages.patientData30.t,
      hr : mqttStore.messages.patientData30.hr,
      rr : mqttStore.messages.patientData30.rr,
      bp : mqttStore.messages.patientData30.bp,
      spo2   : mqttStore.messages.patientData30.spo2
    })

    
      if (props.chartType === "today")
      {
        chartData.value = liveDataToday.value;
      }
      else if (props.chartType === "week")
      {
        chartData.value = liveDataWeek.value;
      }
      else if (props.chartType === "month")
      {
        chartData.value = liveDataMonth.value;
      }

    // onMounted(() => {
    //   console.log("OnMounted props : " + JSON.stringify(props));
    //   chartname.value = props.chartType;
    //   if (props.chartType == "lasthour")
    //   {
    //     chartData.value = liveData1hour;
    //   }
    //   else if (props.chartType == "month")
    //   {
    //     chartData.value = liveDataMonth;
    //   }

    // });

    return {
      confirm,
      close,
      realtimechart,
      onMounted,
      mqttStore,
      liveDataToday,
      liveDataWeek,
      liveDataMonth,
      chartname,
      chartData
      //chartType
    };
  },
});
</script>
<style scoped>
ion-list {
    --ion-item-background: transparent;
    text-align: center;
}
.ion-label-right
{
  font-family: 'OpenSansRegular';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 160%;
  /* or 22px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;

  /* Button 1 */

  color: #EF9116;

}
.ion-label-text
{
  font-family: 'OpenSansRegular';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 35px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  white-space: normal;
  /* background */

  color: #FEFEFE;
}
.ion-card-div
{
   color: white;
    border-radius: 0px;
    /* width: 90px;
    height: 30px;
    text-align: center;
    background: orange; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 4px 12px;
    gap: 10px;

   
    width: 178px;
    height: 97px;     */
    margin: 0px;

    /* Button 1 */

    background: #EF9116;
    border-radius: 5px;
}
.rpm-div-second {
  /* position: fixed; */
  /* width: 200px; */

  /* Main heading */
  background: black;
}

.label-selected {
  color: white;
}

.tab-item-active {
  background: green;
}

.ion-card-tab {
  position: fixed;
  top: 14%;
  width: 50%;
  height: 25px;
}
ion-tab-bar {
  border: 1px solid orange;
  border-radius: 10px;
}

.ion-tab-button:hover {
  color: blue;
}

.ion-tab-button-selected {
  border-radius: 10px;
  background-color: orange;
}
.ion-tab-button-unselected {
  background-color: #f5f5f5;
}
ion-tab-button-selected {
  background-color: red;
}
.ion-tab-bar-right {
  border-right: 0px solid orange;
}
ion-tab-button.tab-selected {
  --background-color: orange;
}

.ion-tab-label {
  font-family: "OpenSansRegular";

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* identical to box height, or 22px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;

  /* Main heading */
}

.ion-tab-label-unselected {
  color: #333333;
}

.table {
  position: absolute;
  width: 395px;
  height: 103px;
  left: 0px;
  top: 0px;
  /* border:1px solid black; */

  background: #ffffff;
}

.ion-card {
  width: 395px;
  background: #f5f5f5;
}

.ion-grid-row {
  background: #f5f5f5;
  height: 60%;
}

.span-class {
  /* width: 395px; */
  left: 0px;
  top: 0px;
  background: #f5f5f5;
}
</style>
