<template>
    <ion-page style="border-radius: 5%;">
    <ion-content ref="content">
    <div style="border-radius: 15%">
        <ion-list lines="none">
            <ion-list-header style="text-align: center">
                <ion-label style="margin-top: 5px" class="success-text-heading">Scheduled event </ion-label>
                <ion-icon :icon="closeCircle" @click="dismiss" style="zoom: 1.5">
            </ion-icon>                
            </ion-list-header>
        </ion-list>
        <ion-list lines="none">
            <ion-list-header style="">
                <ion-label style="margin-top: 5px" class="success-heading">Meeting Start</ion-label>
            </ion-list-header>
            <ion-item style="--background:lightgreen">
                <ion-label class="success-text">{{DateTime.fromISO(this.selectedEvent.MEETINGSTARTTIME, {
                    setZone: false,
                  }).toLocaleString(
          DateTime.DATETIME_MED
        )}} </ion-label>
            </ion-item>
        </ion-list>
        <ion-list lines="none">
            <ion-list-header style="">
                <ion-label style="margin-top: 5px" class="success-heading">Meeting End</ion-label>
            </ion-list-header>
            <ion-item style="--background:lightgreen">
                <ion-label class="success-text">
                {{DateTime.fromISO(this.selectedEvent.MEETINGENDTIME, {
                    setZone: false,
                  }).toLocaleString(DateTime.DATETIME_MED)}} </ion-label>
            </ion-item>
        </ion-list>
        <ion-list lines="none">
            <ion-list-header style="">
                <ion-label style="margin-top: 5px" class="success-heading">Scheduled by </ion-label>
            </ion-list-header>
            <ion-item style="--background:lightgreen">
                <ion-label class="success-text">
                {{
                    loggedInUserRole.value == "patient" ?  this.selectedEvent.STAFF.NICKNAME
                    : this.selectedEvent.PATIENT.NICKNAME
                    
                }} 
            </ion-label>
            </ion-item>
        </ion-list>
        <ion-list lines="none">
            <ion-list-header style="">
                <ion-label style="margin-top: 5px" class="success-heading">Scheduled on</ion-label>
            </ion-list-header>
            <ion-item style="--background:lightgreen">
                <ion-label class="success-text">

                
                    {{DateTime.fromISO(this.selectedEvent.TIMESTAMP, {
                        setZone: false,
                      }).toLocaleString(DateTime.DATETIME_MED)}}
                      

                </ion-label>
            </ion-item>
        </ion-list>
        <ion-list lines="none">
            <ion-list-header style="">
                <ion-label style="margin-top: 5px" class="success-heading">Comments</ion-label>
            </ion-list-header>
            <ion-item style="--background:lightgreen">
                <ion-label class="success-text">
                    {{ this.selectedEvent.COMMENTS }}
                </ion-label>
            </ion-item>
        </ion-list>

            <!-- <ion-item lines="none">
                <ion-label style="white-space: inherit" slot="start">
                    Start time :
                </ion-label>
                <ion-label style="white-space: inherit" class="success-text" slot="">
                    {{this.selectedEvent.MEETINGSTARTTIME}}
                </ion-label>
            </ion-item> -->
            <!-- <ion-item lines="none" class="successModalIonItem">
        <div class="center-button">
          <ion-button id="doneButton" @click="dismiss" class="successButton"
            >Done</ion-button
          >
        </div>
      </ion-item> -->
        
    </div>
</ion-content>
  </ion-page>
</template>
<script lang="js">
import { DateTime } from "luxon";
import { defineComponent, ref} from "vue";
import { closeCircle } from "ionicons/icons";
import { useUserStore } from "../stores/user";

import {
    //IonButton, 
    IonList, IonItem, IonLabel, IonIcon,
    IonPage,
  IonContent,
} from "@ionic/vue";

export default defineComponent({
    name: "calendarEventModal",
    components: {
        // IonButton,
        IonPage,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonIcon
    },
    props: {
        selectedEvent: Object
    },
    mounted() {
        // console.log('heading : ' + this.$props.heading)
        console.log('props ' + JSON.stringify(this.selectedEvent));
    },

    methods: {
        dismiss() {
            const modal = document.querySelector("#calendarEventModal");
            if (modal !== null) modal.dismiss();
        },
    },
    setup() {
    // const welcomeStore = useWelcomeStore()
     const userStore = useUserStore();
     const loggedInUserRole = ref(userStore?.user["com.rpmone.app/roles"][0]);
    // const ionRouter = useIonRouter();
  
    return {
      //welcomeStore,
      closeCircle,
      DateTime,
      userStore,
      loggedInUserRole
    //   ionRouter,
      //   caretUpOutline,
      //   chevronForward,
      //  addOutline
    };
  },
});
</script>
<style>
.successButton {
    font-family: "OpenSansRegular";
    font-size: 15px;
    font-weight: 600;
    width: 50%;
    height: 50%;
    letter-spacing: 0.5px;
}

.center-button {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

}

.success-heading {
    font-family: "OpenSansRegular";
    font-size: 15px;
    font-weight: 700;
}

.success-text {
    font-family: "OpenSansRegular";
    font-size: 13px;
    font-weight: 700;
}
.success-text-heading
{
    font-family: "OpenSansRegular";
    font-size: 20px;
    font-weight: 700;
}
</style>
