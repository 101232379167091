<template>
  <ion-page>
    <ion-content ref="content" :scroll-y="true" :scrollEvents="true">
      <ion-accordion-group :multiple="true" :value="['first']">
        <ion-accordion
          value="first"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" style="font-size: xx-small">
            <ion-label class="accordionLabel">My Calendar</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <vue-cal
              ref="vuecal"
              :events="this.eventProperties"
              :time-from="8 * 60"
              :time-to="19 * 60"
              :time-step="60"
              active-view="week"
              :disable-views="['years', 'year']"
              timeFormat="hh:mm {am}"
              twelveHour="true"
              timeCellHeight="38"
              :on-event-click="onEventClick"
              :selected-date="selectedDate"
              title="Scheduling Calendar"
              today-button
            >
              <template v-slot:no-event>
                <div></div>
              </template>
              <template #today-button>               
                <div>
                  <ion-icon :icon="locateOutline" style="zoom: 2.3"></ion-icon>
                </div>
              </template>
            </vue-cal>
          </div>
        
        </ion-accordion>
      </ion-accordion-group>
      <!-- <div title="Click to refresh calendar" style="position: fixed; bottom: 0%; right: 4%">
             
          </div> -->
     
        <div class="row">
   
        <ion-button class="button-refresh-calendar" @click="refreshCalendar">
            <ion-icon :icon="refreshCircleSharp" style="zoom: 3.3;float: right; margin-right: 4px;" ></ion-icon>
            Refresh
          </ion-button>
          
         
            <ion-button style="--border-radius: 40px;float:right;margin-right: 10px;" @click="goToMyStaff" slot>
          <!-- <ion-icon :icon="addOutline" style="zoom: 1.3"></ion-icon> -->
          <ion-label class="NewMeeting">Schedule a Meeting</ion-label>
        </ion-button>
           
          </div>

        
    
    </ion-content>
  </ion-page>
</template>

<script>
import { DateTime } from "luxon";
import VueCal from "vue-cal";
import "../../public/assets/css/vuecal.css";
import { defineComponent, ref, watchEffect } from "vue";
import { useIonRouter } from "@ionic/vue";
import { useUserStore } from "../stores/user";
import { useMqttStore } from "../stores/mqtt";
import { useKsqlStore } from "../stores/KSQL";
import { refreshCircleSharp, locateOutline } from "ionicons/icons";
import calendarEventModal from "../components/calendarEventModalPopup.vue";

import {
  IonPage,
  IonContent,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonIcon,
  //IonButton,
  // IonIcon,
  IonButton,
  IonAccordion,
  modalController,
} from "@ionic/vue";

//import { useUserStore } from "../stores/user";

export default defineComponent({
  name: "mySchedule",
  data() {
    return {
      showDialog: false,   
      // events: [
      //   {
      //     start: "2023-02-03 10:35",
      //     end: "2023-02-03 11:30",
      //     title: "Doctor appointment",
      //     content: '<i class="icon material-icons">shopping_cart</i>',
      //     class: "leisure",
      //   },
      //   {
      //     start: "2023-02-01 16:30",
      //     end: "2023-02-01 17:15",
      //     title: "Dentist appointment",
      //     content: '<i class="icon material-icons">shopping_cart</i>',
      //     class: "leisure",
      //   },
      // ],
      // events : this.loggedInUserRole == "patient"
      //   ? this.mqttStore.messages.events.ui.patient.schedule
      //   : this.mqttStore.messages.events.ui.staff.schedule
    };
  },
  components: {
    IonContent,
    IonPage,
    VueCal,
    IonAccordionGroup,
    IonIcon,
    //IonIcon,
    IonButton,
    IonItem,
    IonLabel,
    IonAccordion,
  },
  setup() {
    // const welcomeStore = useWelcomeStore()
    const ksqlStore = useKsqlStore();
    const userStore = useUserStore();
    const ionRouter = useIonRouter();
    const mqttStore = useMqttStore();
    const loggedInUserRole = ref(userStore?.user["com.rpmone.app/roles"][0]);
    const scheduleEvents = ref(
      loggedInUserRole.value == "patient"
        ? mqttStore.messages.events.ui.patient.schedule
        : mqttStore.messages.events.ui.staff.schedule
    );

    //const scheduleEvents = ref(mqttStore.messages.events.ui.patient.schedule);


    const eventProperties = ref([]); 

    watchEffect(() => {
      // mypatients.value = mqttStore.messages.events.ui.staff.mypatients;
      // myStaff.value = mqttStore.messages.events.ui.patient.patientstaff;

      eventProperties.value = scheduleEvents?.value?.map((event) => {
      let properties = {
        start: DateTime.fromISO(event?.MEETINGSTARTTIME,  {
                setZone: false,
              }).toLocaleString(
          DateTime.DATETIME_MED
        ),
        end: DateTime.fromISO(event?.MEETINGENDTIME,  {
                setZone: false,
              }).toLocaleString(
          DateTime.DATETIME_MED
        ),
        //end:  event.MEETINGENDTIME,
        title: "Meeting",
        class: "leisure",
        data: event
      };
      return properties;
    });

    });

    //let events  = this.events;
   // console.log("events" + JSON.stringify(eventProperties));
    return {
      //welcomeStore,
      VueCal,
      userStore,
      ionRouter,
      mqttStore,
      loggedInUserRole,
      scheduleEvents,
      eventProperties,
      refreshCircleSharp,
      ksqlStore,
      locateOutline

      //   caretUpOutline,
      //   chevronForward,
      //  addOutline
    };
  },
  watch: {
    scheduleEvents: {
      handler: function () {
        // console.log(oldval);
        // console.log(newval);
      this.eventProperties.value = this.scheduleEvents?.value?.map((event) => {
      let properties = {
        start: DateTime.fromISO(event?.MEETINGSTARTTIME).toLocaleString(
          DateTime.DATETIME_MED
        ),
        end: DateTime.fromISO(event?.MEETINGENDTIME).toLocaleString(
          DateTime.DATETIME_MED
        ),
        //end:  event.MEETINGENDTIME,
        title: "Meeting",
        class: "leisure",
        data: event
      };
      return properties;
    });
         
      },

      deep: true,
    },
  },
  mounted() {
        // console.log('heading : ' + this.$props.heading)
       // console.log('I am scheduling mounted event');
    },
  methods: {
    refreshCalendar(e)
    {
      if (this.loggedInUserRole == "patient")
      {
        // userStore?.user["com.rpmone.app/userId"]
        this.ksqlStore.pullSchedulePatient(this.userStore?.user["com.rpmone.app/userId"]).then(() =>
        {
          //alert('Hi');
          this.scheduleEvents = this.mqttStore.messages.events.ui.patient.schedule;

        }
        );
        e.stopPropagation();
      
      }
      else
      {
        this.ksqlStore.pullScheduleStaff(this.userStore?.user["com.rpmone.app/userId"]);
        this.scheduleEvents = this.mqttStore.messages.events.ui.staff.schedule;
      }
      
      
    },

    goToMyStaff() {
      console.log("going to my staff");
      this.ionRouter.push("/StaffSchedule");
    },

    onEventClick(event, e) {
      this.selectedEvent = event;
      this.showDialog = true;
      this.openModal(event);

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
    },

    async openModal(event) {
      const modal = await modalController.create({
        id: "calendarEventModal",
        component: calendarEventModal,
        cssClass: "modalCalenderEvent",
        componentProps:{
          selectedEvent: event.data
        }
      });
      modal.present();
      modal.onDidDismiss().then((data) => {
        var d = JSON.stringify(data);
        console.log("Dismiss Data " + d);
        // const toggle = document.querySelector("ion-tab-bar");
        // toggle.setAttribute("selected-tab", "scheduling");
        return;
      });
    },
  },
});
</script>
<style scoped>

.button-refresh-calendar
{
  /* float:right; */
  width:auto;
  --border-radius: 40px;
  font-family: "OpenSansRegular";
  font-size: 14px;
  font-weight: 600;
  color: black;
  float:left;
  margin-left:10px

}

.NewMeeting {
  font-family: "OpenSansRegular";
  font-size: 14px;
  font-weight: 600;
  color: black;
}

ion-icon {
  font-size: 12px;
}

ion-item {
  cursor: pointer;

  --background-hover: #ef9116;
}
ion-item:hover {
  cursor: hand;
  --background: #ffb555;
  --background-hover: #ef9116;
}

ion-icon:hover {
  cursor: hand;
  /* background:red; */
}

ion-icon {
  cursor: hand;
  /* background:lightgrey; */
}

.accordionLabel {
  font-size: 12px;
  /* left: 10px; */
  /* float: left; */
  font-family: "OpenSansRegular";
  font-weight: 700;
}
.ion-item {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 2px dotted var(--ion-color-medium);
  /* --background-color:#ef9116; */
  --padding-start: 5px;
  --padding-end: 5px;
}

ion-button {
  /* --background: #ef9116; */
  --color: white;
  font-family: "OpenSansRegular";
  font-style: normal;
  height: 40px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 1px;
  text-transform: capitalize;
}

/* General CSS Setup */
body {
  background-color: lightblue;
  font-family: "Ubuntu-Italic", "Lucida Sans", helvetica, sans;
}


/* container */
.container {
  padding: 5% 5%;
}

.vuecal {
  margin: auto;
  /* max-width: 1300px;
  max-height: 662px; */
}

.vuecal * {
  font-family: "OpenSansRegular";
}

.event {
  background-color: #f0fff1;
}

.vuecal--green-theme .vuecal__title {
  font-family: "OpenSansRegular";
  font-size: 14px;
  font-weight: 700;
  color: red;
  --background-color: yellow;
}

vue-cal.vuecal--green-theme.vuecal__header {
  font-size: 14px;
  font-family: "OpenSansRegular";
  font-weight: 600;
}

.vuecal__title-bar {
  font-family: "OpenSansRegular";
  font-size: 14px;
  font-weight: 700;
}
vuecal__menu {
  font-family: "OpenSansRegular";
  font-size: 14px;
  font-weight: 700;
}

.vuecal__view-btn {
  font-family: "OpenSansRegular";
  font-size: 16px;
  font-weight: 700;
}
.vuecal__event {
  background-color: rgba(173, 216, 230, 0.5);
  box-sizing: border-box;
  padding: 5px;

  /* &.lunch {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px);
  } */
}
</style>
