<template>
  <ion-page>
    <ion-content v-if="userStore" :fullscreen="true">
      <scheduleUsers  
      v-if="userStore.role == 'staff' && this.mypatients?.value?.length > 0"       
        :key="componentKey"
      />    

      <scheduleStaff  
      v-if="userStore.role == 'patient' && this.mystaff?.value?.length > 0"       
        :key="componentKey"
      />    
      
    
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, reactive, watchEffect } from "vue";
import scheduleUsers from "../../components/scheduleListUsers.vue";
import scheduleStaff from "../../components/scheduleListStaff.vue";
import { IonPage, IonContent } from "@ionic/vue";
import { useUserStore } from "../../stores/user";
import { useMqttStore } from "../../stores/mqtt";
export default defineComponent({
  name: "scheduleUsersView",
  components: { scheduleUsers, IonPage, IonContent, scheduleStaff },
  setup() {
    const userStore = useUserStore();
    const mqttStore = useMqttStore();
    const mypatients = reactive(mqttStore.messages.events.ui.staff.mypatients);
    const mystaff = reactive(mqttStore.messages.events.ui.patient.patientstaff);

    console.log('userStore.role' +  userStore.role);
   
    const componentKey = ref(0);
    const forceRerender = () => {
      componentKey.value += 1;
    };
    watchEffect(() => {
      mypatients.value = mqttStore.messages.events.ui.staff.mypatients;  
      mystaff.value = mqttStore.messages.events.ui.patient.patientstaff;        
    });

    return { userStore, forceRerender, mqttStore, mypatients, scheduleUsers, mystaff, scheduleStaff, componentKey };
  },
  mounted() {
    //console.log('abc');
   // this.forceRerender();
  },
});
</script>
