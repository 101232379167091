<template>
  <ion-page>   
    <ion-content>
      <patientDetail :PATIENTUSERID = "userIdValue"></patientDetail>
    </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent, ref,  } from "vue";
import { IonPage, IonContent} from "@ionic/vue";
import patientDetail from "../../components/patientDetails.vue";

export default defineComponent({
  name: "patientDetails",
  components: { IonContent, IonPage,
    patientDetail 
  },
  props: ['PATIENTUSERID'],
  setup(props) {

    const userIdValue = ref(props.PATIENTUSERID);

    const toggle = document.querySelector("ion-tab-bar");
    toggle.setAttribute("selected-tab", "home");
    
    return { 
           userIdValue };
  },
});
</script>
