<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-label class="notificationText">Notifications</ion-label>
        </ion-row>
        <ion-row
          style="display: inline"
          v-for="(item, index) in this.groupByData"
          :key="index"
          :value="item"
        >
       <!-- {{ item }} -->
          <PatientNotification
            :data="item"
            :index="index"
            @close="close"
          ></PatientNotification>
        </ion-row>
        <ion-row
          style="display: inline"
          v-for="(item, index) in this.scheduledata"
          :key="index"
          :value="item"
        >
       <!-- {{ item }} -->
          <PatientNotification
            :data="item"
            :index="index"
            @close="close"
          ></PatientNotification>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent, ref, watchEffect } from "vue";
import PatientNotification from "../../components/notification.vue";
import {
  IonPage,
  IonGrid,
  IonRow,
  IonContent,
  //IonTitle,
  IonLabel,
} from "@ionic/vue";
import { userNotificationStore } from "../../stores/notification";
import { useMqttStore } from "../../stores/mqtt";
import { useUserStore } from "../../stores/user";
export default defineComponent({
  name: "patientTabNotification",
  components: {
    PatientNotification,
    IonPage,
    IonContent,
    // IonHeader, IonToolbar,IonTitle,
    IonLabel,
    IonGrid,
    IonRow,
  },

  mounted() {
    // console.log("notifyData" + JSON.stringify(notifyData.value.notifications));
  },
  // watch: {
  //   notifications: {
  //     handler: function (val) {
  //           var len = val.length;
  //           for (var i = 0; i < len; i++) {
  //             if (this.data.filter(x => x.id == val[i].id).length > 0)
  //               this.data.push({

  //                   text: `You have a meeting Scheduled by ${ val[i].staff.nickname } from ${ DateTime.fromISO(val[i].meetingStartTime.toLocaleString(DateTime.DATETIME_MED)) } to ${ DateTime.fromISO(val[i].meetingEndTime.toLocaleString(DateTime.DATETIME_MED))}`,
  //                   id: i,
  //                   buttonText: "Connect",
  //               });

  //             this.store.setNotificationCount(len);
  //           //console.log('this.data' +  JSON.stringify(data));
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  setup() {
    const store = userNotificationStore();
    const userStore = useUserStore();
    const mqttStore = useMqttStore();
    const chatdata = ref([]);
    const scheduledata = ref([]);
    const groupByData = ref([]);
    const notifications = ref(mqttStore.messages.notifications);
    const loggedInUserRole = ref(userStore?.user["com.rpmone.app/roles"][0]);
    //   var len = notifications.value.length;
    //     for (var i = 0; i < len; i++) {
    //         data.value.push({
    //           text: `You have a meeting Scheduled by ${ notifications.value[i].staff.nickname } from ${ DateTime.fromISO(notifications.value[i].meetingStartTime.toLocaleString(DateTime.DATETIME_MED)) } to ${ DateTime.fromISO(notifications.value[i].meetingEndTime.toLocaleString(DateTime.DATETIME_MED))}`,
    //                id: i,
    //             buttonText: "Connect",
    //         });
    //     store.setNotificationCount(len);
    //     console.log('this.data' +  JSON.stringify(data));
    // }

    function close(val) {
      console.log(val);
      // const requiredIndex = data.value.findIndex((el) => {
      //   return el.id === val;
      // });
      // if (requiredIndex === -1) {
      //   return false;
      // }

      // var pendingDataNotifications = !!data.value.splice(requiredIndex, 1);
      // if (pendingDataNotifications) {
      //   store.setNotificationCount(data.value.length);
      // }
    }

//     function groupBy(list, keyGetter) {
//     const map = new Map();
//     list.forEach((item) => {
//         const key = keyGetter(item);
//         if (!map.has(key)) {
//             map.set(key, [item]);
//         } else {
//             map.get(key).push(item);
//         }
//     });
//     return map;
// }

function groupBy(objectArray, property) {
   return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
         acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
   }, {});
}


    watchEffect(() => {
      notifications.value = mqttStore.messages.notifications;
      var len = notifications.value.length;
      chatdata.value = [];
      scheduledata.value = [];
     // console.log(loggedInUserRole);

    //   const groupByNotificationType = notifications?.value.groupBy(product => {
    //   return product.type;
    // });

     // console.log('groupByNotificationType' + JSON.stringify(groupByNotificationType));


      for (var i = 0; i < len; i++) {
        let type = typeof notifications?.value[i];
        if (
          type == "string" ? notifications?.value[i]?.startsWith("chat") : null
        )
          continue;
        // if (notifications?.value[i]?.notification == undefined)
        // continue;

        let notificationTypeString;
        notificationTypeString = typeof notifications?.value[i]?.NOTIFICATION;
        let notification = notificationTypeString == "string" ? JSON.parse(notifications?.value[i]?.NOTIFICATION) : notifications?.value[i]?.NOTIFICATION;

        if (notification !== undefined && notifications?.value[i]?.NOTIFICATIONTYPE == "chatNotification") {
          chatdata.value.push({
            text: `${notification.user.nickname} 
              tried to reach you via chat with message '${
                notification.chatMessage
              }'
              at ${DateTime.fromMillis(parseInt(notifications.value[i].TIMESTAMP), { zone: "utc" }).toFormat(
                "yyyy-MM-dd HH:mm a"
              )}`,

            id: i,
            buttonText: "Chat",
            icon : "chatbubbles",
            patientUserId:notification?.patientUserId,
            staffUserId:notification?.staffUserId,
            notification: notification,
            type: "chatNotification"
          });
        } else 
        if (notification !== undefined && notifications?.value[i]?.NOTIFICATIONTYPE == "ScheduleNotification") {
          scheduledata.value.push({
            text: `You have a meeting Scheduled by ${
              loggedInUserRole.value == "patient"
                ? notification?.staff.nickname
                : notification?.patient.nickname
            } 
              from ${DateTime.fromISO(
                notification?.meetingStartTime
              ).toLocaleString(DateTime.DATETIME_MED)} 
              to ${DateTime.fromISO(
                notification?.meetingEndTime
              ).toLocaleString(DateTime.DATETIME_MED)}`,
            id: i,
            buttonText: "Check",
            icon : "calendar",
            patientUserId:notification?.PATIENTUSERID,
            staffUserId:notification?.STAFFUSERID,
            notification: notification,
            type: "ScheduleNotification"
          });
        }
        // if (notifications.value.filter(x => x.id == notifications.value[i].id).length > 0)
        // {
        // DateTime.fromISO(notifications.value[i].meetingEndTime).toLocaleString(DateTime.DATETIME_MED)
        //console.log('ab ' + DateTime.fromISO(notifications.value[i].meetingStartTime).toLocaleString(DateTime.DATETIME_MED));

       // notifications?.value?.shift();
      //  if (chatdata.value.length > 0)
      //  {
       // groupByData.value =  groupBy(chatdata.value, 'buttonText');
       //}
        

        //console.log("this.pet" + JSON.stringify(grouped));

        // if (scheduledata?.value?.length > 0 && groupByData?.value?.length > 0)
        // {
        //   groupByData.value = [...groupByData, ...scheduledata];
        //   console.log(groupByData.value);
        // }

        // store.setNotificationCount(len);
        //console.log("this.data" + JSON.stringify(data));
        //}
      }

      groupByData.value =  groupBy(chatdata.value, 'buttonText');
      store.setNotificationCount(len);

    });

    return {
      PatientNotification,
      // dataNotifications,
      close,
      mqttStore,
      notifications,
      chatdata,
      scheduledata,
      store,
      DateTime,
      userStore,
      loggedInUserRole,
      groupBy,
      groupByData
    };
  },
});
</script>
<style scoped>
.notificationText {
  width: 175px;
  height: 32px;

  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 130%;
  /* or 32px */

  display: flex;
  align-items: center;
  letter-spacing: 1px;
}
</style>
