<template>
  <ion-content class="content-transparent">
    <ion-grid>
      <ion-row>
        <ion-col
          size="6"
          v-for="(item, index) in boxData"
          :key="index"
          :value="item.id"
        >
          <LaunchPadBox
            :firstLabel="item.firstLabel"
            :image="item.image"
            :additionalInfo="item.additionalInfo"
            :navigateTo="item.navigateTo"
          ></LaunchPadBox>
        </ion-col>
      </ion-row>
      <ion-row style="padding-top: 20px">
        <ion-col>
          <ion-button class="btn-complete" @click="redirectToCheckIn">
            <ion-img
              slot="start"
              class="rpm-image"
              src="assets//images//RPM-One_logo_Welcome.png"
            >
            </ion-img>
            COMPLETE CHECK IN
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>

<script>
import { personCircle, calendar, home } from "ionicons/icons";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import LaunchPadBox from "../components/rpmLaunchPadBox.vue";
import {
  IonContent,
  IonButton,
  IonGrid,
  IonImg,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";

export default defineComponent({
  name: "MainPopUp",
  components: {
    IonContent,
    IonButton,
    IonGrid,
    IonImg,
    IonRow,
    IonCol,
    LaunchPadBox,
  },
  methods: {
    dismiss() {
      return modalController.dismiss(null, "dismiss");
    },
    confirm() {
      return modalController.dismiss(this.name, "confirm");
    },
    redirectToCheckIn() {
      this.router.push("/checkin");
      this.dismiss();
    },
  },

  setup() {
    const router = useRouter();
    onMounted(() => {
      console.log("Live component is now mounted.");
    });

    const boxData = ref([
      {
        id: 1,
        image: "assets//icon//home.svg",
        firstLabel: "Home",
        additionalInfo: "",
        navigateTo: "/home",
      },
      {
        id: 2,
        image: "assets//icon//chatbubbles.svg",
        firstLabel: "Chat",
        additionalInfo: "",
        navigateTo: "/connect",
      },
      {
        id: 3,
        image: "assets//icon//calendar.svg",
        firstLabel: "Scheduling",
        additionalInfo: "",
        navigateTo: "/Schedule",
      },
      {
        id: 4,
        image: "assets//icon//notifications-sharp.svg",
        firstLabel: "Notifications",
        additionalInfo: "",
        navigateTo: "/notifypatient",
      },
    ]);

    return {
      onMounted,
      personCircle,
      modalController,
      boxData,
      LaunchPadBox,
      calendar,
      home,
      close,
      router,
      //redirectToCheckIn
    };
  },
});
</script>
<style scoped>
.btn-complete {
  --background: #ef9116;
  --color: white;
  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  height: 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 15px;
  width: 100%;
}

.rpm-image {
  position: fixed;
  left: 2%;
  width: 43px;
  height: 27px;
}

.btn-label {
  width: 179px;
  height: 26px;

  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  /* identical to box height, or 26px */
  background: #ef9116;
  letter-spacing: 0.5px;
  border-radius: 15px;
  color: #ffffff;
}

.content-transparent {
  --background: transparent !important;
}
</style>
