<template>
  <!--  <ion-header> -->
  <ion-toolbar style="font-family: 'OpenSansRegular'; text-align: center; width: 101%;">
    <ion-title> Digital Triage Assistant </ion-title>
  </ion-toolbar>
  <!-- </ion-header>  -->
  <ion-content
    ref="content"
    :scroll-y="true"
    :scrollEvents="true"
    style="height: 350px"
  >
    <ion-list v-if="messages && messages.length > 0">
      <ion-item
        lines="none"
        v-for="message in messages"
        :key="message.timestamp"
        :message="message"
      >
        <ion-avatar v-if="message.PICTURE" slot="start">
          <img :src="message.PICTURE" />
        </ion-avatar>
        <ion-label
          v-if="message.contentType && message.contentType == 'PlainText'"
          class="ion-text-wrap messageText"
          >{{ message.text }}</ion-label
        >
        <ion-card
          style="width: 100%"
          v-else-if="
            message.contentType && message.contentType == 'ImageResponseCard'
          "
        >
          <ion-card-header>
            <ion-card-title class="ion-header-title">{{
              message.card.title
            }}</ion-card-title>
            <ion-card-subtitle v-if="message.card.subtitle">{{
              message.card.subtitle
            }}</ion-card-subtitle>
            <ion-card-content>
              <!-- <ion-chip size="small" fill="solid" color="primary" @click="onRespondeCardButtonClick(button)" 
                    v-for="button in message.card.buttons" :key="button.value">{{button.text}}</ion-chip>
                 -->
              <ion-item
                style="width: 100%"
                lines="none"
                v-for="button in message.card.buttons.sort((p1, p2) =>
                  p1?.index > p2?.index ? 1 : p1?.index < p2?.index ? -1 : 0
                )"
                :key="button.value"
                :index="button?.index"
              >
                <ion-button
                  class="buttonText"
                  @click="onRespondeCardButtonClick(button)"
                >
                  {{ button.text }}
                </ion-button>
              </ion-item>
            </ion-card-content>
          </ion-card-header>
        </ion-card>
        <ion-label v-else class="ion-text-wrap messageText">{{
          message.text
        }}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label style="minheight: 250px" class="messageText"></ion-label>
      </ion-item>
    </ion-list>
    <ion-item lines="none">
      <ion-label style="height: 350px" class="messageText"></ion-label>
    </ion-item>
  </ion-content>
  <!-- <ion-footer>
    <ion-toolbar>
      <ion-item>
        <ion-input
          @keydown.enter="onSendClick"
          v-model="typedInput"
          placeholder="type message"
        ></ion-input>
        <ion-button @click="onSendClick">
          <ion-icon name="send"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-toolbar>
  </ion-footer> -->
</template>

<script>
import { defineComponent } from "vue";
import {
  LexRuntimeV2Client,
  RecognizeTextCommand,
} from "@aws-sdk/client-lex-runtime-v2";

import {
  IonAvatar,
  //IonIcon,
  IonTitle,
  IonToolbar,
  // IonFooter,
  IonContent,
  //IonChip,
  IonButton,
  // IonInput,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue";

import { v4 as uuidv4 } from "uuid";
import { useMqttStore } from "@/stores/mqtt";
import { useUserStore } from "@/stores/user";

export default defineComponent({
  name: "LexBot",
  components: {
    IonAvatar,
    // IonIcon,
    IonTitle,
    IonToolbar,
    // IonFooter,
    IonContent,
    // IonChip,
    IonButton,
    //IonInput,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonList,
    IonItem,
    IonLabel,
  },
  props: {
    message: Object,
    setShowData: {
      type: Function,
    },
  },
  setup() {
    const mqttStore = useMqttStore();
    const userStore = useUserStore();
    return {
      mqttStore,
      userStore,
    };
  },
  data() {
    return {
      lexClient: null,
      lexKey: process.env.VUE_APP_LEX_KEY,
      lexSecret: process.env.VUE_APP_LEX_SECRET,
      lexRegion: process.env.VUE_APP_LEX_REGION,
      lexSessionId: "",
      lexSessionAttributes: null,
      botName: process.env.VUE_APP_LEX_BOT_NAME_ID,
      botAlias: process.env.VUE_APP_LEX_BOT_ALIAS_ID,
      messages: [],
      typedInput: "",
    };
  },
  watch: {
    message(newVal) {
      this.lexSessionId = newVal.lexSessionId;
      this.messages = null;
      this.messages = [];
      let m = {
        text: newVal.UTTERANCE,
        userId: newVal.USER_ID,
        username: newVal.NICKNAME,
        picture: newVal.PICTURE,
        lexSessionId: newVal.lexSessionId,
      };
      //console.log('chat message',m)
      this.messages.push(m);
      this.sendToLex(m);
      //this.scrollToBottom();
    },
  },
  mounted() {
    this.initLex();
  },
  methods: {
    clearMessages() {
      this.messages = [];
    },
    /* onScroll(e){
      console.log(e)
    }, */
    scrollToBottom() {
      // extra ion-item at end is required because this doesn't scroll up enough the see all of last one
      this.$refs.content.$el.scrollToBottom(1000);
    },
    publishCheckin(topic, m) {
      this.mqttStore.publishMqtt(topic, m);
    },
    publishDigitalTriageAction(topic, m) {
      this.mqttStore.publishMqtt(topic, m);
    },
    initLex() {
      //console.log('lex user',this.lexUserId)
      const awsCredentials = {
        credentials: {
          accessKeyId: this.lexKey,
          secretAccessKey: this.lexSecret,
        },
        region: this.lexRegion,
      };
      this.lexClient = new LexRuntimeV2Client(awsCredentials);
      //console.log('lex client',this.lexClient)
      //this.startConversation('High Alert for Patient '+this.lexUserId)
    },
    async sendToLex(m) {
      if (m.text == undefined) return;

      let input = {
        botId: this.botName,
        botAliasId: this.botAlias,
        sessionId: m.lexSessionId,
        localeId: "en_US",
        text: m.text,
      };
      //console.log('sendtolex',input)
      const command = new RecognizeTextCommand(input);
      const response = await this.lexClient.send(command);
      console.log("lex reponse", response);
      // console.log('lex state',response.sessionState.intent.state)
      /*  content: "Would you like to review the patient's vitals and medical records?"
     contentType: "PlainText"
     imageResponseCard: undefined */

      if (response.messages && response.messages.length > 0) {
        response.messages.forEach((message) => {
          let m;
          if (message.contentType == "PlainText") {
            m = {
              contentType: message.contentType,
              text: message.content,
            };
          } else if (message.contentType == "ImageResponseCard") {
            m = {
              contentType: message.contentType,
              card: message.imageResponseCard,
            };

            m.card.buttons.forEach((element) => {
              if (element.text == "Normal") element.index = 2;
              else if (element.text == "Better") element.index = 3;
              else if (element.text == "Much Better") element.index = 4;
              else if (element.text == "Worse") element.index = 1;
              else if (element.text == "Much Worse") element.index = 0;
            });
          }

          this.messages.push(m);
          //this.scrollToBottom();
        });
      }
      /* Valid Values: Failed | Fulfilled | InProgress | ReadyForFulfillment | Waiting | FulfillmentInProgress*/
      if (response.sessionState.intent.state === "InProgress") {
        console.log("InProgress");
      }
      if (response.sessionState.intent.state === "Waiting") {
        console.log("Waiting");
      }
      if (response.sessionState.intent.state === "ReadyForFulfillment") {
        console.log("ReadyForFulfillment");
      }
      if (response.sessionState.intent.state === "FulfillmentInProgress") {
        console.log("FulfillmentInProgress");
      }
      if (response.sessionState.intent.state === "Fulfilled") {
        console.log("Fullfilled");
        this.$emit("state", "Fulfilled");
        if (response.sessionState.intent.name !== "TriageAssistant") {
          //publish checkin
          this.processCheckin(response.sessionState);
        }
      }
      if (response.sessionState.intent.state === "Failed") {
        console.log("Failed");
      }

      if (response.sessionState.sessionAttributes.loadData == "true") {
        // this.loadPatientData(true)
      } else {
        //this.loadPatientData(false)
      }
      let riskLevel = "";
      let userId = "";
      if (response.sessionState.intent.name == "TriageAssistant") {
        if (response.sessionState.intent.slots) {
          if (response.sessionState.intent.slots.riskLevel) {
            if (
              response.sessionState.intent.slots.riskLevel.value.originalValue
            ) {
              riskLevel =
                response.sessionState.intent.slots.riskLevel.value
                  .originalValue;
            }
          }
          if (response.sessionState.intent.slots.userId) {
            if (response.sessionState.intent.slots.userId.value.originalValue) {
              userId =
                response.sessionState.intent.slots.userId.value.originalValue;
            }
          }
          if (response.sessionState.intent.slots.HighRiskActions) {
            if (
              response.sessionState.intent.slots.HighRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.HighRiskActions.value
                  .originalValue
              );
            }
          }
          if (response.sessionState.intent.slots.ModerateRiskActions) {
            if (
              response.sessionState.intent.slots.ModerateRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.ModerateRiskActions.value
                  .originalValue
              );
            }
          }
          if (response.sessionState.intent.slots.MediumRiskActions) {
            if (
              response.sessionState.intent.slots.MediumRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.MediumRiskActions.value
                  .originalValue
              );
            }
          }
          if (response.sessionState.intent.slots.LowRiskActions) {
            if (
              response.sessionState.intent.slots.LowRiskActions.value
                .originalValue
            ) {
              this.processTreatmentAction(
                userId,
                riskLevel,
                response.sessionState.intent.slots.LowRiskActions.value
                  .originalValue
              );
            }
          }
        }
      }
    },
    /*  loadPatientData(val) {
        this.setShowData(val)
    }, */
    processCheckin(lexFullfillment) {
      let m = {
        id: uuidv4(),
        userId: this.userStore.userId,
        checkinData: lexFullfillment,
        timestamp: Date.now(),
      };
      //console.log('lex process checkin', m)
      let topic = `patient_checkin/${this.userStore.userId}`;
      //console.log('lex publish action',topic)
      this.publishCheckin(topic, JSON.stringify(m));
    },
    processTreatmentAction(userId, riskLevel, choice) {
      let m = {
        id: uuidv4(),
        userId: userId,
        riskLevel: riskLevel,
        action: choice,
        status: "New",
        staffUser: this.userStore.userId,
        timestamp: Date.now(),
      };
      console.log("lex process treatment", m);
      let topic = `digitaltriage_actions/${userId}`;
      //console.log('lex publish action',topic)
      this.publishDigitalTriageAction(topic, JSON.stringify(m));
    },
    onSendClick() {
      // console.log('typed',this.typedInput)
      let m = {
        text: this.typedInput,
        lexSessionId: this.lexSessionId,
      };

      this.messages.push(m);
      //this.sendToLex(m);
      this.sendToLex(m).then(() => {
        this.scrollToBottom();
      });
      this.typedInput = "";
    },
    onRespondeCardButtonClick(button) {
      let m = {
        text: button.value,
        lexSessionId: this.lexSessionId,
      };
      this.messages.push(m);
      this.sendToLex(m).then(() => {
        this.scrollToBottom();
      });
    },
  },
});
</script>

<style scoped>
.messageText {
  font-family: "OpenSansRegular";
  font-size: 15px;
}
.ion-header-title {
  text-align: center;
  font-family: "OpenSansRegular";
  font-size: 22px;
  font-weight: 700;
}
.buttonText {
  width: 100%;
  font-size: 14px;
  font-family: "OpenSansRegular";
  font-weight: 600;
  height: 32px;
}
</style>
