<template>
  <ion-card style="margin: 0px">
    <ion-card-content style="padding: 8px;">
      <chart
        ref="chartIcon"
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></chart>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { defineComponent, ref } from "vue";
import { userThemeStore } from "../stores/theme";
import { DateTime } from "luxon";
import { useMqttStore } from "../stores/mqtt";
import VueApexCharts from "vue3-apexcharts";
import {
  //IonPage,
  IonCard,
  // IonGrid,
  // IonRow,
  // IonCol,
  // IonImg,
  IonCardContent,
} from "@ionic/vue";

export default defineComponent({
  name: "RealtimeChart",
  components: {
    chart: VueApexCharts,
    IonCard,
    IonCardContent,
    //IonPage
    //IonGrid, IonRow, IonCol, IonImg
  },
  props: {
    chartdata: Object,
    chartname: String,
  },
  setup(props) {
    const chartIcon = ref("");
    const chartValue = ref(props);
    const mqttStore = useMqttStore();
    const themeStore = userThemeStore();
    function toggleSeries(checkbox) {
      // console.log(checkbox);
      chartIcon.value.toggleSeries(checkbox);
    }

    console.log("chartname" + props.chartname);

    return {
      mqttStore,
      chartIcon,
      toggleSeries,
      chartValue,
      themeStore,
    };
  },
  //   methods:
  //   {

  //   },
  data(props) {
    console.log();
    return {
      series: [
        {
          name: "Temp",
          data: this.chartValue.chartdata.t,
        },
        {
          name: "Heart Rate",
          data: this.chartValue.chartdata.hr,
        },
        {
          name: "Resp Rate",
          data: this.chartValue.chartdata.rr,
        },
        {
          name: "BP",
          data: this.chartValue.chartdata.bp,
        },
        {
          name: "SPO2",
          data: this.chartValue.chartdata.spo2,
        },
      ],
      chartOptions: {
        chart: {
          id: "patientmetrics",
          height: 350,
          type: "area",
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
          toolbar: {
            show: true,
            export: {
              csv: {
                headerCategory: "Date, Time",
                dateFormatter: function (timestamp) {
                  let ts = new Date(timestamp).toISOString();

                  let date = DateTime.fromISO(ts, {
                    setZone: true,
                  });
                  // console.log('date' + date);
                  //console.log('date to Format' + date.toFormat("dd MMM yyyy hh:mm:ss a"));
                  return date.toFormat("dd MMM yyyy, hh:mm:ss a");
                },
              },
            },
          },
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: false,
            zoomedArea: {
              fill: {
                color: "#90CAF9",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
                opacity: 0.4,
                width: 1,
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100],
            },
          },
        },
        theme: {
          mode: this.themeStore.darkTheme ? "dark" : "light",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "My  Vitals",
          align: "left",
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: "datetime",
          labels: {
            //  let that = this;
            formatter: function (val) {
              if (props.chartname == "today")
                return DateTime.fromMillis(val, { zone: "utc" })
                  .toFormat("hh:mm a")
                  .toLowerCase();
              else
                return DateTime.fromMillis(val, { zone: "utc" }).toFormat(
                  "dd MMM"
                );
            },
          },
        },
        yaxis: {
          min: 0,
          max: 170,
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: function (x) {
              if (typeof x !== "undefined" && props.chartname == "today") {
                var b = DateTime.fromMillis(x, { zone: "utc" })
                  .toFormat("hh:mm:ss a")
                  .toLowerCase();
                return b;
              } else {
                var a = DateTime.fromMillis(x, { zone: "utc" }).toFormat(
                  "dd MMM"
                );
                //.substring(11);
                return a;
              }
              //return x;
            },
          },
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
    };
  },
});
</script>
<style scoped>
.ion-icon-image {
  height: 35px;
  width: 35px;
  margin: auto;
}
</style>
