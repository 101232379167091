<template>
  <ion-page>
    
          <chatlist :PATIENTUSERID="propsValue"></chatlist> 
 
  </ion-page>
</template>


<script>
import { defineComponent, ref } from "vue";
import chatlist from "../components/chatList.vue";
import { IonPage} from "@ionic/vue";

export default defineComponent({
  name: "ChatView",
  components: { chatlist,IonPage },
  props: ['PATIENTUSERID'],
  setup(props) {
    const propsValue = ref(props);
    //console.log('ChatView Props' + JSON.stringify(props.PATIENTUSERID));  
    
    return {
      propsValue
    };
  }
});
</script>
