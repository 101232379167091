<template>
  <ion-page>
    <!-- <ion-header> -->
    <ion-toolbar>
      <ion-title> Connect with Staff : </ion-title>
    </ion-toolbar>
    <!-- </ion-header> -->
    <ion-content ref="content" :scroll-y="true" :scrollEvents="true">
      <ion-accordion-group :multiple="true" :value="['first', 'second']">
        <ion-accordion
          value="first"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" style="font-size: xx-small">
            <ion-label class="accordionLabel">My Conversations</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-list v-if="mqttStore.messages.events.ui.patient.patientstaff.length > 0">
              <ion-item
                detail="false"
                lines="none"
                v-for="user in mqttStore.messages.events.ui.patient.patientstaff"
                :key="user.USER_ID"
                class="ion-item ion-no-padding chatuser"
              >
                <ion-avatar slot="start">
                  <img :src="user.PICTURE" />
                </ion-avatar>
                <ion-item
                  style="width: 100%"
                  class="ion-no-padding chatuser"
                  lines="none"
                  @click="sendtoChatView(user)"
                >
                  <div>
                    <h4 style="margin: 0px; font-weight: 600; font-size: 18px">
                      {{ user.NICKNAME }}
                    </h4>
                    <!-- {{ user.USER_ID }} -->
                  </div>
                </ion-item>
                <!-- <ion-menu-toggle> -->
                <ion-icon
                  :icon="chevronForward"
                  class="icon"
                  style="margin-right: 0px"
                  slot="end"
                >
                </ion-icon>
                <!-- </ion-menu-toggle> -->
              </ion-item>
              <!-- <ion-menu contentId="main-content">              
                <ion-content class="ion-padding">
                  <ion-menu-toggle>
                    <ion-button>Pin</ion-button>
                  </ion-menu-toggle>
                </ion-content>
              </ion-menu> -->
            </ion-list>
            <div v-if="mqttStore.messages.events.ui.patient.patientstaff.length == 0">
            <h4 style="margin: 0px; font-weight: 600; font-size: 14px">
                     No Staff to Connect.
                    </h4>
            </div> 
            </div>      
        </ion-accordion>
        <ion-accordion
          value="second"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" style="font-size: xx-small">
            <ion-label class="accordionLabel">Recently Closed</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">Recent Connections</div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useIonRouter } from "@ionic/vue";
import { caretUpOutline, chevronForward } from "ionicons/icons";
import {
  IonAccordionGroup,
  IonAccordion,
  IonPage,
  IonAvatar,
  IonIcon,
  IonList,
  IonTitle,
  //IonHeader,
  IonToolbar,
  //IonFooter,
  IonContent,
  IonItem,
  //IonCard,IonCardContent,IonAvatar,
  //IonInput,
  //IonButton,
  IonLabel,
  // IonMenu,
  //   IonMenuToggle,
} from "@ionic/vue";
import { useChatStore } from "../stores/chat";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
export default defineComponent({
  name: "chatStaff",
  components: {
    IonIcon,
    IonAccordionGroup,
    IonAccordion,
    IonPage,
    IonAvatar,
    IonList,
    IonTitle,
    //IonHeader,
    IonToolbar,
    //IonFooter,
    IonContent,
    IonItem,
    //IonCard,IonCardContent,IonAvatar,
    //IonInput,
    //IonButton,
    IonLabel,
    // IonMenu,
    // IonMenuToggle,
  },
  setup() {
    const chatStore = useChatStore();
    const mqttStore = useMqttStore();
    const userStore = useUserStore();
    const ionRouter = useIonRouter();
    const mystaff = ref(mqttStore.messages.events.ui.patient.patientstaff);
    console.log(
      "messages.events.ui.patient.patientstaff" +
        JSON.stringify(mqttStore.messages.events.ui.patient.patientstaff)
    );

    console.log("messages.users" + JSON.stringify(mqttStore.messages.users));

    onMounted(() => {
      // this.unsubscribe(`chat/${this.subscribeToUserId}`);
      //var allusers = mypatients.value;
      if (mystaff.value.length > 0) {
        mystaff.value.forEach((user) => {
          console.log(
            "calling unsubscrible for user : " + JSON.stringify(user)
          );
          mqttStore.unsubscribeMqtt(`chat/${user.STAFFUSERID}`);
        });
      }
    });

    return {
      chatStore,
      mqttStore,
      userStore,
      ionRouter,
      mystaff,
      caretUpOutline,
      chevronForward,
    };
  },
  data() {
    return {
      typedInput: "",
    };
  },
  watch: {
    mystaff: {
      handler: function (val, oldVal) {
        // console.log('Watch mypatients val' + JSON.stringify(val));
        console.log("Watch mystaff oldval" + JSON.stringify(oldVal));
        // console.log(oldVal);
        if (val.length > 0) {
          val.forEach((user) => {
            //console.log('calling unsubscrible for user : ' + JSON.stringify(user));
            this.mqttStore.unsubscribeMqtt(`chat/${user.STAFFUSERID}`);
          });
          //console.log("Chat List Users mounted.");
        }
      },

      deep: true,
    },
  },
  methods: {
    async closeChatNotification() {      
      if (this.mqttStore.messages?.notifications !== undefined || this.mqttStore.messages?.notifications?.length > 0) {
        let result  = this.mqttStore.messages.notifications.filter(x => x.NOTIFICATIONTYPE == 'chatNotification');
        let len = result?.length;
        if (len == 0)
        return;
        for (var i = 0; i < len; i++) {
          let notificationMessage = {
            USERID: this.userStore.userId,
            NOTIFICATION: result[i]?.NOTIFICATION,
            TIMESTAMP: result[i]?.TIMESTAMP,
            STATUS: "closed",
            NOTIFICATIONTYPE: result[i]?.NOTIFICATIONTYPE,            
          };
         

          let options = {
            qos: 1,
          };

          let notificationTopic = `notifications/${notificationMessage.USERID}`;
          let that = this;
          await this.mqttStore.mqttClient.publish(
            notificationTopic,
            JSON.stringify(notificationMessage),
            options,
            (err) => {
              if (err) {
                console.log(err);
                console.log("this.mqttStore.mqttClient.publish" + err);
              } else {
                console.log(
                  "notification sent to patient " +
                    JSON.stringify(notificationMessage)
                );
                const indexOfObject =
                  that.mqttStore.messages.notifications.findIndex((object) => {

                    let notificationTypeString;
                    notificationTypeString = typeof object?.NOTIFICATION;
                    let n = notificationTypeString == "string" ? JSON.parse(object?.NOTIFICATION) : object?.NOTIFICATION;

                    let notificationMessageString;
                    notificationMessageString = typeof notificationMessage?.NOTIFICATION;
                    let ns = notificationMessageString == "string" ? JSON.parse(notificationMessage?.NOTIFICATION) : notificationMessage?.NOTIFICATION;

                    return (
                      n?.timestamp ===
                      ns?.timestamp
                    );
                  });

                //console.log(indexOfObject);
                if (indexOfObject !== -1)
                  this.mqttStore.messages.notifications.splice(
                    indexOfObject,
                    1
                  );
              }
            }
          );
        }
      }
    },
    goToPatientDetail(data) {
      this.ionRouter.push("/patientDetail/" + data.STAFFUSERID + "/Patient");
    },

    openModal() {
      alert("OpenModal");
    },

    sendtoChatView(user) {
      //console.log("sendtoChatView from myStaff" + JSON.stringify(e));
      //let that = this;
       this.closeChatNotification().then(() => {
        this.ionRouter.push("/connect/" + user.STAFFUSERID + "/chat");
        this.chatStore.setSelectedChat(user);
      });
    },

    scrollToBottom() {
      // extra ion-item at end is required because this doesn't scroll up enough the see all of last one
      this.$refs.content.$el.scrollToBottom(500);
      console.log(
        "mqttStore.messages.users" +
          JSON.stringify(this.mqttStore.messages.users)
      );
    },
    // onSendClick() {
    //   let topic = `chat/${this.mqttStore.subscribeToUserId}`

    //   let m = {
    //     topic: topic,
    //     patientUserId:this.mqttStore.subscribeToUserId,
    //     userId: this.userStore.userId, //staff or user id that is sending message
    //     user:this.userStore.user,
    //     chatMessage:this.typedInput,
    //     timestamp:Date.now()
    //   }
    //   console.log('chatmessage',m)

    //   this.mqttStore.publishMqtt(topic,JSON.stringify(m))
    //   this.typedInput = ''
    //   this.scrollToBottom()
    // }
  },
});
</script>
<style scoped>
ion-icon {
  font-size: 12px;
}

ion-item {
  cursor: pointer;

  --background-hover: #ef9116;
}
ion-item:hover {
  cursor: hand;
  --background: #ffb555;
  --background-hover: #ef9116;
}

ion-icon:hover {
  cursor: hand;
  /* background:red; */
}

ion-icon {
  cursor: hand;
  /* background:lightgrey; */
}

.accordionLabel {
  font-size: 12px;
  /* left: 10px; */
  /* float: left; */
  font-family: "OpenSansRegular";
  font-weight: 700;
}
.ion-item {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 2px dotted var(--ion-color-medium);
  /* --background-color:#ef9116; */
  --padding-start: 5px;
  --padding-end: 5px;
}

ion-button {
  /* --background: #ef9116; */
  --color: white;
  font-family: "OpenSansRegular";
  font-style: normal;
  height: 30px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 1px;
}

/* General CSS Setup */
body {
  background-color: lightblue;
  font-family: "Ubuntu-Italic", "Lucida Sans", helvetica, sans;
}

/* container */
.container {
  padding: 5% 5%;
}

/* CSS talk bubble */
.talk-bubble {
  margin-top: 20px;
  margin-left: 20px;
  display: inline-block;
  position: relative;
  width: 90%;
  height: auto;
  background-color: deepskyblue;
  border-radius: 15px;
}

.border {
  border: 8px solid #666;
}
.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 12px solid;
  border-color: deepskyblue transparent transparent transparent;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: deepskyblue deepskyblue transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #666;
}
.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent deepskyblue;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: deepskyblue transparent transparent deepskyblue;
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: deepskyblue deepskyblue transparent transparent;
}
/*
      left: -8px;
    right: auto;
    top: auto;
      bottom: -40px;
      border: 32px solid;
      border-color: transparent transparent transparent #666;
      left: 0px;
    right: auto;
    top: auto;
      bottom: -20px;
      border: 22px solid;
      border-color: transparent transparent transparent deepskyblue;
  
  /*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: deepskyblue deepskyblue transparent transparent;
}

/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: deepskyblue transparent transparent deepskyblue;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: deepskyblue transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}
</style>
