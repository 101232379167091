import { ref } from "vue";
import { defineStore } from "pinia";
export const userThemeStore = defineStore(
 "themeStore",
 () => {
 const darkTheme = ref(false);

 function setDarkTheme(val) {
 darkTheme.value = val;
 }
 return { darkTheme, setDarkTheme };
 },
{
 persist: true,
 }
);

