import { defineStore, acceptHMRUpdate } from "pinia";
import { getPlatforms } from "@ionic/vue";
import { useWelcomeStore } from "../stores/welcome";
import { useMqttStore } from "../stores/mqtt";
import { useRouter } from "vue-router";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    platform: "web",

    user: null,
    userClaims: null,
    isAuthenticated: false,
    // showWelcome:true,
    // showLaunchPad:true,
    role: "patient",
    userId: "donnie",
    tabs: [],
    tabsPatient: [
      {
        tab: "home",
        href: "/home",
        label: "Home",
        icon: "home",
      },
      {
        tab: "connect",
        href: "/connect",
        label: "Chat",
        icon: "chatbubbles",
      },
      {
        tab: "checkin",
        href: "/checkin",
        label: "Check In",
        icon: "checkbox",
      },
      // {
      //     tab: 'myrpm',
      //     href:'/myrpm',
      //     label: 'My RPM',
      //     icon: 'school'
      // },
      {
        tab: "scheduling",
        href: "/Schedule",
        label: "Scheduling",
        icon: "calendar",
      },
      // {
      //     tab: 'myrpm',
      //     href:'/myrpm',
      //     label: 'My RPM',
      //     icon: 'school'
      // },
      {
        tab: "notify",
        href: "/notifypatient",
        label: "Notifications",
        icon: "notifications",
      },
      {
        tab: "pairdevice",
        href: "/pair",
        label: "Pair",
        icon: "bluetooth",
      },
    ],
    tabsPatientWeb: [
      {
        tab: "home",
        href: "/home",
        label: "Home",
        icon: "home",
      },
      {
        tab: "connect",
        href: "/connect",
        label: "Chat",
        icon: "chatbubbles",
      },
      {
        tab: "checkin",
        href: "/checkin",
        label: "Check In",
        icon: "checkbox",
      },
      // {
      //     tab: 'myrpm',
      //     href:'/myrpm',
      //     label: 'My RPM',
      //     icon: 'school'
      // },
      {
        tab: "scheduling",
        href: "/Schedule",
        label: "Scheduling",
        icon: "calendar",
      },
      {
        tab: "notify",
        href: "/notifypatient",
        label: "Notifications",
        icon: "notifications",
      },
    ],
    tabsStaff: [
      {
        tab: "home",
        href: "/home",
        label: "Home",
        icon: "home",
      },
      {
        tab: "connect",
        href: "/connect",
        label: "Chat",
        icon: "chatbubbles",
      },
      {
        tab: "scheduling",
        href: "/Schedule",
        label: "Scheduling",
        icon: "calendar",
      },
      // {
      //     tab: 'admin',
      //     href:'/admin',
      //     label: 'Admin',
      //     icon: 'clipboard'
      // },
      {
        tab: "notify",
        href: "/notifystaff",
        label: "Notifications",
        icon: "notifications",
      },
    ],
  }),
  actions: {
    setPlatform() {
      this.platforms = getPlatforms(); // returns ["iphone", "ios", "mobile", "mobileweb"] from an iPhone

      if (
        this.platforms.includes("desktop") ||
        this.platforms.includes("mobileweb")
      ) {
        this.platform = "web";
      } else if (this.platforms.includes("ios")) {
        this.platform = "ios";
      } else if (this.platforms.includes("android")) {
        this.platform = "android";
      }
      console.log("platform", this.platform);
    },
    setUser(auth0User, isAuthenticated, claims) {
      /* com.rpmone.app/roles: ['patient']
        com.rpmone.app/userId: "62f7d23526ef957bf8d40f8a"
        email: "donniekerr01@gmail.com"
        email_verified: true
        name: "Donnie Kerr"
        nickname: "donniekerr01"
        picture: "https://s.gravatar.com/avatar/62671afcd96905c3969eeebfe045c50f?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fdo.png"
        sub: "auth0|62f7d23526ef957bf8d40f8a"
        updated_at: "2022-11-24T06:41:06.084Z" */
      const router = useRouter();
      this.user = auth0User;
      this.isAuthenticated = isAuthenticated;
      this.userClaims = claims;
      //console.log('set user',auth0User)
      // console.log('user claim',claims)
      this.role = this.user["com.rpmone.app/roles"][0];
      //reminded that I stripped out userId in Auth0 side and put it here this.user['com.rpmone.app/userId']
      this.userId = this.user.sub.substring(6);
      // console.log('userId',this.userId)
      console.log("role", this.role);

      const welcomeStore = useWelcomeStore();

      const mqttStore = useMqttStore();
      mqttStore.connectMqtt();

      this.tabs = [];
      if (this.role == "patient") {
        if (this.platform == "web") {
          this.tabs = this.tabsPatientWeb;
        } else {
          //includes bluetooth and other native only features
          this.tabs = this.tabsPatient;
        }

        if (welcomeStore.showWelcome) {
          router.push("/welcome");
        }

        // console.log('tabs',this.tabs)
      } else {
        this.tabs = this.tabsStaff;
        // console.log('tabs staff',this.tabs)
        //this.showWelcome = false;

        if (welcomeStore.showWelcome) {
          router.push("/welcomeStaff");
        }
      }
    },
    // setShowWelcome(val)
    // {
    //     this.showWelcome = val;
    // },
    // setShowLaunchPad(val)
    // {
    //     this.showLaunchPad = val;
    // },
    logout() {},
  },
  persist: false,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
