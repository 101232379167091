import { createRouter, createWebHistory } from "@ionic/vue-router";
import BluetoothView from "../views/BluetoothView.vue";
import { authGuard } from "@auth0/auth0-vue";
import HomeView from "../views/HomeView.vue";
// import PatientTab2 from "../views/patient/PatientTab2";
//import PatientTab3 from "../views/patient/PatientTab3";
//import PatientTab4 from "../views/patient/PatientTab4";
//import PatientTab5 from "../views/patient/PatientTabNotification";
//  import StaffTab2 from "../views/staff/StaffTab2";
//import StaffTab3 from "../views/staff/StaffTab3";
//import StaffTab4 from "../views/staff/StaffTab4";
import DigitalTriage from "../views/staff/DigitalTriage";
import scheduleUsersView from "../views/staff/ScheduleUsersView";
//import StaffTab5 from "../views/staff/StaffTab5";
import { getPlatforms  } from "@ionic/vue";
import WelcomeRpmOne from "../views/patient/Welcome.vue";
import skinTonePicker from "../views/patient/skinTonePicker.vue";
//import rpmOneMain from "../views/patient/rpmOneMain.vue";
import PatientTabNotification from "../views/patient/PatientTabNotification.vue";
import Rpmboxdescription from "../views/patient/rpmBoxDescriptionView.vue";
//import rpmcheckinFatigue from "../views/patient/rpmcheckinFatigue.vue";
//import rpmcheckinShortness from "../views/patient/rpmcheckinShortness.vue";
import WelcomeStaff from "../views/staff/WelcomePage.vue";
import staffGetStarted from "../views/staff/GetStarted.vue";
//import rpmPatientData from "../views/patient/rpmPatientData.vue";
//import staffReviewAlert from "../views/staff/rpmReviewAlert.vue";
import ChatView from "../views/ChatView.vue";
import chatUsersView from "../views/ChatUsersView.vue";
import CheckinBot from '../views/patient/CheckinBot'
import lexbot from "../views/staff/rpmLexBotView.vue";
import patientDetails from "../views/staff/PatientDetailsView.vue";
import schedulingUsersView from '../views/SchedulingUsersView.vue'
import mySchedule from '../views/MySchedule.vue'

//web can use authGuard to protect each route because it uses loginWithRedirect. Native cannot so we check the platform
const routesWeb = [
   {
    path: "/",
    component: HomeView,
    beforeEnter: authGuard,
  }, 
  {
    path: "/home",
    component: HomeView,
    beforeEnter: authGuard,
  },   
  {
    path: "/welcome",
    component: WelcomeRpmOne,
    beforeEnter: authGuard,
  },   
  {
    path: "/skin",
    component: skinTonePicker,
    beforeEnter: authGuard,
  },
  {
    path: "/Schedule",
    component: mySchedule,
    beforeEnter: authGuard,
  },
  {
    path: "/StaffSchedule",
    component: scheduleUsersView,
    beforeEnter: authGuard,
  },
  {
    path: "/boxDescription",
    component: Rpmboxdescription,
    beforeEnter: authGuard,
  },
  {
    
    path: "/connect",
    component: chatUsersView,
    beforeEnter: authGuard,
  },
  {
    path: '/connect/:PATIENTUSERID/chat',
    component: ChatView,
    props: true,
    // path: "/connect",
    // component: chatUsersView,
    beforeEnter: authGuard,
  },
  {
    path: '/schedule/:PATIENTUSERID/Patient',
    component: schedulingUsersView,
    props: true,
    // path: "/connect",
    // component: chatUsersView,
    beforeEnter: authGuard,
  },
  {
    path: '/patientDetail/:PATIENTUSERID/Patient',
    component: patientDetails,
    props: true,
     beforeEnter: authGuard,
  },
  {
    path: "/checkin",
    component: CheckinBot,
    beforeEnter: authGuard,
  },
  {
    path: "/lexbot",
    component: lexbot,
    beforeEnter: authGuard,
  },
  // {
  //   path: "/scheduling",
  //   component: schedulingUsersView,
  //   beforeEnter: authGuard,
  // },
  {
    path: "/welcomeStaff",
    component: WelcomeStaff,
    beforeEnter: authGuard,
  },
  {
    path: "/getStarted",
    component: staffGetStarted,
    beforeEnter: authGuard,
  },
  {
    path: "/admin",
    component: DigitalTriage,
    beforeEnter: authGuard,
  },
  {
    path: "/notifypatient",
    component: PatientTabNotification,
    beforeEnter: authGuard,
  },
  {
    path: "/notifystaff",
    component: PatientTabNotification,
    beforeEnter: authGuard,
  },
  {
    path: "/pair",
    component: BluetoothView,
    beforeEnter: authGuard,
  },
];
const routesNative = [
   {
    path: "/",
    component: HomeView,
  }, 
  {
    path: "/welcomeStaff",
    component: WelcomeStaff
  },
  {
    path: "/StaffSchedule",
    component: scheduleUsersView
  },
  {
    path: "/home",
    component: HomeView,
  },   
  {
    path: "/welcome",
    component: WelcomeRpmOne
  },     
   {
    path: "/skin",
    component: skinTonePicker,
   },
   {
    path: "/getStarted",
    component: staffGetStarted,
  },
    {
      path: "/Schedule",
      component: mySchedule
    },
   {
    path: "/boxDescription",
    component: Rpmboxdescription,
  },
  {
    
    path: "/connect",
    component: chatUsersView,
  },
  {
    path: '/connect/:PATIENTUSERID/chat',
    component: ChatView,
    props: true
  },
  {
    path: '/schedule/:PATIENTUSERID/Patient',
    component: schedulingUsersView,
    props: true,
  },
  {
    path: '/patientDetail/:PATIENTUSERID/Patient',
    component: patientDetails,
    props: true,
     beforeEnter: authGuard,
  },
  {
    path: "/checkin",
    component: CheckinBot,
  },
  // {
  //   path: "/scheduling",
  //   component: schedulingUsersView,
  // },
  {
    path: "/admin",
    component: DigitalTriage,
  },

  { 
    path: "/notifypatient",
    component: PatientTabNotification,
  },
  {
    path: "/notifystaff",
    component: PatientTabNotification,
  },
  {
    path: "/pair",
    component: BluetoothView,
  },
];
let routes;
const platforms = getPlatforms();
//console.log(platforms);
if (platforms.includes("desktop") || platforms.includes("mobileweb")) {
  routes = routesWeb;
} else {
  routes = routesNative;
  console.log('routes native');
 // console.log('routes ' + JSON.stringify(routes));
// console.log('routes native ' + JSON.stringify(routesNative));
}
console.log(routes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
