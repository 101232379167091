<template>
  <ion-page>
    <ion-toolbar>
      <ion-title> Schedule with patients : </ion-title>
    </ion-toolbar>
    <ion-content ref="content" :scroll-y="true" :scrollEvents="true">
      <ion-accordion-group :multiple="true" :value="['first', 'second']">
        <ion-accordion
          value="first"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" style="font-size: xx-small">
            <ion-label class="accordionLabel">My Patients</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-list>
              <ion-item
                detail="false"
                lines="none"
                v-for="user in this.mypatientDistinctList"
                :key="user.USER_ID"
                class="ion-item ion-no-padding chatuser"
              >
                <ion-avatar slot="start" @click="goToPatientDetail(user)">
                  <img :src="user.PICTURE" />
                </ion-avatar>
                <ion-item
                  style="width: 100%"
                  class="ion-no-padding chatuser"
                  lines="none"
                  @click="sendtoScheduleView(user)"
                >
                  <div>
                    <h4 style="margin: 0px; font-weight: 600; font-size: 18px">
                      {{ user.NICKNAME }}
                    </h4>
                  </div>
                </ion-item>

                <ion-icon
                  :icon="chevronForward"
                  class="icon"
                  style="margin-right: 0px"
                  slot="end"
                >
                </ion-icon>
              </ion-item>
            </ion-list>
          </div>
        </ion-accordion>
        <!-- <ion-accordion
          value="second"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" style="font-size: xx-small">
            <ion-label class="accordionLabel">Recent</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">Recent Connections</div>
        </ion-accordion> -->
      </ion-accordion-group>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useIonRouter } from "@ionic/vue";
import { caretUpOutline, chevronForward } from "ionicons/icons";
import {
  IonAccordionGroup,
  IonAccordion,
  IonPage,
  IonAvatar,
  IonList,
  IonTitle,
  IonIcon,
  IonToolbar,

  IonContent,
  IonItem,

  IonLabel,

} from "@ionic/vue";
import { useScheduleStore } from "../stores/schedule";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
import { useKsqlStore } from "../stores/KSQL";
export default defineComponent({
  name: "scheduleUsers",
  components: {
    IonAccordionGroup,
    IonAccordion,
    IonPage,
    IonList,
    IonTitle,
    IonToolbar,
    IonContent,
    IonItem,
    IonLabel,
    IonAvatar,
    IonIcon
    
  },
  setup() {
    const scheduleStore = useScheduleStore();
    const mqttStore = useMqttStore();
    const userStore = useUserStore();
    const ionRouter = useIonRouter();
    const ksqlStore = useKsqlStore();
    const mypatientDistinctList = ref([]);
    const mypatients = ref(mqttStore.messages.events.ui.staff.mypatients);

    console.log(
      "messages.events.ui.staff.mypatients" +
        JSON.stringify(mqttStore.messages.events.ui.staff.mypatients)
    );

    // watchEffect(() => {
    //   mypatients.value = mqttStore.messages.events.ui.staff.mypatients;
    // });


    onMounted(() => {
      // this.unsubscribe(`chat/${this.subscribeToUserId}`);
      //var allusers = mypatients.value;
      if (mypatients.value.length > 0) {
        mypatientDistinctList.value = [
          ...new Map(
            mypatients.value.map((item) => [item["USER_ID"], item])
          ).values(),
        ];
        console.log(
          "this.mypatientDistinctList.value",
          JSON.stringify(mypatientDistinctList.value)
        );
        // mypatients.value.forEach(user => {
        // console.log('calling unsubscrible for user : ' + JSON.stringify(user));
        //mqttStore.unsubscribeMqtt(`chat/#`);
      
      }
    });

    return {
      scheduleStore,
      mqttStore,
      userStore,
      ionRouter,
      mypatients,
      caretUpOutline,
      chevronForward,
      mypatientDistinctList,
      ksqlStore
    };
  },
  data() {
    return {
      typedInput: "",
    };
  },
  watch: {
    mypatients: {
      handler: function (val, oldVal) {
        // console.log('Watch mypatients val' + JSON.stringify(val));
        console.log("Watch mypatients oldval" + JSON.stringify(oldVal));
        if (this.mypatientDistinctList.length == 0)
          this.mypatientDistinctList.push(val);
        else if (this.mypatientDistinctList.length > 0) {
          // check if
          let record = this.mypatientDistinctList?.value?.filter(
            (x) => x.USERID == val.USERID
          );
          if (record == null || record == undefined) {
            this.mypatientDistinctList.push(val);
          }
          console.log("val" + JSON.stringify(val));
        }
        // console.log(oldVal);
        this.mqttStore.unsubscribeMqtt(`chat/#`);
      },

      deep: true,
    },
  },
  methods: {
    goToPatientDetail(data) {
      console.log("data" + JSON.stringify(data));

      this.ionRouter.push("/patientDetail/" + data.USER_ID + "/Patient");
    },

    openModal() {
      alert("OpenModal");
    },

    sendtoScheduleView(user) {
      // console.log("sendtoChatView" + JSON.stringify(user));
      let PATIENTUSERID = user.USER_ID;
      this.ionRouter.push("/schedule/" + PATIENTUSERID + "/patient");
      this.scheduleStore.setSelectedSchedule(user);
      this.ksqlStore.pullScheduleStaff(this.userStore.userId);
    },

    scrollToBottom() {
      // extra ion-item at end is required because this doesn't scroll up enough the see all of last one
      this.$refs.content.$el.scrollToBottom(500);
      console.log(
        "mqttStore.messages.users" +
          JSON.stringify(this.mqttStore.messages.users)
      );
    },    
  },
});
</script>
<style scoped>
ion-icon {
  font-size: 12px;
}

ion-item {
  cursor: pointer;

  --background-hover: #ef9116;
}
ion-item:hover {
  cursor: hand;
  --background: #ffb555;
  --background-hover: #ef9116;
}

ion-icon:hover {
  cursor: hand;
  /* background:red; */
}

ion-icon {
  cursor: hand;
  /* background:lightgrey; */
}

.accordionLabel {
  font-size: 12px;
  /* left: 10px; */
  /* float: left; */
  font-family: "OpenSansRegular";
  font-weight: 700;
}
.ion-item {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 2px dotted var(--ion-color-medium);
  /* --background-color:#ef9116; */
  --padding-start: 5px;
  --padding-end: 5px;
}

ion-button {
  /* --background: #ef9116; */
  --color: white;
  font-family: "OpenSansRegular";
  font-style: normal;
  height: 30px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 1px;
}

/* General CSS Setup */
body {
  background-color: lightblue;
  font-family: "Ubuntu-Italic", "Lucida Sans", helvetica, sans;
}

/* container */
.container {
  padding: 5% 5%;
}
</style>
