import { defineStore, acceptHMRUpdate } from 'pinia'

 import { useMqttStore } from '../stores/mqtt'
  //import { useUserStore } from '../stores/user'

export const useChatStore = defineStore({
  id: 'chat',
  state: () => ({
    selectedChat:null,
    chatMessages:[]
    
  }),
  actions: {
    setSelectedChat(selectedItem) {
        const mqttStore = useMqttStore()
       console.log('selected chat',selectedItem)
       this.selectedChat = selectedItem
       this.chatMessages = mqttStore.messages.chat
    },
    
  },
  persist: false, 
},
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChatStore, import.meta.hot))
}
