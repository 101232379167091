<template>
  <ion-content>
    <ion-page no-padding>
      <!-- <ion-header> -->
      <ion-toolbar>
        <ion-title class="ion-text-wrap">Patient</ion-title>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
      <!-- </ion-header> -->

      <ion-grid
        style="
          width: 100%;

          padding-top: 20px;
          height: 100%;
        "
      >
        <ion-row style="padding-top: 30px; padding-bottom: 0px">
          <ion-col size="12">
            <ion-Label class="heading"> Name </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-Label class="labelValue">
              {{ userData.NICKNAME }}
            </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row style="padding-top: 30px; padding-bottom: 0px">
          <ion-col size="12">
            <ion-Label class="heading"> Email </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-Label class="labelValue">
              {{ userData.EMAIL }}
            </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row style="padding-top: 30px; padding-bottom: 0px">
          <ion-col size="4">
            <ion-Label class="heading"> Date of Birth </ion-Label>
          </ion-col>
          <ion-col size="4">
            <ion-Label class="heading"> Gender </ion-Label>
          </ion-col>
          <ion-col size="4">
            <ion-Label class="heading"> Gold Score </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4">
            <ion-Label class="labelValue"> July 08, 1954 </ion-Label>
          </ion-col>
          <ion-col size="4">
            <ion-Label class="labelValue"> Male </ion-Label>
          </ion-col>
          <ion-col size="4">
            <ion-Label class="labelValue"> 4 </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row style="padding-top: 30px; padding-bottom: 0px">
          <ion-col size="12">
            <ion-Label class="heading"> Indication </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-Label class="labelValue">
              {{ userData.UTTERANCE }}
            </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row style="padding-top: 30px; padding-bottom: 0px">
          <ion-col size="6">
            <ion-Label class="heading"> Risk </ion-Label>
          </ion-col>
          <ion-col size="6">
            <ion-Label class="heading"> Symptoms </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-Label class="labelValue">
              {{ userData.RISKLEVEL }}
            </ion-Label>
          </ion-col>
          <ion-col size="6">
            <ion-Label class="labelValue"> Sweating, heart racing, </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row style="padding-top: 30px; padding-bottom: 0px">
          <ion-col size="6">
            <ion-Label class="heading"> History </ion-Label>
          </ion-col>
          <ion-col size="6">
            <ion-Label class="heading"> Allergies </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-Label class="labelValue"> Chronic Bronchitis </ion-Label>
          </ion-col>
          <ion-col size="6">
            <ion-Label class="labelValue"> Yes </ion-Label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-page>
  </ion-content>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
import {
  IonPage,
  //IonItem,
  IonLabel,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  // IonButton,
  //IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
} from "@ionic/vue";

export default defineComponent({
  name: "patientDetail",
  components: {
    IonPage,
    IonLabel,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    //IonItem,
    //  IonButton,
    //IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    //IonCard, IonCardContent
  },
  props: {
    PATIENTUSERID: String,
  },
  setup(props) {
    //console.log("P D " + props.PATIENTUSERID);
    const mqttStore = useMqttStore();
    const userData = ref([]);
    const userStore = useUserStore();
    const mypatients = reactive(mqttStore.messages.events.ui.staff.mypatients);
    onMounted(() => {
      let loggedInUserRole = userStore.user["com.rpmone.app/roles"][0];
     // console.log('loggedInUserRole' + loggedInUserRole);
      if (loggedInUserRole == "patient")
      {
        userData.value = userStore.user;
      }
      else if ((loggedInUserRole == "staff") && (props.PATIENTUSERID !== undefined || props.userId !== "")) {
        var newArray = mypatients.filter(function (el) {
          return el.USER_ID == props.PATIENTUSERID;
        });
        if (newArray.length !== 0) {
          userData.value = newArray[0];
        } 
      }
    });

    return { mypatients, mqttStore, userData, userStore };
  },

  mounted() {},
});
</script>
<style scoped>
.heading {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* color: #666666; */
}
ion-button {
  /* --background: black; */
}

.labelValue {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* color: #000000; */
}

.labelHeading {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  letter-spacing: 0.5px;

  /* color: #000000; */
}
</style>
