<template>
  <ion-page>
    <ion-content>
    
     <ion-grid>
        
  <ion-row>
    <ion-col size="2">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Digital Triage</ion-card-subtitle>
          <ion-card-title>Alerts</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item button @click="selectAlert(message,index,$event)"  v-for="message in mqttStore.messages.events.digitalTriage.alert" :key="message.timestamp">
            <ion-label class="ion-text-wrap">
            <h3>{{ message.UTTERANCE }}</h3>
            <p>Risk Score: {{message.RISKSCORE}}</p>
          </ion-label>
              
              </ion-item> 
            </ion-list>
        </ion-card-content>
      </ion-card>
       
    </ion-col>
    <ion-col size="2">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Digital Triage</ion-card-subtitle>
          <ion-card-title>My Patients</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item id="MyPatients" button @click="selectMessage(message,index,$event)"  v-for="message in mqttStore.messages.events.ui.staff.mypatients" :key="message.timestamp">
              <ion-label class="ion-text-wrap">
                  <h3>{{ message.UTTERANCE }}</h3>
                 <p>Risk Score: {{message.RISKSCORE}}</p>
                 <p>Assigned: {{userStore.user.nickname}}</p>
                 <ion-button @click="chat">
                  <ion-icon name="chatbubbles"></ion-icon>
                </ion-button>
                </ion-label>
                
              </ion-item> 
            </ion-list>
        </ion-card-content>
      </ion-card>
       
    </ion-col>
    <ion-col size="4">
        <ion-grid>
          <ion-row>
            <ion-col>
              <LexBot  :message="selectedMessage"/>
          </ion-col>
        </ion-row>
        <!-- <ion-row>
          <ion-col>
            <chat-list/>
          </ion-col>
        </ion-row> -->
       <ion-row>
          <ion-col>
              <ion-card>
              <ion-card-header>
                <ion-card-subtitle>Digital Triage</ion-card-subtitle>
                <ion-card-title>Actions</ion-card-title>
              </ion-card-header>

              <ion-card-content>
                <ion-list v-if="mqttStore.messages.commands.digitalTriage.action.length > 0">
                    <ion-item button v-for="message in mqttStore.messages.commands.digitalTriage.action" :key="message.timestamp">
                    <ion-label class="ion-text-wrap">
                      <h3>{{ message.action }}</h3>
                        <p>Patient: {{message.userId}}</p>
                      </ion-label>
                      
                    </ion-item> 
                </ion-list>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row> 
         </ion-grid>
    </ion-col>
 <ion-col size="4">
                    <ion-card v-if="mqttStore.messages.patientData.t.length > 0">
                        <ion-card-header>
                            <ion-card-title>Patient Metrics</ion-card-title>

                        </ion-card-header>
                        <ion-card-content>
                           <realtimechart :chartdata = "mqttStore.messages.patientData" :chartname = "chartname"></realtimechart>
                        </ion-card-content>
                    </ion-card>
   </ion-col>
    </ion-row>
    
    </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { useUserStore } from '../../stores/user'
import { useMqttStore } from '../../stores/mqtt'
import LexBot from '@/components/LexBot.vue';
import {useRouter} from 'vue-router'
//import chatList from "@/components/chatList.vue";
import { defineComponent } from 'vue';
 import realtimechart from "../../components/rpmMainLiveRealTimeChart.vue";
import {IonIcon,IonButton,IonContent,IonPage,IonList,IonItem,IonLabel,IonGrid,IonRow,IonCol,IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/vue';


export default defineComponent({
  name: 'DigitalTriage',
  components: {
    IonIcon,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonPage,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    LexBot,
    realtimechart,
    IonButton
    //chatList
  },
   setup() {
    const router = useRouter()
    const userStore = useUserStore()
    const mqttStore = useMqttStore()
    
    return {
      userStore,mqttStore,router
    }
  }, 
  data() {
    return {
      chartname:'Live',
      selectedMessage:null,
      lexSessionId:null,
      }
  },
  methods: {
    chat() {
      this.router.push('/connect')
    },
    selectAlert(m,index, e) {
      m['assignedStaff'] = this.userStore.userId
      m['assignedDate'] = Date.now()
      let message = JSON.stringify(m)
      let topic = `digitaltriage_mypatients/${this.userStore.userId}`
      this.mqttStore.publishMqtt(topic,message)

      let topicMy = `staffpatients/${this.userStore.userId}`
      this.mqttStore.publishMqtt(topicMy,message);
      //update alert status by add alert to messages log. query will filter out already assigned alerts
      //let topicAlert = `digitaltriage_alerts/${m.userId}`
     // this.mqttStore.publishMqtt(topicAlert,message)
      //console.log(topic,m)
      this.mqttStore.messages.events.digitalTriage.alert.splice(index,1)
      this.selectMessage(m,index, e)
      
    },
    selectMessage(m,index, e) {
      //console.log('selected alert',m)
      const mypatientsIonItem = document.querySelectorAll("#MyPatients");
      //console.log('mypatientsIonItem' + JSON.stringify(mypatientsIonItem));

      if (mypatientsIonItem != null) {
        for (var i = 0, len = mypatientsIonItem.length; i < len; i++) {
          //work with button[i]
          mypatientsIonItem[i].classList.remove("mystyle");  
        }
      }

      console.log('selected alert11',e.target)
      if (e.target.parentElement.parentElement.id == "MyPatients")
      {
        e.target.parentElement.parentElement.classList.add("mystyle");
      }
      m.lexSessionId = `${m.USER_ID}${index}`
      this.selectedMessage = null
      this.selectedMessage = m; 
      this.mqttStore.subscribeToPatientData(m.USER_ID)
      this.mqttStore.subscribeToPatientChat(m.USER_ID,7)
    }

  }
});
</script>
<style scoped>
.mystyle
{
  --background: lightyellow;
}
</style>
