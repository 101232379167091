<template>
  <!-- <ion-content> -->
  <ion-card>
    <ion-card-content
      style="
        padding-right: 0px;
        border-left-width: 10px;
        padding-left: 0px;
        padding: 0px;
      "
      class="content"
    >
      <!-- {{ result.data }}
      {{ result?.index }} -->

      <ion-grid>
        <ion-row v-if="result.data.type == undefined">
          <ion-col size="11">
            <ion-item lines="none" @click="redirectTo">
              {{ result.data[result.data.length - 1]?.text }}
            </ion-item>
            <ion-item lines="none" style="float: right">
              <ion-button
                class="notificationButton"
                @click="redirectTo"
                :id="result.data[result.data.length - 1]?.buttonText"
                v-show="result.data[result.data.length - 1]?.buttonText"
              >
                <!-- {{ result.data[result.data.length-1]?.buttonText }} -->
                <ion-icon
                  @click="redirectTo"
                  :id="result.data[result.data.length - 1]?.buttonText"
                  :icon="result.data[result.data.length - 1]?.icon"
                  style="height: 100%; width: 100%"
                >
                </ion-icon>
                <ion-badge
                  @click="redirectTo"
                  id="notifications-badge"
                  color="danger"
                  slot="end"
                  style="top: 0px; right: -6px"
                  >{{ result?.data?.length }}</ion-badge
                >
              </ion-button>
            </ion-item>
          </ion-col>
          <ion-col size="1">
            <ion-icon
              :icon="closeCircle"
              class="closeIcon"
              v-on:click="closeChatNotification"
              style="zoom: 1.3"
            >
            </ion-icon>
          </ion-col>
        </ion-row>
        <ion-row v-if="result.data.type == 'ScheduleNotification'">
          <ion-col size="11" @click="redirectTo">
            <ion-item lines="none" class="notificationtxt">
              {{ result.data?.text }}
            </ion-item>
            <ion-item lines="none" style="float: right">
              <ion-button
                class="notification"
                @click="redirectTo"
                :id="result.data?.buttonText"
                v-show="result.data?.buttonText"
              >
                {{ result.data?.buttonText }}
                <!-- <ion-icon
                  @click="redirectTo"
                  :id="result.data?.buttonText"
                  :icon="result.data?.icon"
                  style="height: 100%; width: 100%"
                >
                </ion-icon> -->
                <!-- <ion-badge
                  @click="redirectTo"
                  id="notifications-badge"
                  color="danger"
                  slot="end"
                  style="top: 0px; right: -6px"
                  >{{ result?.data?.length }}</ion-badge
                > -->
              </ion-button>
            </ion-item>
          </ion-col>
          <ion-col size="1">
            <ion-icon
              :icon="closeCircle"
              class="closeIcon"
              v-on:click="closeNotification"
              style="zoom: 1.3"
            >
            </ion-icon>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
  <!-- </ion-content> -->
</template>

<script>
import { defineComponent, defineEmits, ref } from "vue";
import { closeCircle } from "ionicons/icons";
import { useIonRouter } from "@ionic/vue";
import { useUserStore } from "../stores/user";
import { useMqttStore } from "../stores/mqtt";
import { useChatStore } from "../stores/chat";

import {
  IonCard,
  IonCardContent,
  IonItem,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonBadge,
  // IonContent
  //IonIcon
} from "@ionic/vue";

export default defineComponent({
  name: "patientNotification",
  emits: defineEmits(["close"]),
  components: {
    IonCard,
    IonCardContent,
    IonItem,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonBadge,
    //  IonContent
    //IonIcon
  },
  props: {
    data: Object,
    index: String,
  },
  methods: {
    async closeChatNotification() {

      if (this.result.data !== undefined || this.result.data?.length > 0) {
        let len = this.result.data?.length;
        for (var i = 0; i < len; i++) {
          let notificationMessage = {
            USERID: this.userStore.userId,
            NOTIFICATION: this.result.data[i]?.notification,
            TIMESTAMP: this.result.data[i]?.notification?.timestamp,
            STATUS: "closed",
            NOTIFICATIONTYPE: this.result.data[i]?.notification?.type,            
          };
         

          let options = {
            qos: 1,
          };

          let notificationTopic = `notifications/${notificationMessage.USERID}`;
          let that = this;
          await this.mqttStore.mqttClient.publish(
            notificationTopic,
            JSON.stringify(notificationMessage),
            options,
            (err) => {
              if (err) {
                console.log(err);
                console.log("this.mqttStore.mqttClient.publish" + err);
              } else {
                console.log(
                  "notification sent to patient " +
                    JSON.stringify(notificationMessage)
                );
                const indexOfObject =
                  that.mqttStore.messages.notifications.findIndex((object) => {

                    let notificationTypeString;
                    notificationTypeString = typeof object?.NOTIFICATION;
                    let n = notificationTypeString == "string" ? JSON.parse(object?.NOTIFICATION) : object?.NOTIFICATION;
                    return (
                      n?.timestamp ===
                      notificationMessage?.NOTIFICATION?.timestamp
                    );
                  });

                //console.log(indexOfObject);
                if (indexOfObject !== -1)
                  this.mqttStore.messages.notifications.splice(
                    indexOfObject,
                    1
                  );
              }
            }
          );
        }
      }
    },
    async closeNotification() {
      let notificationMessage = {
        USERID: this.userStore.userId,
        NOTIFICATION: this.$props?.data?.notification,
        TIMESTAMP: this.$props?.data?.notification?.timestamp,
        STATUS: "closed",
        NOTIFICATIONTYPE: this.$props?.data?.notification?.type,

        // USERID: this.userStore.userId,
        //     NOTIFICATION: this.result.data[i]?.notification,
        //     TIMESTAMP: Date.now(),
        //     STATUS: "closed",
        //     NOTIFICATIONTYPE: this.result.data[i]?.notification?.type,   

            
      };

      //console.log("abc" + JSON.stringify(this.result.data));

      let options = {
        qos: 1,
      };

      let notificationTopic = `notifications/${notificationMessage.USERID}`;
      let that = this;
      await this.mqttStore.mqttClient.publish(
        notificationTopic,
        JSON.stringify(notificationMessage),
        options,
        (err) => {
          if (err) {
            console.log(err);
            console.log("this.mqttStore.mqttClient.publish" + err);
          } else {
            console.log(
              "notification sent to patient " +
                JSON.stringify(notificationMessage)
            );
            const indexOfObject =
              that.mqttStore.messages.notifications.findIndex((object) => {

                let notificationTypeString;
                    notificationTypeString = typeof object?.NOTIFICATION;
                let n = notificationTypeString == "string" ? JSON.parse(object?.NOTIFICATION) : object?.NOTIFICATION;
                return (
                  n?.timestamp === notificationMessage?.NOTIFICATION?.timestamp
                );
              });

            //console.log(indexOfObject);
            if (indexOfObject !== -1)
              this.mqttStore.messages.notifications.splice(indexOfObject, 1);
          }
        }
      );
    },

    redirectTo(e) {
      let that = this;
      if (this.loggedInUserRole == "patient") {
        var staffuser =
          e.target.id == "Chat"
            ? this.mqttStore.messages.events.ui.patient.patientstaff.filter(
                function (el) {
                  return (
                    el.STAFFUSERID ==
                    that.$props?.data[that.$props.data.length - 1]?.staffUserId
                  );
                }
              )
            : this.mqttStore.messages.events.ui.patient.patientstaff.filter(
                function (el) {
                  return el.STAFFUSERID == that.$props?.data?.staffUserId;
                }
              );
        //console.log(staffuser[0]);
        if (staffuser.length > 0) {
          if (e.target.id == "Chat") {
            this.closeChatNotification().then(() => {
              //this.ionRouter.push("/Schedule");
              this.chatStore.setSelectedChat(staffuser[0]);
              this.ionRouter.push("/connect");
              this.ionRouter.push(
                "/connect/" +
                  this.$props?.data[that.$props.data.length - 1]?.staffUserId +
                  "/chat"
              );
              return;
            });
          } else if (e.target.id == "Check") {

            //console.log('notificationMessage' + JSON.stringify(notificationMessage));
            this.closeNotification().then(() => {
              this.ionRouter.push("/Schedule");
              return;
            });
            //this.ionRouter.push("/Schedule");
          }
        }
      } else if (this.loggedInUserRole == "staff") {
        var patientuser =
          this.mqttStore.messages.events.ui.staff.mypatients.filter(function (
            el
          ) {
            return (
              el.USER_ID ==
              that.$props?.data[that.$props.data.length - 1]?.patientUserId
            );
          });
        //console.log(staffuser[0]);
        if (patientuser.length > 0) {
          this.chatStore.setSelectedChat(patientuser[0]);
          this.ionRouter.push("/connect");
          this.ionRouter.push(
            "/connect/" +
              this.$props?.data[that.$props.data.length - 1]?.patientUserId +
              "/chat"
          );
        }
      }
    },
  },
  setup(props) {
    // console.log('abc' + JSON.stringify(props));
    // console.log('abc chat count' + JSON.stringify(props?.data.length));
    const result = ref(props);

    // console.log(
    //   props?.data?.filter((x) => x.type == "chatNotification")?.length
    // );

    const chatStore = useChatStore();
    const ionRouter = useIonRouter();
    const userStore = useUserStore();
    const mqttStore = useMqttStore();
    const loggedInUserRole = ref(userStore?.user["com.rpmone.app/roles"][0]);
    const action = () => {
      var val = props.data.id;
      console.log(val);
      //emit("close", val);
    };

    return {
      close,
      action,
      closeCircle,
      ionRouter,
      userStore,
      loggedInUserRole,
      mqttStore,
      chatStore,
      result,
    };
  },
});
</script>
<style scoped>
.notificationtxt {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-size: 15px;
}

ion-button {
  font-family: "OpenSansRegular";
  font-style: normal;
  height: 30px;

  font-size: 13px;
  line-height: 130%;
  letter-spacing: 1px;
  font-weight: 700;
}

.close-btn {
  float: right;
  padding-top: 0px;
  top: -11px;
}

.imgClass {
  /* font-size: 30px; */
  max-width: 100%;
  /* border: 0; */
  /* width: 25px;
    height: 25px; */
}

.content {
  box-sizing: border-box;
  /* width: 373px;
height: 90px; */
  /* left: 8px;
top: 189px; */

  border: 2px solid #f7c788;
  border-radius: 4px;
}
</style>
