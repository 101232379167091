<template>
  <ion-page>
    <ion-content>
      <ion-card style="margin:0px;height: 100%;">
        <ion-grid class="span-class blur">
          <ion-row style="height: 20%;">
            <ion-col>
              <ion-label class="ThankYou">Thank You For Joining!</ion-label>
            </ion-col>
          </ion-row>
          <ion-row style="height: 20%;">
            <ion-col style="text-align: center;">
              <ion-icon style="width:131px;height:132px;color: #0DBA61;background: white;border-radius: 50%;"
                name='checkmark-circle-outline'></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row style="height: 15%;">
            <ion-col>
              <ion-label class="basedOn">Based on the information provided, you can make up to $35 per hour with RPM-One
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row style="height: 5%;">
            <ion-col>
              <ion-label class="offer">How was my offer calculated?</ion-label>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
                <ion-button router-link="/getStarted" shape="round" expand="block">ACCEPT</ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
             
              <ion-button shape="round" class="bgDecline" expand="block" @click="setShowWelcome">
                DECLINE</ion-button>
  
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-content>


  </ion-page>
</template>
<script>

import { defineComponent } from "vue";
import {
  IonCard,
  //IonCardContent,
  IonLabel,
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
//import { ref } from "vue";
import { addIcons } from "ionicons";
import { arrowBack, arrowForward, chevronForwardOutline, checkmarkCircle } from "ionicons/icons";
import { useUserStore } from "../../stores/user";
import { useIonRouter } from "@ionic/vue";

export default defineComponent({
  name: "WelcomeStaff",
  components: {
    IonCard,
    //IonCardContent,
    IonLabel,
    IonContent,
    IonPage,
    IonIcon,
    IonButton,
    IonGrid,
    IonRow,
    IonCol
  },
  created() {
    addIcons({
      "arrow-back": arrowBack,
      "chevron-forward-outline": chevronForwardOutline,
      "checkmark-circle-outline": checkmarkCircle,
      forward: arrowForward,
    });
  },
  setup() {
    const userStore = useUserStore();
    const router = useIonRouter();
    function rediectToGetStarted() {
      router.push("/getStarted");
    }
    function setShowWelcome() {
      userStore.setShowWelcome(false);
    }

    return { setShowWelcome, addIcons, rediectToGetStarted };
  },
});
</script>
<style scoped>
.blur {
  position: relative;
  display: inline-block;
  /* make the div not 100% */
}

/* the 'blur' effect */
.blur:before {
  content: "";
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.blur img {
  display: block;
  /* remove bottom space */
}

.offer {
  position: absolute;
  width: 229px;
  height: 25px;
  left: 83px;
  /* top: 533px; */
  font-family: 'Open Sans';
  font-style: normal;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 1px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #FEFEFE;
}

.basedOn {
  position: absolute;



  /* top: 414px; */
  font-family: 'roboto-slabRegular';
  font-style: normal;
  /* font-weight: 700; */
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FEFEFE;
}


.rpm-one-padding {
  padding-bottom: 20px;
}

.ThankYou {
  position: absolute;
  width: 366px;
  height: 106px;
  left: 14px;
  top: 30px;
  font-family: 'OpenSansRegular';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 150%;
  /* or 52px */

  text-align: center;
  letter-spacing: 1px;

  /* background */

  color: #FEFEFE;
}

.span-class {

  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
}

.rpm-image {

  width: 60px;
  height: 66.7px;
  left: 43px;
  top: 160px;
  /* background: transparent;
    backdrop-filter: blur(1px);
    background: url("../../public/assets/images/RPM-One_logo_Welcome.png"); */
}

.rpm-div-second {
  position: fixed;
  width: 395px;
  left: 0px;
  top: 400px;

  /* Main heading */
  background: black;
}

.rpm-one-questions-icon {
  color: white;
  font-weight: bold;
  padding-right: 8px;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  --ionicon-stroke-width: 64px;
  --ionicon-stroke-weight: 10px;
}

ion-icon {
  --ionicon-stroke-width: 66px;
}


ion-button {
  --background: #ef9116;
  --color: white;
  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  height: 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.bgDecline {
  --background: #606B7C;
}
</style>
