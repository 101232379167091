<template>
  <ion-content>
  <ion-toolbar style="text-align: center">
    <ion-Label class="Heading"> Patient Vitals </ion-Label>

    <ion-buttons slot="end">
      <ion-button color="primary" @click="closeModal">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
    <ion-card style="margin: 0px">
      <ion-card-content>
        <ion-segment color="tertiary" value="primary">
          <ion-segment-button value="primary" @click="clickBasicTab">
            <ion-label>
              <ion-icon class="patientVitalsIcon" icon="gridout"></ion-icon>
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="segment" @click="clickLiveTab">
            <ion-label>
              <ion-icon class="patientVitalsIcon" icon="chart"></ion-icon>
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-card-content>
    </ion-card>
    <div v-if="isLive">
      <ion-segment color="tertiary" value="today">
        <ion-segment-button value="today" @click="chartSegmentClick">
          <ion-label> Today </ion-label>
        </ion-segment-button>
        <ion-segment-button value="week" @click="chartSegmentClick">
          <ion-label> Week </ion-label>
        </ion-segment-button>
        <ion-segment-button value="month" @click="chartSegmentClick">
          <ion-label> Month </ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
    <ion-grid>
      <ion-row v-if="isBasic">
        <ion-col>
          <basic />
        </ion-col>
      </ion-row>
      <ion-row v-if="isLive && chartType == 'today'" style="padding-top:15%">
        <ion-col>
          <live :chartType="chartType" />
        </ion-col>
      </ion-row>
      <ion-row v-if="isLive && chartType == 'week'" style="padding-top:15%">
        <ion-col>
          <live :chartType="chartType" />
        </ion-col>
      </ion-row>
      <ion-row v-if="isLive && chartType == 'month'" style="padding-top:15%">
        <ion-col>
          <live :chartType="chartType" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</template>
<script>
import { defineComponent, ref } from "vue";
import { addIcons } from "ionicons";
import { browsers, statsChart, search, grid } from "ionicons/icons";
import {
  //IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  //   IonToolbar,
  //   IonButtons,
  //IonItem,
  IonSegment,
  IonSegmentButton,
  // IonIcon,
  //IonButton,
  IonGrid,
  IonCol,
  IonRow,
  // IonCard,
  // IonCardContent,
  IonLabel,
  //IonPage,
  IonContent,
  //IonBackdrop
} from "@ionic/vue";

import basic from "../components/rpmMainBasic.vue";
import live from "../components/rpmMainLive.vue";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";

export default defineComponent({
  name: "PatientVitalsModal",
  components: {
    // IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,

    basic,
    live,
    // IonPage,
    IonContent,
  },
  created() {
    addIcons({
      chart: statsChart,
      browsers: browsers,
      search: search,
      gridout: grid,
    });
  },
  data() {
    return {
      isLive: false,
      isBasic: true,
    };
  },
  props: {
    selectedPatient: Object,
  },
  setup() {
    const userStore = useUserStore();
    const mqttStore = useMqttStore();
    const chartType = ref("today");

    const liveDataToday = ref({
      t: mqttStore.messages.patientData.t,
      hr: mqttStore.messages.patientData.hr,
      rr: mqttStore.messages.patientData.rr,
      bp: mqttStore.messages.patientData.bp,
      spo2: mqttStore.messages.patientData.spo2,
    });

    console.log("Staff liveDataToday" + JSON.stringify(liveDataToday));

    return {
      basic,
      live,
      confirm,
      close,
      userStore,
      chartType,
      mqttStore,
    };
  },
  watch: {
    liveDataToday: {
      handler: function (e) {
        console.log(e);
      },
    },
  },
  methods: {
    closeModal() {
      const toggle = document.querySelector("#patientVitalModal");
      if (toggle !== null) toggle.dismiss();
    },
    chartSegmentClick(e) {
      console.log(e.target.value);
      this.chartType = e.target.value;
    },

    clickBasicTab() {
      this.isBasic = true;
      this.isLive = false;
    },
    clickLiveTab() {
      this.isLive = true;
      this.isBasic = false;
      console.log("this.isLive  " + this.isLive);
    },

    unsubscribe() {
      this.mqttStore.unsubscribeMqtt(
        `avgpatient/${this.selectedPatient.USER_ID}`
      );
      this.mqttStore.unsubscribeMqtt(
        `avgpatient_7days/${this.selectedPatient.USER_ID}`,
        this.mqttStore.options7
      );
      this.mqttStore.unsubscribeMqtt(
        `avgpatient_30days/${this.selectedPatient.USER_ID}`,
        this.mqttStore.options30
      );
    },
  },
  mounted() {
    this.mqttStore.clearPatientDataLocally();
    setTimeout(
      this.mqttStore.subscribeToPatientData(this.selectedPatient.USER_ID),
      6000
    );
    // console.log('this.props.selectedPatient' + JSON.stringify(this.selectedPatient));
    // const toggle = document.querySelector("ion-tab-bar");
    // toggle.setAttribute("selected-tab", "home");
  },
  unmounted() {
    this.unsubscribe();
    this.mqttStore.clearPatientDataLocally();
  },
  beforeUpdate() {
    this.isBasic = true;
    this.isLive = false;
  },
});
</script>
<style scoped>
.Heading {
  font-weight: 700;
  font-size: 18px;
  font-family: "OpenSansRegular";
}
</style>
