<template>
  <!-- <ion-header> -->
  <ion-toolbar>
    <ion-title style="width: auto">
      <ion-label class="titleText">
        Chat >
        {{ chatStore.selectedChat.NICKNAME }}</ion-label
      >
    </ion-title>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/connect"></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end" style="padding-right:10px ;" v-if="this.loggedInUserRole == 'staff'">
    <ion-icon icon="chart" slot="end" @click="openModal(chatStore.selectedChat)"></ion-icon>
  </ion-buttons>
  </ion-toolbar>
  <!-- </ion-header> -->
  <ion-content
    ref="content"
    :scroll-y="true"
    :scrollEvents="true"
    style="height: 86%"
  >
    <ion-list>
      <ion-item
        lines="none"
        v-for="message in this.chatmessage.value"
        :key="message.timestamp"
      >
        <ion-item-sliding>
          <!-- <ion-card  style="width:100%;background-color: transparent;">
                <ion-card-content style="background-color: transparent;padding-right: 0px;border-left-width: 10px;padding-left: 0px;" class="content">
                -->
          <!-- <ion-item-options side="start">
                <ion-item-option color="success">
                  <ion-icon slot="icon-only" name="archive"></ion-icon>
                </ion-item-option>
              </ion-item-options> -->

          <ion-item
            style="width: 100%; --padding-start: 0px"
            lines="none"
            class="speech-bubble"
          >
            <div
              :class="
                message.user['com.rpmone.app/roles'][0] === loggedInUserRole
                  ? 'talk-bubble-right tri-right right-in'
                  : 'talk-bubble-left tri-right left-in'
              "
            >
              <div class="talktext">
                <h5
                :style="message.user['com.rpmone.app/roles'][0] === loggedInUserRole ? 'margin: 0px; color: white; font-weight: 600' : 'margin: 0px; color: black; font-weight: 600'" 
                  @click="goToPatientDetail(message)"
                >
                  {{ message.user.nickname }}
                </h5>
                {{ message.chatMessage }}
              </div>
              <div style="float: right;margin-right: 9px;margin-bottom: 5px;">
                <ion-label style="font-size: 11px; font-weight: 700">
              {{ getTime(message.timestamp) }}
              </ion-label>
              </div>
            </div>
            <ion-avatar
              :slot="
                message.user['com.rpmone.app/roles'][0] === loggedInUserRole
                  ? 'end'
                  : 'start'
              "
              @click="goToPatientDetail(message)"
            >
              <img :src="message.user.picture" />
            </ion-avatar>
          </ion-item>

          <!-- </ion-card-content>
            </ion-card> -->
          <!-- <p class="bubble thought">{{ message.chatMessage }}</p> -->
          <ion-item-options
            :side="
              message.user['com.rpmone.app/roles'][0] === loggedInUserRole
                ? 'end'
                : 'start'
            "
            style="padding-top: 22%; background: white"
          >
            <ion-item-option slot="bottom" style="background: white">
              <ion-label style="font-size: 11px; font-weight: 700">
                {{ getTime(message.timestamp) }}
              </ion-label>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-item>
      <ion-item lines="none">
        <ion-label style="height: 100px"></ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label id="Test" style="height: 10px"></ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer id="footer">
    <ion-toolbar>
      <ion-item>
        <ion-input
          @keydown.enter="onSendClick"
          v-model="typedInput"
          placeholder="type message"
        ></ion-input>
        <ion-button @click="onSendClick">
          <ion-icon name="send"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import { defineComponent, ref, watchEffect } from "vue";
import { addIcons } from "ionicons";
import { statsChart } from "ionicons/icons";
import patientVitalsModalPopup from "../components/patientVitalsModalPopup.vue";
//import rpmPatientData from "../views/patient/rpmPatientData.vue";
//import  close-circle  from '@icon/ionicons';
import {
  modalController,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonIcon,
  IonList,
  IonTitle,
  //IonHeader,
  IonToolbar,
  IonFooter,
  IonContent,
  IonItem,
  //IonCard,IonCardContent,IonAvatar,
  IonInput,
  IonButton,
  IonLabel,
  IonButtons,
  IonBackButton,
} from "@ionic/vue";
import { useChatStore } from "../stores/chat";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
import { useIonRouter } from "@ionic/vue";

import { DateTime } from "luxon";
export default defineComponent({
  name: "chatList",
  components: {
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonIcon,
    IonList,
    IonTitle,
    //IonHeader,
    IonToolbar,
    IonFooter,
    IonContent,
    IonItem,
    //IonCard,IonCardContent,IonAvatar,
    IonInput,
    IonButton,
    IonLabel,
    IonButtons,
    IonBackButton,
  },
  created() {
    addIcons({
      chart: statsChart  
    });
  },
  setup(props) {
    const userIdValue = ref(props.PATIENTUSERID);
    const ionRouter = useIonRouter();
    const chatStore = useChatStore();
    const mqttStore = useMqttStore();
    const userStore = useUserStore();
    const chatMessagePosition = ref("right");
    const chatmessage = ref(mqttStore.messages.chat);
    const loggedInUserRole = ref(userStore.user["com.rpmone.app/roles"][0]);
    // console.log('userIdValue' + JSON.stringify(userIdValue.value));
   // console.log("userIdValue Props" + JSON.stringify(props));

    watchEffect(() => {
      chatmessage.value = mqttStore.messages.chat;
    });

    return {
      chatStore,
      mqttStore,
      userStore,
      userIdValue,
      ionRouter,
      chatmessage,
      loggedInUserRole,
      chatMessagePosition,
      patientVitalsModalPopup,
      modalController,
      DateTime
    };
  },
  props: {
    PATIENTUSERID: String,
  },
  data() {
    return {
      typedInput: "",
    };
  },

   mounted() {  
    if (this.loggedInUserRole == "staff" && this.userIdValue !== undefined) {
     // this.mqttStore.subscribeToPatientData(this.userIdValue.PATIENTUSERID);
      this.mqttStore.subscribeToPatientChat(this.userIdValue.PATIENTUSERID, 7);
      let that = this;
          this.chatmessage.value =  this.chatmessage.filter(function (el) {
            return (el.patientUserId == that.chatStore.selectedChat.USERID && el.staffUserId == that.userStore.userId);
          });
    }
    else  if (this.loggedInUserRole == "patient" && this.userIdValue !== undefined) {
          //this.mqttStore.subscribeToPatientChat(this.userIdValue.PATIENTUSERID, 7);
          let thats = this;
          this.chatmessage.value =  this.chatmessage.filter(function (el) {         
            return (el.staffUserId == thats.chatStore.selectedChat.STAFFUSERID && el.patientUserId == thats.userStore.userId);
          });
    } 
    this.scrollToBottom();
  },
  unmounted()
  {
    this.mqttStore.unsubscribeMqtt(`chat/${this.userIdValue.PATIENTUSERID}`,this.mqttStore.options7)
  },
  watch: {
    chatmessage: {
      handler: function () {       
        if (this.loggedInUserRole == "staff")
        {
          let that = this;
          this.chatmessage.value =  this.chatmessage.filter(function (el) {
            return (el.patientUserId == that.chatStore.selectedChat.USERID && el.staffUserId == that.userStore.userId);
          });
        }
        else if (this.loggedInUserRole == "patient")
        {
          let thats = this;
          this.chatmessage.value =  this.chatmessage.filter(function (el) {          
            return (el.staffUserId == thats.chatStore.selectedChat.STAFFUSERID && el.patientUserId == thats.userStore.userId);
          });
        }
        this.scrollToBottom();
        //this.closeChatNotification();
      },
      deep: true,
    },
  },
  methods: {

    async closeChatNotification() {      
      if (this.mqttStore.messages?.notifications !== undefined || this.mqttStore.messages?.notifications?.length > 0) {
        let result  = this.mqttStore.messages.notifications.filter(x => x.NOTIFICATIONTYPE == 'chatNotification');
        let len = result?.length;
        if (len == 0)
        return;
        for (var i = 0; i < len; i++) {
          let notificationMessage = {
            USERID: this.userStore.userId,
            NOTIFICATION: result[i]?.NOTIFICATION,
            TIMESTAMP: result[i]?.TIMESTAMP,
            STATUS: "closed",
            NOTIFICATIONTYPE: result[i]?.NOTIFICATIONTYPE,            
          };
         

          let options = {
            qos: 1,
          };

          let notificationTopic = `notifications/${notificationMessage.USERID}`;
          let that = this;
          await this.mqttStore.mqttClient.publish(
            notificationTopic,
            JSON.stringify(notificationMessage),
            options,
            (err) => {
              if (err) {
                console.log(err);
                console.log("this.mqttStore.mqttClient.publish" + err);
              } else {
                console.log(
                  "notification sent to patient " +
                    JSON.stringify(notificationMessage)
                );
                const indexOfObject =
                  that.mqttStore.messages.notifications.findIndex((object) => {

                    let notificationTypeString;
                    notificationTypeString = typeof object?.NOTIFICATION;
                    let n = notificationTypeString == "string" ? JSON.parse(object?.NOTIFICATION) : object?.NOTIFICATION;

                    let notificationMessageString;
                    notificationMessageString = typeof notificationMessage?.NOTIFICATION;
                    let ns = notificationMessageString == "string" ? JSON.parse(notificationMessage?.NOTIFICATION) : notificationMessage?.NOTIFICATION;

                    return (
                      n?.timestamp ===
                      ns?.timestamp
                    );
                  });

                //console.log(indexOfObject);
                if (indexOfObject !== -1)
                  this.mqttStore.messages.notifications.splice(
                    indexOfObject,
                    1
                  );
              }
            }
          );
        }
      }
    },    
    async openModal(selectedChat) {
      console.log('Show patient Vitals' + JSON.stringify(selectedChat));
      const modal = await modalController.create({
        id: "patientVitalModal",
        component: patientVitalsModalPopup,
        cssClass: "modalPatientVital",
        componentProps:{
            selectedPatient: this.chatStore.selectedChat
      },
      });
      modal.present();

      modal.onDidDismiss().then((data) => {
        var d = JSON.stringify(data);
        console.log("Dismiss Data " + d);
        return;
      });
    },
    goToPatientDetail(data) {
      let loggedInUserRole = this.userStore.user["com.rpmone.app/roles"][0];
      if (loggedInUserRole.includes("patient")) return;    
      var roles = data.user["com.rpmone.app/roles"];
      if (!roles.includes("patient")) return;

      // if the user is a patient go to patient details page.
      //this.ionRouter.push("/patientDetail/" + data.USER_ID + "/Patient");
      this.ionRouter.push("/patientDetail/" + data.patientUserId + "/Patient");
      //console.log('Clicked on icon' + JSON.stringify(data.user["com.rpmone.app/roles"]));
    },
    scrollToBottom() {
      // extra ion-item at end is required because this doesn't scroll up enough the see all of last one
      this.$refs.content.$el.scrollToBottom(500);
    },
    getTime(timestamp) {
      return DateTime.fromMillis(timestamp, { zone: "utc" }).toFormat(
        "MM-dd-yyyy hh:mm a"
      );
    },
    onSendClick() {
      if (this.typedInput === "") return;
      let loggedInUserRole = this.userStore.user["com.rpmone.app/roles"][0];
      let topic = `chat/${
        loggedInUserRole == "patient"
          ? this.userStore.userId
          : this.userIdValue.PATIENTUSERID
      }`;

      let m = {
        topic: topic,
        patientUserId: loggedInUserRole == "patient" ? this.userStore.userId: this.userIdValue.PATIENTUSERID,
        staffUserId: loggedInUserRole == "staff" ? this.userStore.userId: this.userIdValue.PATIENTUSERID,
        userId: this.userStore.userId, //staff or user id that is sending message
        user: this.userStore.user,
        chatMessage: this.typedInput,
        timestamp: Date.now(),
        type: 'chatNotification'
      };

      let options = {
        qos: 1,
      };


       // notification message
       let notificationMessage = 
      {
        USERID : loggedInUserRole == "staff" ? m.patientUserId : m.staffUserId,
        NOTIFICATION : m,
        TIMESTAMP : m.timestamp,
        STATUS:'open',
        NOTIFICATIONTYPE: m.type
      }
      
      //this.mqttStore.publishMqtt(topic, JSON.stringify(m));
      this.mqttStore.mqttClient.publish(
        topic,
        JSON.stringify(m),
        options,
        (err) => {
          if (err) {
            console.log(err);
            console.log("this.mqttStore.mqttClient.publish" + err);
            // open success modal pop up
            // this.openModal('Failure', 'There is some issue in Scheduling the meeting! Please contact Administrator.');
            // return;
          } else {
            if (loggedInUserRole == "staff") {
              let notificationTopic = `notifications/${m.patientUserId}`;
              //m = 'chat - ' +  JSON.stringify(m);
              this.mqttStore.mqttClient.publish(
                notificationTopic,
                JSON.stringify(notificationMessage),
                options,
                (err) => {
                  if (err) {
                    console.log(err);
                    console.log("this.mqttStore.mqttClient.publish" + err);
                  } else {
                    console.log(
                      "notification sent to patient " + notificationMessage.userId
                    );
                  }
                }
              );
            } else if (this.loggedInUserRole == "patient") {
              let notifyTopic = `notifications/${m.staffUserId}`;
              //m = 'chat - ' +  JSON.stringify(m);
              this.mqttStore.mqttClient.publish(
                notifyTopic,
                JSON.stringify(notificationMessage),
                options,
                (err) => {
                  if (err) {
                    console.log(err);
                    console.log("this.mqttStore.mqttClient.publish" + err);
                  } else {
                    console.log(
                      "notification sent to Staff " + notificationMessage.userId
                    );
                  }
                }
              );
            }
          }
        }
      );
      
      this.typedInput = "";
      this.scrollToBottom();
    },
  },
});
</script>
<style scoped>
/* .chat-bubble-heading-loggedinuser
{
  margin: 0px; 
  color: white; 
  font-weight: 600"
}

.chat-bubble-heading
{
  margin: 0px; 
  color: black; 
  font-weight: 600"
} */

ion-button {
  --background: #ef9116;
  --color: white;
  font-family: "OpenSansRegular";
  font-style: normal;
  height: 30px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 1px;
}

/* container */
.container {
  padding: 5% 5%;
}

/* CSS talk bubble */
.talk-bubble-left {
  margin-top: 20px;
  margin-left: 20px;
  display: inline-block;
  position: relative;
  width: 90%;
  height: auto;
  background-color: lightgrey;
  border-radius: 15px;
}

.talk-bubble-right {
  margin-top: 20px;
  /* margin-left: 20px; */
  margin-right: 10px;
  display: inline-block;
  position: relative;
  width: 90%;
  height: auto;
  background-color: deepskyblue;
  border-radius: 15px;
}

.border {
  border: 8px solid #666;
}
.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

.titleText {
  padding-left: 0px;
  margin-left: 0px;
  font-size: 14px;
  font-family: "OpenSansRegular";
  font-weight: 700;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 12px solid;
  border-color: deepskyblue transparent transparent transparent;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: lightgrey lightgrey transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #666;
}
.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent deepskyblue;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: deepskyblue transparent transparent deepskyblue;
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: deepskyblue deepskyblue transparent transparent;
}
/*
	left: -8px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 32px solid;
	border-color: transparent transparent transparent #666;
	left: 0px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent deepskyblue;

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: deepskyblue deepskyblue transparent transparent;
}

/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 38px;
  bottom: auto;
  border: 12px solid;
  border-color: deepskyblue transparent transparent deepskyblue;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}
.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: deepskyblue transparent transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}
</style>
