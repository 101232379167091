<template>
  <ion-button class="box-button" v-on:click="redirectTo">
    <ion-grid style="height: 100%">
      <ion-row align-items-center>
        <ion-col style="width: 100%">
          <ion-label class="ion-label-first" text-wrap> {{ firstLabel }}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row align-items-center>
        <ion-col style="width: 100%">
          <img :src="image" class="iconImage" />
          <ion-badge
            v-if="firstLabel == 'Notifications'"
            id="notifications-badge"
            color="danger"
            >{{
              this.mqttStore.messages?.notifications?.length > 0
                ? this.mqttStore.messages?.notifications?.length
                : ""
            }}</ion-badge
          >
          <ion-badge
            v-if="firstLabel == 'Chat'"
            id="notifications-badge"
            color="danger"
            >{{
              this.mqttStore.messages.notifications.length > 0
                ? this.mqttStore.messages.notifications.filter(
                    (x) => x.type == "chatNotification" && x.patientUserId == this.userStore.userId
                  )?.length
                : ""
            }}</ion-badge
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-button>
</template>

<script>
import { calendar, home } from "ionicons/icons";
import { defineComponent } from "vue";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
import { userNotificationStore } from "../stores/notification";
import { useRouter } from "vue-router";

import {
  IonButton,
  IonLabel,
  //IonIcon,
  IonGrid,
  IonCol,
  IonRow,
  IonBadge,
  modalController,
} from "@ionic/vue";

export default defineComponent({
  name: "LaunchPadBox",
  components: {
    IonButton,
    IonLabel,
    IonBadge,
    //IonIcon,
    IonGrid,
    IonCol,
    IonRow,
  },
  props: {
    firstLabel: String,
    image: String,
    additionalInfo: String,
    navigateTo: String,
  },
  methods: {},

  setup(props) {
    const store = userNotificationStore();
    const mqttStore = useMqttStore();
    const router = useRouter();
    const userStore = useUserStore();

    // let c = mqttStore.messages.notifications.filter(
    //                 (x) => x.type == "chatNotification" && x.patientUserId == userStore.userId
    //               )?.length;

    // console.log(c);

    function redirectTo() {
      if (props.navigateTo !== "") {
        store.setNotificationCount(props.additionalInfo);
        modalController.dismiss(null, "dismiss");
        router.push(props.navigateTo);
      }
    }

    return { calendar, home, store, redirectTo, modalController, mqttStore, userStore };
  },
});
</script>
<style scoped>
#notifications-badge {
  background-color: red;
  position: absolute;
  top: 6px;
  right: 43px;
  border-radius: 100%;
}

.iconImage {
  height: 62px;
  width: 62px;
}
.ion-grid-row {
  background: #f5f5f5;
  height: 80%;
}

.ion-icon-image {
  height: 40px;
  width: 40px;
  background-color: white;
}

.box-button {
  width: 177px;
  height: 164px;

  /* background-color: white; */

  /*  background: white;
         box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
}

ion-button {
  --background: white;
  --background-hover: hsla(150, 3%, 77%, 0.171);
  --background-activated: hsla(150, 3%, 77%, 0.171);
  --background-focused: hsla(150, 3%, 77%, 0.171);

  /* 
     --border-radius: 0;
     --border-color: #000;
     --border-style: solid;
     --border-width: 1px; */

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --ripple-color: deeppink;

  /* --padding-top: 10px;
     --padding-bottom: 10px; */
}

.ion-label-first {
  /* color: black;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  height: 48px;
  line-height: 150%;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  display: grid;
  justify-items: center;

width: 144px;
height: 30px;
left: 10px;
top: 6px; */

  /* position: absolute;
width: 132px;
height: 30px;
left: 16px;
top: 6px; */

  /* width: 132px;
height: 30px; */
  /* left: 16px;
top: 6px; */

  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  text-align: center;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  color: #606b7c;
  text-transform: none;
}

.ion-label-second {
  color: black;

  height: 24px;

  font-family: "roboto-slabBold";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 150%;
  /* or 38px */

  display: grid;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
}
.ion-label-third {
  /* width: 79px; */
  height: 21px;

  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: grid;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;

  color: #ef9116;
}
</style>
