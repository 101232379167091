<template>
  <ion-page>
    
    <ion-content>
        <ion-grid class="span-class blur">
            <ion-row style="height: 15%;">
                <ion-col>                   
                    <ion-img
                        class="rpm-image"      
                        src="assets//images//RPM-One_logo_Welcome.png" 
                    ></ion-img>                
                </ion-col>
                <ion-col>                   
                    <ion-label class="rpm-one">RPM-One</ion-label>              
                </ion-col>
            </ion-row>           
            <ion-row style="height: 15%;">
                <ion-col>                   
                        <ion-label class="letsgetStarted">Lets Get Started</ion-label>
                </ion-col>
            </ion-row>
            <ion-row style="height: 15%;">
                <ion-col>                   
                        <ion-label class="label">To start providing services, you must complete the RPM-ONE Provider Certification.</ion-label>
                </ion-col>
            </ion-row>
            <ion-row style="height: 10%;">
                <ion-col>                   
                        <ion-label class="label">Approximate Duration: 60 Mins.</ion-label>
                </ion-col>
            </ion-row>
            <ion-row style="height: 10%;">
                <ion-col>                   
                        <ion-label class="label">Incentive: $50 upon completion*</ion-label>
                </ion-col>
            </ion-row>
            <ion-row style="height: 20%;">
                <ion-col>                   
                        <ion-label class="incentive">*Incentive is provided after provider has completed 120 billable minutes of monitoring services.</ion-label>
                </ion-col>
            </ion-row>
            <ion-row style="height: 10%;">
                <ion-col>  
                    <ion-button shape="round" class="bgDecline" @click="redirectToHome"  expand="block">START TRAINING</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
      </ion-content>    


</ion-page>
</template>
  <script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonLabel,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonImg 
} from "@ionic/vue";
//import { ref } from "vue";
import { addIcons } from "ionicons";
import { arrowBack, arrowForward, chevronForwardOutline } from "ionicons/icons";
//import { useUserStore } from "../../stores/user";
import { useWelcomeStore } from "../../stores/welcome";
import { useIonRouter } from "@ionic/vue";
export default defineComponent({
  name: "staffGetStarted",
  components: {
    IonPage,
    IonLabel,
    IonButton,   
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonImg
  },
  created() {
    addIcons({
      "arrow-back": arrowBack,
      "chevron-forward-outline": chevronForwardOutline,
      forward: arrowForward,
    });
  },
  setup() {
   // const userStore = useUserStore();
    const welcomeStore = useWelcomeStore();
    const router = useIonRouter();

    function redirectToHome()
    {
      welcomeStore.setShowWelcome(false);
      router.push("/");
    }
    
    function setShowWelcome() {
      welcomeStore.setShowWelcome(false);
      router.push('/')
    }

    return { setShowWelcome, redirectToHome, welcomeStore };
  },
});
</script>
  <style scoped>

    .incentive
    {
        position: absolute;
        width: 359px;
        height: 46px;
        left: 18px;


        font-family: 'OpenSansRegular';
        font-style: normal;       
        font-size: 12px;
        line-height: 180%;
        /* or 22px */

        text-align: center;
        letter-spacing: 1px;

        /* background */

        color: #FEFEFE;
    }
  .label
  {
    position: absolute;
    width: 325px;
    height: 265px;
    left: 35px;   
    font-family: 'roboto-slabRegular';
    font-style: normal;  
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    /* background */
    color: #FEFEFE;
  }

  .letsgetStarted
  {
    position: absolute;
    width: 327px;
    height: 53px;
    left: 34px;


    font-family: 'OpenSansRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 150%;
    /* identical to box height, or 52px */

    text-align: center;
    letter-spacing: 1px;

    /* background */

    color: #FEFEFE;

  }


.ion-accordion-item {
  --border-width: 0px 0px 0px 0px;
  color: white;
  background: black;
  /* backdrop-filter: blur(1px); */
}
.ion-accordion-toggle-icon {
  background-color: white;
  color: white;
}

.bg-transparent {
  background-color: transparent !important;
}
.rpm-one-thumbnail {
  background: transparent;
  /* backdrop-filter: blur(1px); */
}

.blur {
  position: relative;
  display: inline-block; /* make the div not 100% */
}
/* the 'blur' effect */
.blur:before {
  content: "";
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.blur img {
  display: block; /* remove bottom space */
}

.success {
  color: #20c933;
}

.rpm-one-padding {
  padding-bottom: 20px;
}
.welcomeTo {
  position: fixed;
  width: 216px;
  height: 53px;
  left: 84px;
  top: 85px;
  font-family: "OpenSansRegular", "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 150%;
  /* identical to box height, or 52px */

  text-align: center;
  letter-spacing: 1px;

  /* background */

  color: white;
}
.span-class {  
  left: 0px;
  height: 100%;
  width: 100%;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  background: url("../../assets/RPM-One_logo_Designs-18\ 1.png");
  
}
.rpm-image {
  position: fixed;
  width: 60px;
  height: 66.7px;
  left: 43px;
  /* background: transparent;
    backdrop-filter: blur(1px);
    background: url("../../public/assets/images/RPM-One_logo_Welcome.png"); */
}

.rpm-one {
    position: fixed;
    /* width: 208px; */
    /* height: 59px; */
    left: 133px;
    /* top: 164px; */
    font-family: "roboto-slabRegular";
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 2px;
    color: #fefefe;
}

.rpm-one-text {
  position: fixed;
  width: 325px;
  height: 78px;
  left: 35px;
  top: 268px;

  font-family: "roboto-slabRegular";
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  white-space: pre-line;

  /* background */

  color: #fefefe;
}
.rpm-div-second {
  position: fixed;
  width: 395px;
  left: 0px;
  top: 400px;

  /* Main heading */
  background: black;
}

.rpm-one-questions-icon {
  color: white;
  font-weight: bold;
  padding-right: 8px;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  --ionicon-stroke-width: 64px;
  --ionicon-stroke-weight: 10px;
}

ion-icon {
  --ionicon-stroke-width: 66px;
}

.rpm-one-questions {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-size: 18px;

  background-color: black;
  /* or 26px */

  letter-spacing: 1px;
  text-decoration-line: underline;

  /* background */

  color: white;
}

.rpm-one-answers {
  font-family: "OpenSansRegular";
  font-style: normal;
  background-color: black;
  letter-spacing: 1px;
  padding-left: 19%;
  /* background */
  color: white;
}

.rpm-ready-to-get-started {
  font-family: "OpenSansRegular";
  font-style: normal;
  align-content: center;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 1px;
  /* background */

  color: #fefefe;
}

ion-button {
  --background: #ef9116;
  --color: white;
  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  height: 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
</style>
  