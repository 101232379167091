<template>
  <ion-page>
    <ion-content>
      <ion-card>
        <ion-card-content class="span-class">
          <ion-label class="rpm-please-select"
            >Please select your skin tone</ion-label
          >
          <ion-label class="rpm-inorder-for"
            >In order for the bracelet to better monitor your health, choose a
            color that is close to your skin.</ion-label
          >
          <div slot="start" class="rpm-div-circle">
            <ion-button
              class="rpm-ion-button-1 rpm-ion-button-circle-common"
              id="F6D1B4"
              name="first"
              shape="circle"
              @click="SkinToneClicked($event)"
            ></ion-button>
            <ion-button
              class="rpm-ion-button-2 rpm-ion-button-circle-common"
              id="E6B493"
              name="second"
              shape="circle"
              @click="SkinToneClicked($event)"
            ></ion-button>
            <ion-button
              class="rpm-ion-button-3 rpm-ion-button-circle-common"
              id="E5B380"
              name="third"
              shape="circle"
              @click="SkinToneClicked($event)"
            ></ion-button>
            <ion-button
              class="rpm-ion-button-4 rpm-ion-button-circle-common"
              id="BC7853"
              name="fourth"
              shape="circle"
              @click="SkinToneClicked($event)"
            ></ion-button>
            <ion-button
              class="rpm-ion-button-5 rpm-ion-button-circle-common"
              id="A55B2C"
              name="fifth"
              shape="circle"
              @click="SkinToneClicked($event)"
            ></ion-button>
            <ion-button
              class="rpm-ion-button-6 rpm-ion-button-circle-common"
              id="3D1E1B"
              name="six"
              shape="circle"
              @click="SkinToneClicked($event)"
            ></ion-button>
          </div>
        </ion-card-content>
        <ion-card-content class="rpm-div-second">
          <!-- <router-link to="/" slot="button"> -->
          <ion-button
            @click="setShowWelcome"
            shape="round"
            class="ion-button-continue"
            :disabled="!enableContinue"
            >CONTINUE</ion-button
          >
          <!--  </router-link> -->
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>
<script>
import { computed, defineComponent, onMounted } from "vue";
//import { useRouter } from "vue-router";
import { useIonRouter } from "@ionic/vue";
import {
  IonCard,
  IonCardContent,
  IonButton,
  IonLabel,
  IonPage,
  IonContent,
} from "@ionic/vue";
import { ref } from "vue";
import { useskinToneStore } from "../../stores/skinTone";
import { useWelcomeStore } from "../../stores/welcome";
export default defineComponent({
  name: "SkinTonePicker",
  components: {
    IonCard,
    IonCardContent,
    IonButton,
    IonLabel,
    IonPage,
    IonContent,
  },
  setup() {
    const router = useIonRouter();
    const welcomeStore = useWelcomeStore();
    var skinToneSelected = ref(false);
    const store = useskinToneStore();
    function SkinToneClicked(e) {
      //alert(e.target.attributes.name.value)
      this.skinToneSelected = true;

      const buttons = this.$el.querySelectorAll(
        ".rpm-ion-button-circle-common"
      );
      if (buttons != null) {
        for (var i = 0, len = buttons.length; i < len; i++) {
          //work with button[i]
          buttons[i].style.border = "none";
          buttons[i].style.border = "1px none";
          buttons[i].style.borderRadius = "50%";
        }
      }
      store.setSkinTone(e.target.id);

      e.target.style.border = "none";
      e.target.style.border = "3px solid #3468B9";
      e.target.style.borderRadius = "50%";
      return this.skinToneSelected;
    }

    function setShowWelcome() {
      welcomeStore.setShowWelcome(false);
      router.push("/home");
    }

    const enableContinue = computed(() => {
      // console.log('skinToneSelected' + skinToneSelected.value);
      var isEnabled = skinToneSelected.value ? true : false;
      // console.log(isEnabled);
      return isEnabled;
    });

    onMounted(() => {
      //console.log(circle.value);
    });

    return {
      skinToneSelected,
      enableContinue,
      SkinToneClicked,
      setShowWelcome,
      welcomeStore
    };
  },
});
</script>
<style scoped>
.rpm-div-second {
  position: fixed;
  width: 395px;
  height: 416px;
  left: 0px;
  top: 87%;
}

.ion-button-continue {
  --color: white;
  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: flex;
  height: 15%;
  flex-direction: column;
  align-items: center;
  --background: #ef9116;
}

ion-button[disabled] {
  --background: #a3a4a6 !important;
}
.ion-button-continue-enabled {
  --background: #ef9116;
}

.ion-button-continue-disabled {
  --background: #a3a4a6;
}

.rpm-ion-button-circle-highlight {
  --border-radius: 50%;
  width: 54.47px;
  height: 54.47px;
  --border-width: 3px;
  --border-color: solid #3468b9;
}

.rpm-ion-button-circle-common {
  --border-radius: 50%;
  width: 54.47px;
  height: 54.47px;
  --border-color: white;
  --border-style: solid;
  --border-width: 1px;
}

.rpm-ion-button-1[shape="circle"] {
  position: fixed;
  left: 166.56px;
  top: 254px;
  --background: #f6d1b4;
}
.rpm-ion-button-2[shape="circle"] {
  position: fixed;

  left: 286.53px;
  top: 332.02px;
  --background: #e6b493;
}
.rpm-ion-button-3[shape="circle"] {
  position: fixed;

  left: 281.39px;
  top: 457.11px;
  --background: #e5b380;
}
.rpm-ion-button-4[shape="circle"] {
  position: fixed;

  left: 171.1px;
  top: 524.88px;
  --background: #bc7853;
}
.rpm-ion-button-5[shape="circle"] {
  position: fixed;
  left: 39px;
  top: 457.11px;
  --background: #a55b2c;
}
.rpm-ion-button-6[shape="circle"][shape="circle"] {
  position: fixed;
  left: 39px;
  top: 332.02px;
  --background: #3d1e1b;
}
.rpm-div-circle {
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  width: 275.76px;
  height: 273.13px;
  left: 54.89px;
  top: 280.93px;

  /* background */

  border: 2px dashed #fefefe;
}
.rpm-inorder-for {
  position: absolute;
  width: 361px;
  height: 63px;
  left: 17px;
  top: 171px;

  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  text-align: center;
  letter-spacing: 2px;

  color: #ffffff;
}
.rpm-please-select {
  position: absolute;
  width: 334px;
  height: 106px;
  left: 30px;
  top: 44px;

  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 150%;
  /* or 52px */

  text-align: center;
  letter-spacing: 2px;

  /* background */

  color: #fefefe;
}
.blur {
  position: relative;
  display: inline-block; /* make the div not 100% */
}
/* the 'blur' effect */
.blur:before {
  content: "";
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.blur img {
  display: block; /* remove bottom space */
}
.span-class {
  position: fixed;
  width: 395px;
  height: 844px;
  left: 0px;
  top: 0px;

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  background-image: url("..//..//..//public//assets//images//skinTonePicker-background.png");
}
</style>
