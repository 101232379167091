import { ref } from "vue";
import { defineStore } from "pinia";
export const userpmBasicStore = defineStore(
  "rpmBasicStore",
  () => {
    const rpmbasic = ref("");

    function setRpmBasic(val) {
      rpmbasic.value = val;
    }
    return { rpmbasic, setRpmBasic };
  },
  {
    persist: true,
  }
);


