<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card style="">
        <ion-card-content>
          <ion-segment
            :value="segmentType"
            @click="segmentClick"
            class="ionsegment"
          >
            <ion-segment-button value="review">
              <ion-label class="segmentLabel">
                Queue<ion-badge slot="end" class="reviewBadge">{{
                  this.myAlerts.value?.length
                }}</ion-badge>
              </ion-label>
            </ion-segment-button>
            <!-- <ion-segment-button value="alert" @click="segmentClick">
          <ion-label class="segmentLabel">
            Alerts
            &nbsp;<ion-badge slot="end" class="reviewAlert">{{ this.dataAlert.length }}</ion-badge>
          </ion-label>
        </ion-segment-button> -->

            <ion-segment-button value="myPatients" @click="segmentClick">
              <ion-label class="segmentLabel">
                My Patients
                <ion-badge slot="end" class="reviewMyPatients">{{
                  this.myPatients.value?.length
                }}</ion-badge>
              </ion-label>
            </ion-segment-button>
            <ion-segment-button value="actions" @click="segmentClick">
              <ion-label class="segmentLabel">
                My Actions
                <ion-badge slot="end" class="reviewAlert">{{
                  this.mqttStore.messages.commands.digitalTriage.action?.length
                }}</ion-badge>
              </ion-label>
            </ion-segment-button>
          </ion-segment>
          <ion-searchbar
            show-clear-button="focus"
            :placeholder="placeHolderSearch"
            v-model="searchBar"
            @ionChange="searchbyVal"
            @ionClear="clearSearch"
            value="SearchText"
            class="searchbar"
          ></ion-searchbar>
        </ion-card-content>
      </ion-card>

      <ion-grid v-if="this.segmentType == 'review' ? true : false">
        <ion-row
          style="display: inline"
          v-for="message in this.myAlerts?.value"
          :key="message.timestamp"
          :value="message"
        >
          <rpmStaffReview
            :data="message"
            buttonText="Accept"
            :keyValue="message.timestamp"
            @clearMessage="clearMessage"
          ></rpmStaffReview>
        </ion-row>
      </ion-grid>

      <ion-grid v-if="this.segmentType == 'myPatients' ? true : false">
        <ion-row
          style="display: inline"
          v-for="message in this.myPatients?.value"
          :key="message.timestamp"
          :value="item"
        >
          <rpmStaffReview
            :data="message"
            buttonText="Start"
            :keyValue="index"
          ></rpmStaffReview>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="this.segmentType == 'actions' ? true : false">
        <ion-row>
          <ion-col size="12">
            <!-- <ion-list> -->
            <ion-accordion-group ref="accordionGroup" text-wrap>
              <ion-accordion
                style="border-bottom: 1px solid white"
                v-for="message in this.contacts.value"
                :key="message.key"
                :value="message.key"
              >
                <ion-item slot="header" style="--background: lightgray">
                  <ion-label class="labelGroup">
                    Patient : {{ message.key }}
                  </ion-label>
                </ion-item>
                <!-- <ion-item-group style="margin-left:10px" v-for="message in this.contacts.value" :key="message.key"> -->
                <div slot="content" text-wrap>
                  <ion-item
                    v-for="contact in message.values"
                    :key="contact.TIMESTAMP"
                    :message="contact"
                  >
                    <ion-label class="labelText">
                      {{ contact.ACTION }} at
                      {{ this.getTime(contact.TIMESTAMP) }}
                    </ion-label>
                  </ion-item>
                </div>

                <!-- </ion-item-group> -->
              </ion-accordion>
            </ion-accordion-group>
            <!-- </ion-list> -->
          </ion-col>
        </ion-row>

        <!-- <ion-row
          style="display: inline"
          v-for="message in mqttStore.messages.commands.digitalTriage.action" 
          :key="message.timestamp"
          :value="item"
        >
          <rpmStaffMyAction
            :data="message"
            @clearMessage="clearMessage"
          ></rpmStaffMyAction>
        </ion-row> -->
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
//import { IonSearchbar } from '@ionic/vue';
import { defineComponent, ref, reactive, watchEffect } from "vue";
import rpmStaffReview from "../../components/rpmStaffReviewQueue.vue";
import { useMqttStore } from "../../stores/mqtt";
import { useWelcomeStore } from "../../stores/welcome";
import { DateTime } from "luxon";
import { computed } from "vue";

import {
  // IonList,
  IonBadge,
  IonCard,
  IonCardContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonItem,
  // IonItemDivider,
  //IonItemGroup,
  // IonAvatar,
  IonCol,
  IonRow,
  IonContent,
  IonLabel,
  IonSearchbar,
  IonAccordion,
  IonAccordionGroup,
  loadingController,
} from "@ionic/vue";
//import { userNotificationStore } from "../../stores/notification";

export default defineComponent({
  name: "staffReviewAlert",
  components: {
    // IonList,
    IonBadge,
    rpmStaffReview,
    //rpmStaffMyAction,
    IonPage,
    IonContent,
    IonLabel,
    IonGrid,
    IonItem,
    // IonItemDivider,
    IonAccordion,
    IonAccordionGroup,
    // IonItemGroup,
    // IonAvatar,
    IonRow,
    IonCol,
    IonSegment,
    IonCard,
    IonCardContent,
    IonSegmentButton,
    IonSearchbar,
  },
  setup() {
    const segmentType = ref("review");
    const mqttStore = useMqttStore();
    const welcomeStore = useWelcomeStore();
    const searchBar = ref("");
    const isLoading = ref(true);
    const placeHolderSearch = ref("Search by name, risk level or risk score ");
    const myAlerts = reactive(mqttStore.messages.events.digitalTriage.alert);
    const myalertsRef = ref(mqttStore.messages.events.digitalTriage.alert);
    const myPatients = reactive(mqttStore.messages.events.ui.staff.mypatients);
    //console.log('myAlerts ' + JSON.stringify(myAlerts));

    const myActions = ref(mqttStore.messages.commands.digitalTriage.action);
    const contacts = ref(myActions);

    const showLoading = async () => {
      return await loadingController
        .create({
          message: "Loading...",
          //duration: 3000,
          spinner: "circles",
          id: "loadingController",
        })
        .then((a) => {
          a.present().then(() => {
            console.log("presented");
            if (!isLoading.value) {
              a.dismiss().then(() => console.log("abort presenting"));
            }
          });
        });
      //loading.present();
    };

    const myAlertComputed = computed(() => {
      if (myAlerts.value?.length > 0) {
        dismissLoading();
      }
      return null;
    });

    const dismissLoading = () => {
      const toggle = document.querySelector("#loadingController");
      if (toggle !== undefined && toggle !== null) {
        return toggle
          .dismiss()
          .then((res) => {
            console.log("Loading dismissed!", res);
            if (!res) {
              //toggle.dismiss();
              setTimeout(() => {
                toggle.dismiss();
              }, 1000);
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    };

    watchEffect(() => {
      if (myalertsRef.value?.length > 0) {
        const toggle = document.querySelector("#loadingController");
        if (toggle !== undefined && toggle !== null) {
          console.log("dismiss: myalertsRef watch effect");
          toggle.dismiss();
        }
      }

      if (searchBar.value !== "" && segmentType.value == "review") {
        myAlerts.value = mqttStore.messages.events.digitalTriage.alert.filter(
          (item) =>
            item.NICKNAME?.toLowerCase().indexOf(
              searchBar.value.toLowerCase()
            ) > -1 ||
            item.RISKSCORE?.toString().indexOf(searchBar.value.toLowerCase()) >
              -1 ||
            item.RISKLEVEL?.toLowerCase().indexOf(
              searchBar.value.toLowerCase()
            ) > -1
        );
      } else if (searchBar.value !== "" && segmentType.value == "myPatients") {
        myPatients.value = mqttStore.messages.events.ui.staff.mypatients.filter(
          (item) =>
            item.NICKNAME?.toLowerCase().indexOf(
              searchBar.value.toLowerCase()
            ) > -1 ||
            item.RISKSCORE?.toString().indexOf(searchBar.value.toLowerCase()) >
              -1 ||
            item.RISKLEVEL?.toLowerCase().indexOf(
              searchBar.value.toLowerCase()
            ) > -1
        );
      } else if (searchBar.value !== "" && segmentType.value == "actions") {
        myActions.value =
          mqttStore.messages.commands.digitalTriage.action.filter(
            (item) =>
              item.USERID.toLowerCase().indexOf(searchBar.value.toLowerCase()) >
              -1
          );
      } else {
        myPatients.value = mqttStore.messages.events.ui.staff.mypatients;
        myAlerts.value = mqttStore.messages.events.digitalTriage.alert;
        myActions.value = mqttStore.messages.commands.digitalTriage.action;
      }
      if (myAlerts.value?.length > 0) {
        isLoading.value = false;
        //dismissLoading();
        setTimeout(() => dismissLoading(), 8000);
      }
    });

    return {
      rpmStaffReview,
      contacts,
      myActions,
      segmentType,
      mqttStore,
      myAlerts,
      searchBar,
      myPatients,
      placeHolderSearch,
      showLoading,
      myalertsRef,
      dismissLoading,
      isLoading,
      myAlertComputed,
      welcomeStore,
    };
  },
  mounted() {
    
    if (this.welcomeStore.showWelcome) return;
   // console.log('this.welcomeStore.showWelcome' + this.welcomeStore.showWelcome);
    if (this.myAlerts.value?.length == 0) this.showLoading();
    // console.log('myActions' + JSON.stringify(this.mqttStore.messages.commands.digitalTriage.action));
  },
  watch: {
    myActions: {
      handler: function (val, oldVal) {
        console.log(val);
        console.log(oldVal);
        let sContacts = this.sortArray(this.myActions, "NICKNAME", 1);
        this.contacts.value = this.groupByArray(
          sContacts,
          "NICKNAME",
          "ACTION"
        );
        //console.log("Contacts" + JSON.stringify(this.contacts.value));
        //if (this.myAlerts?.value?.length > 0) this.dismissLoading();
      },

      deep: true,
    },
    isLoading: {
      handler: function () {
        if (!this.isLoading) {
          // console.log("isLoading" + val);
          // console.log("isLoading" + oldVal);
         // console.log("Loading is " + this.isLoading);
          setTimeout(() => this.dismissLoading(), 3000);
        }
      },

      deep: true,
    },
    myalertsRef: {
      handler: function (val, oldVal) {
        console.log("myAlerts " + val);
        console.log("myAlerts " + oldVal);

        if (val?.length > 0) {
          const toggle = document.querySelector("#loadingController");
          if (toggle !== undefined && toggle !== null) {
            console.log("dismiss: myalertsRef");
            toggle.dismiss();
          }
        }
      },

      deep: true,
    },
  },

  methods: {
    dismissLoader() {
      const toggle = document.querySelector("#loadingController");
      if (toggle !== undefined && toggle !== null) {
        console.log("dismiss: myAlerts");
        console.log(JSON.stringify(toggle));
        toggle.dismiss();
      }
    },
    searchbyVal(e) {
      //console.log(e);
      if (this.segmentType == "review") {
        this.searchBar = e.target.value;
      }
    },

    clearSearch() {
      //this.myAlerts.value = this.mqttStore.messages.events.digitalTriage.alert;
    },

    getTime(timestamp) {
      // lookup your timezone, etc here.
      let intTimeStamp = parseInt(timestamp);
      return DateTime.fromMillis(intTimeStamp, { zone: "utc" }).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      );
    },

    clearMessage(val, m) {
      console.log(val + m);
      this.mqttStore.messages.events.digitalTriage.alert.splice(val, 1);
    },

    segmentClick(e) {
      //console.log(e.target.value);
      this.segmentType = e.target.value;
      this.searchBar = "";
      if (
        (this.searchBar === "" || this.searchBar.value == undefined) &&
        (this.segmentType == "review" || this.segmentType == "myPatients")
      ) {
        this.placeHolderSearch = "Search by name, risk level or risk score ";
      } else if (
        (this.searchBar === "" || this.searchBar.value == undefined) &&
        this.segmentType == "actions"
      ) {
        this.placeHolderSearch = "Search by name ";
      }
    },

    groupByArray(xs, key, sortKey) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r) => r && r.key === v);

        if (el) {
          el.values.push(x);
          el.values.sort(function (a, b) {
            return a[sortKey]
              .toLowerCase()
              .localeCompare(b[sortKey].toLowerCase());
          });
        } else {
          rv.push({ key: v, values: [x] });
        }

        return rv;
      }, []);
    },

    sortArray(array, property, direction) {
      direction = direction || 1;
      array.sort(function compare(a, b) {
        let comparison = 0;
        if (a[property] > b[property]) {
          comparison = 1 * direction;
        } else if (a[property] < b[property]) {
          comparison = -1 * direction;
        }
        return comparison;
      });
      return array;
    },
  },
});
</script>
<style scoped>
.searchbar {
  margin: 0px;
  padding: 0px;
  font-family: "OpenSansRegular";
}

ion-searchbar .searchbar-input-container {
  font-size: 12px;
}

.labelText {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* line-height: 150%;
  /* or 27px */

  /* color: #000000; */
}
.labelGroup {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  /* line-height: 150%;
  /* or 27px */

  /* color: #000000; */
}

.reviewBadge {
  background: rgba(206, 6, 6, 0.2);
  color: #ce0606;
}

.reviewMyPatients {
  background: green;
  color: white;
}

.segmentLabel {
  /* width: 97px;
    height: 19px; */

  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Main heading */

  text-transform: none;
}
</style>
