<template>
<ion-page>

    <ion-content :fullscreen="true">
        <ion-grid>

            <ion-row>
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>RPM-ONE</ion-card-title>
                            <ion-card-subtitle>Bluetooth Status</ion-card-subtitle>
                        </ion-card-header>

                        <ion-card-content>
                        </ion-card-content>
                    </ion-card>

                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-list>
                        <!-- <ion-item>
                            <ion-label class="ion-text-wrap">
                                <ion-text color="primary">
                                    Secure Login
                                </ion-text>
                                <UserProfile v-if="userStore.isAuthenticated" />
                                <UserLogin v-else />
                            </ion-label>
                            <ion-icon v-if="userStore.isAuthenticated" slot="end" color="success" :icon="checkmark"></ion-icon>

                        </ion-item> -->
                        <ion-item v-if="userStore.isAuthenticated">
                            <ion-label class="ion-text-wrap">
                                <ion-text color="primary">
                                    Connect to device
                                </ion-text>
                                <ion-text v-if="connectedToDevice" color="secondary">
                                    <p>Connected to: {{deviceName}}</p>
                                </ion-text>
                            </ion-label>
                            <ion-icon v-if="connectedToDevice" slot="end" color="success" icon="checkmark"></ion-icon>

                            <ion-button v-if="!connectedToDevice" @click="initBlueTooth">Connect</ion-button>
                        </ion-item>
                        <ion-item v-if="userStore.isAuthenticated && connectedToDevice">
                            <ion-label class="ion-text-wrap">
                                <ion-text color="primary">
                                    Receiving from device
                                </ion-text>

                            </ion-label>
                            <ion-badge :style="{margin:'5px'}" slot="end" color="secondary">{{received}}</ion-badge>
                            <ion-icon v-if="receiving" slot="end" color="success" :icon="checkmark"></ion-icon>
                        </ion-item>
                        <ion-item v-if="userStore.isAuthenticated && connectedToDevice">
                            <ion-label class="ion-text-wrap">
                                <ion-text color="primary">
                                    Sending to RPM-ONE
                                </ion-text>

                            </ion-label>
                            <ion-badge :style="{margin:'5px'}" slot="end" color="secondary">{{sent}}</ion-badge>
                            <ion-icon v-if="sending" slot="end" color="success" :icon="checkmark"></ion-icon>
                        </ion-item>
                    </ion-list>
                </ion-col>
            </ion-row>
            <ion-row v-if="measurements.length > 0">
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-title>Bluetooth Device</ion-card-title>
                            <ion-card-subtitle>Available Services</ion-card-subtitle>
                        </ion-card-header>

                        <ion-card-content>
                        </ion-card-content>
                    </ion-card>

                </ion-col>
            </ion-row>
            <ion-row v-if="measurements.length > 0">
                <ion-col>
                    <ion-list>
                        <ion-item v-for="item in measurements" :key="item.measurementUuid" >
                            <ion-label class="ion-text-wrap">
                                
                                <p>{{ item.measurementUuid }}</p>
                                <ion-buttons>
                                    <ion-button color="success" v-if="item.notify">
                                        <ion-icon slot="start" icon="notifications"></ion-icon>
                                        Notification
                                    </ion-button>

                                    <ion-button color="success" v-if="item.read">
                                        <ion-icon slot="start" icon="book"></ion-icon>
                                        Read
                                    </ion-button>
                                    <ion-button color="success" v-if="item.write">
                                        <ion-icon slot="start" icon="create"></ion-icon>
                                        Write
                                    </ion-button>
                                    <ion-button color="success" v-if="item.broadcast">
                                        <ion-icon slot="start" icon="megaphone"></ion-icon>
                                        Broadcast
                                    </ion-button>
                                    <ion-button color="success" v-if="item.indicate">
                                        <ion-icon slot="start" icon="checkmark"></ion-icon>
                                        Indicate
                                    </ion-button>
                                </ion-buttons>
                            </ion-label>
                        </ion-item>
                        
                    </ion-list>
                </ion-col>
            </ion-row>

        </ion-grid>
    </ion-content>
</ion-page>
</template>

<script>
import {
    defineComponent
} from 'vue';
import {
    IonList,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonItem,
    IonLabel,
    IonBadge,
    IonIcon,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonContent,
    IonButton,
    IonButtons
} from '@ionic/vue';
import {
    BleClient,numbersToDataView
} from '@capacitor-community/bluetooth-le'
//import UserProfile from "../components/UserProfile.vue";
import {
    useUserStore
} from '../stores/user'
import {
    useMqttStore
} from '../stores/mqtt'

export default defineComponent({
    name: 'BluetoothView',
    components: {
        IonList,
        IonCard,
        IonCardContent,
        IonCardTitle,
        IonCardSubtitle,
        IonItem,
        IonBadge,
        IonIcon,
        IonText,
        IonLabel,
        IonContent,
        IonPage,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonButtons
    },
    setup() {
        const userStore = useUserStore()
        const mqttStore = useMqttStore()

        return {
            userStore,
            mqttStore
        }
    },

    data() {
        return {
            scanResult: {},
            measurements: [],
            connectedToDevice: false,
            receiving: false,
            sending: false,
            status: '',
            deviceName: '',
            heartRate: 0,
            received: 0,
            sent: 0
        }
    },
    methods: {
        async scan() {
            try {
                await BleClient.initialize();

                await BleClient.requestLEScan({},
                    (result) => {
                        this.scanResult = result
                        //{"device":{"name":"P8","deviceId":"BB84AA72-8984-61DA-58BB-C912943525D7"
                    }
                );

                setTimeout(async () => {
                    await BleClient.stopLEScan();
                    console.log('stopped scanning');
                }, 5000);
            } catch (error) {
                console.error(error);
            }
        },
        async initBlueTooth() {
            await BleClient.initialize();
            const device = await BleClient.requestDevice({
                name: 'P8'
            });
            await BleClient.connect(device.deviceId, (deviceId) => this.onDisconnect(deviceId));
            console.log('connected to device', device);
            this.connectedToDevice = true;
            this.deviceName = String(device.name);


            /* The APP write 0xD0 command to the watch via the UUID F0080003, and the parameter configuration = 0x01.
                            Full command: 0xD001
                            service:F0080001-0451-4000-B000-000000000000
                            write characteristic:F0080003-0451-4000-B000-000000000000 
                            value:0xD001*/
                            // Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
                            //octets = Math.floor(hex.replace(/^0x/, '').length / 2),
                            //dataView = new DataView(new ArrayBuffer(octets));
            let srv = 'f0080001-0451-4000-b000-000000000000'
            let char = 'f0080003-0451-4000-b000-000000000000'
            //password 0xA1
             await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xA1]))
            console.log('password command 0xA100 sent')
             //await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xA1,0x00,0x01]))
             //console.log('password command 0xA101 sent')
            // await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xA1,0x00,0x02]))
            // console.log('password command 0xA102 sent')
            
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xB8,0x02])) 
            console.log('switch command 0xB801 sent')
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xA7,0x00])) 
            console.log('functions command 0xA701 sent')
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xA6,0x00])) 
            console.log('watch time command 0xA600 sent')
            //bp 0x40 = 64
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0x40, 0x01]))
            console.log('blood pressure command 0x4001 sent')
            //HR 0xD0 = 208
           await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xD0, 0x01]))
           console.log('heart rate command 0xD001 sent')
            //BATTERY 0xA0 = 160
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0xA0, 0x00]))
            console.log('battery status command 0xA000 sent')
            //Ox2 0x30 = 48
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0x30,0x00]))
            console.log('blood oxygen command 0x3001 sent')
            //temp 0x33 = 51
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0x33, 0x01,0x01]))
            console.log('temp')
            
            //ecg 0x93 = 147
           // await BleClient.write(device.deviceId, srv, char, numbersToDataView([0x93, 0x01,0x01]))
            // console.log('ECG command 0x9301 sent')
            //daily 0x60 = 96
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0x60, 0x00]))
             console.log('daily')
            //sleep 0x61 = 97
            await BleClient.write(device.deviceId, srv, char, numbersToDataView([0x61, 0x00]))
                console.log('sleep details today command 0x6100 sent')
               /*  .then(console.log('BleWrite was successful'))
            .catch(error => console.log('BleWrite fail error: ', error))  */

            let srv2 ='f0080001-0451-4000-b000-000000000000'
            let char2 = 'f0080002-0451-4000-b000-000000000000'
                    //console.log('is P8 nofify for measurements',char)
                        //service:F0080001-0451-4000-B000-000000000000
                        //characteristic:F0080002-0451-4000-B000-000000000000
                        //value:0xd04f000000000000000000000000000000000000
                        //Where 4f is the measured heart rate value

                                await BleClient.startNotifications(
                                    device.deviceId,
                                    srv2,
                                    char2,
                                    (value) => {

                                        console.log('measurement received',char2)
                                          console.log(`value:${value}`); 
                                         let m = this.parseValue(value)
                                        //console.log(`parsedvalue:${parseVal}`);
                                        if (this.received == 0) {
                                            this.receiving = true;
                                        }
                                        this.received = this.received + 1;
                                        
                                       
                                        if(m.command == 208) {
                                            m['topic'] = 'heartrate'
                                            m['heartRate'] = m['v1']
                                        }
                                        if(m.command == 64) {
                                            m['topic'] = 'bloodpressure'
                                            m['bpHigh'] = m['v1']
                                            m['bpLow'] = m['v2']
                                        }
                                        if(m.command == 48) {
                                            m['topic'] = 'bloodoxygen'
                                            m['spO2'] = m['v1']
                                        }
                                        
                                        m['userId'] = this.userStore.userId
                                        m['timestamp'] = Date.now()
                                        
                                         let topic = m['topic']
                                    if(m.command == 208 ||
                                        m.command == 48 ||
                                        m.command == 64){
                                             console.log('publish',m)
                                            this.publishBleToMqtt(topic,JSON.stringify(m))
                                        }
                                    
                                        
                                    }
                                )
                                .then(console.log('BleNotify was successful'))
                                .catch(error => console.log('BleNotify fail error: ', error))  

            const services = await BleClient.getServices(device.deviceId)
            
            for (const service of services) {

               
                let srv = service.uuid;
               
                let characteristics = service.characteristics;
                for (const characteristic of characteristics) {
                    
                   // let char = characteristic.uuid;
                    /* if (characteristic.descriptors.length > 0) {
                        await BleClient.readDescriptor(
                            device.deviceId,
                            srv,
                            char,
                            characteristic.descriptors[0].uuid
                        )
                         .then(resp => {
                            console.log('resp', resp)
                        })
                    }  */

                   
                    const m = {
                        serviceUuid: srv,
                        measurementUuid: characteristic.uuid,
                        read: characteristic.properties.read,
                        extendedProperties: characteristic.properties.extendedProperties,
                        authenticatedSignedWrites: characteristic.properties.authenticatedSignedWrites,
                        broadcast: characteristic.properties.broadcast,
                        notify: characteristic.properties.notify,
                        notifyEncryptionRequired: characteristic.properties.notifyEncryptionRequired,
                        writeWithoutResponse: characteristic.properties.writeWithoutResponse,
                        indicate: characteristic.properties.indicate,
                        indicateEncryptionRequired: characteristic.properties.indicate,
                        write: characteristic.properties.write
                    }

                    this.measurements.push(m)
                   
                    /* if (characteristic.properties.write) {
                        
                        if(srv == 'f0080001-0451-4000-b000-000000000000'){
                            if(char == 'f0080003-0451-4000-b000-000000000000'){
                            
                            console.log('write heartrate start',char)
                            
                            let hex = '0xD001';
                             console.log('hex',hex)
                            let octets = Math.floor(hex.replace(/^0x/, '').length / 2)
                          
                            let dataView = new DataView(new ArrayBuffer(octets))
                             console.log('write dataView.buffer',dataView.buffer)
                            await BleClient.write(device.deviceId, srv, char, dataView)
                                 .then(console.log('BleWrite was successful'))
                                .catch(error => console.log('BleWrite fail error: ', error)) 
                            
                        }
                        

                        }
                        
                    } */
                           

                    /* if (characteristic.properties.read) {
                        let readCharacteristic = await BleClient.read(device.deviceId, srv, char);
                        console.log(`READ characteristic ${char}:${readCharacteristic.getUint8(0)}`);

                    } */
                    /* if (characteristic.properties.notify) {
                       
                        if(srv == 'f0080001-0451-4000-b000-000000000000'){
                            if(char == 'f0080002-0451-4000-b000-000000000000'){
                                console.log('is P8 nofify for measurements',char)
                        //service:F0080001-0451-4000-B000-000000000000
                        //characteristic:F0080002-0451-4000-B000-000000000000
                        //value:0xd04f000000000000000000000000000000000000
                        //Where 4f is the measured heart rate value

                        

                                await BleClient.startNotifications(
                                    device.deviceId,
                                    srv,
                                    char,
                                    (value) => {

                                            function buf2hex(buffer) { // buffer is an ArrayBuffer
                                            return [...new Uint8Array(buffer)]
                                                .map(x => x.toString(16).padStart(2, '0'))
                                                .join('');
                                            }

                                        let hex = buf2hex(value.buffer)
                                        console.log('buf2hex',hex)

                                        console.log('measurement received',char)
                                         console.log(`value:${value}`);
                                        let parseVal = this.parseValue(value)
                                        console.log(`parsedvalue:${parseVal}`);
                                        if (this.received == 0) {
                                            this.receiving = true;
                                        }
                                        this.received = this.received + 1;
                                        let m = {
                                             key: char,
                                            value: parseVal
                                        }
                                        console.log('publish',m)
                                        //this.publishBleToMqtt(JSON.stringify(m))
                                    }
                                )
                                .then(console.log('BleNotify was successful'))
                                .catch(error => console.log('BleNotify fail error: ', error)) 
                                
                             }
                        }
                    } */
                }
            } 
           // console.log(this.measurements)
        },
        parseValue(value) {
            const b0 = value.getUint8(0)
            const b1 = value.getUint8(1)
            const b2 = value.getUint8(2)
            const b3 = value.getUint8(3)
            /* const b4 = value.getUint8(4)
            const b5 = value.getUint8(5)
            const b6 = value.getUint8(6)
            const b7 = value.getUint8(7)
            const b8 = value.getUint8(8)
            const b9 = value.getUint8(9)
            const b10 = value.getUint8(10)
            const b11 = value.getUint8(11)
            const b12 = value.getUint8(12)
            const b13 = value.getUint8(13)
            const b14 = value.getUint8(14)
            const b15 = value.getUint8(15)
            const b16 = value.getUint8(16)
            const b17 = value.getUint8(17)
            const b18 = value.getUint8(18)
            const b19 = value.getUint8(19) */

            let command = b0
            let v1 = null;
            let v2 = null;
            //heartrate
            if(command == 208) {
                v1 = b1;
            }
            //bp

            if(command == 64) {
                v1 = b1;
                v2 = b2;
            }
            //spo2
            if(command == 48) {
                v1 = b3;
            }
            /* const flags = value.getUint8(0);
           
            const rate16Bits = flags & 0x1;
            
            let val;
            if (rate16Bits > 0) {
                val = value.getUint16(1, true);
                
            } else {
                val = value.getUint8(1);
                 
                 
            } */
            let res = {
                command: command,
                v1: v1,
                v2: v2

            }
            return res;
        },
        onDisconnect(deviceId) {
            console.log(`device ${deviceId} disconnected`);
            this.connectedToDevice = false;
        },
        publishBleToMqtt(topic,message) {
            if (this.sent == 0) {
                this.sending = true;
            }
            this.sent = this.sent + 1;
            const topicUser = `${topic}/${this.userStore.userId}`
            this.mqttStore.publishMqtt(topicUser, message)
            console.log('publish', topicUser + ':' + message)
        }

    }
});
</script>
