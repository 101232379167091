<template>
  <div>
    <ion-list
      :style="
        this.$props.heading == 'Failure'
          ? 'successModalIonListFailure'
          : 'successModalIonListSucess'
      "
      lines="none"
    >
      <ion-list-header
        style="text-align: center"
        :class="
          this.$props.heading == 'Failure'
            ? 'successModalIonListFailure'
            : 'successModalIonListSucess'
        "
      >
        <ion-label style="margin-top: 5px" class="success-heading"
          >{{ this.$props.heading }}!!</ion-label
        >
      </ion-list-header>
      <ion-item lines="none" class="successModalIonItem">
        <ion-label style="white-space: inherit" class="success-text">
          {{ this.$props?.content }}
        </ion-label>
      </ion-item>
      <ion-item lines="none" class="successModalIonItem">
        <div class="center-button">
          <ion-button id="doneButton" @click="dismiss" class="successButton"
            >Done</ion-button
          >
        </div>
      </ion-item>
    </ion-list>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { IonButton, IonList, IonItem, IonLabel } from "@ionic/vue";

export default defineComponent({
  name: "successModal",
  components: {
    IonButton,
    IonList,
    IonItem,
    IonLabel,
  },
  props: {
    heading: String,
    content: String,
  },
  mounted() {
    // console.log('heading : ' + this.$props.heading)
  },

  methods: {
    dismiss() {
      const modal = document.querySelector("#successModal");
      if (modal !== null) modal.dismiss();
    },
  },
  setup() {},
});
</script>
<style>
.successButton {
  font-family: "OpenSansRegular";
  font-size: 15px;
  font-weight: 600;
  width: 100px;
  height: 30px;
  letter-spacing: 0.5px;
}
.center-button {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

}
.success-heading {
  font-family: "OpenSansRegular";
  font-size: 20px;
  font-weight: 600;
}
.success-text {
  font-family: "OpenSansRegular";
  font-size: 17px;
  font-weight: 400;
}
</style>
