<template>
  <ion-page>
    <ion-content>
      <boxDescription></boxDescription>
    </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import { IonPage, IonContent } from "@ionic/vue";
import boxDescription from "../../components/rpmBasicBoxDescription.vue";

export default defineComponent({
  name: "RpmBoxDescriptionView",
  components: { IonContent, IonPage, boxDescription },
  setup() {
    return { boxDescription };
  },
});
</script>
