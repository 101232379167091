<template>
  <ion-card style="margin-top: 0px" class="card-content">
    <ion-card-content
      style="
        padding-right: 0px;
        border-left-width: 10px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
      "
      class="content"
    >
      <ion-grid>
        <ion-row>
          <ion-col size="9">
            <ion-item
              lines="none"
              class="ion-no-padding"
              @click="showPatientInfo(this.data)"
            >
              <ion-avatar slot="start">
                <img :src="this.data?.PICTURE" />
              </ion-avatar>

              <ion-Label class="PatientName">
                {{ this.data?.NICKNAME }}
              </ion-Label>
            </ion-item>
          </ion-col>
          <ion-col size="3">
            <ion-Label class="PatientIconText">
              <ion-icon
                v-if="this.data?.RISKSCORE > 2"
                :icon="alertCircle"
                color="danger"
              >
              </ion-icon>
              {{ this.data?.RISKSCORE > 2 ? "High" : "" }}
            </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row style="width: 100%">
          <ion-col size="4">
            <ion-Label class="PatientDOBLabel"> Risk Score: </ion-Label>
          </ion-col>
          <ion-col size="8">
            <ion-Label class="PatientDOB">
              {{ this.data?.RISKSCORE }}
            </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row style="width: 100%">
          <ion-col size="4">
            <ion-Label class="PatientDOBLabel"> Risk Level: </ion-Label>
          </ion-col>
          <ion-col size="8">
            <ion-Label class="PatientDOB">
              {{ this.data?.RISKLEVEL }}
            </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4">
            <ion-Label class="PatientStatusLabel"> Status: </ion-Label>
          </ion-col>
          <ion-col size="8">
            <ion-Label class="PatientStatus">
              {{ this.data?.UTTERANCE }}
            </ion-Label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col
            
            style="text-align: right;padding: 8px;"
            v-if="this.buttonText == 'Start'"
          >
            <ion-icon :icon="statsChart" 
            @click="openPatientVitalModal()"
            class="ionIcon">
            </ion-icon>
          </ion-col>
          <ion-col
            
            class="column"
            v-if="this.buttonText == 'Start'"
          >
            <ion-icon
              :icon="informationCircle"
              @click="showPatientInfo(this.data)"
              class="ionIcon">
            >
            </ion-icon>
          </ion-col>
          <ion-col style="float: right; text-align: right">
            <ion-button
              style="text-transform: none"
              class="notification"
              @click="acceptReviewMessage($event, data)"
              >{{ this.buttonText }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { addIcons } from "ionicons";

import { defineComponent, defineEmits } from "vue";
import {
  ellipsisHorizontalCircleOutline,
  alertCircle,
  informationCircle,
  statsChart,
} from "ionicons/icons";
import { useUserStore } from "../stores/user";
import { useMqttStore } from "../stores/mqtt";
import { useIonRouter } from "@ionic/vue";
import digitalTriageAssistant from "../components/DigitalTriageAssistant.vue";
import patientVitalsModalPopup from "../components/patientVitalsModalPopup.vue";
//import  close-circle  from '@icon/ionicons';
import {
  // IonPage,
  // IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonLabel,
  IonItem,
  IonAvatar,
  IonButton,
  modalController,
  //ionRouter
  IonIcon,
} from "@ionic/vue";

export default defineComponent({
  name: "rpmStaffReview",
  emits: defineEmits(["close"]),
  components: {
    // IonPage,
    // IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonCard,
    IonCardContent,
    IonItem,
    IonAvatar,
    IonButton,
    //modalController,
    //ionRouter
    IonIcon,
  },
  created() {
    addIcons({
      // "ellipsis-horizontal-outline": ellipsisHorizontalOutline
    });
  },
  props: {
    data: Object,
    buttonText: String,
    keyValue: String,
  },
  mounted() {
    //console.log('this.props.data' + JSON.stringify(this.props.data));
  },
  methods: {
    async openPatientVitalModal() {
      //console.log('Show patient Vitals' + JSON.stringify(selectedChat));
      const modal = await modalController.create({
        id: "patientVitalModal",
        component: patientVitalsModalPopup,
        cssClass: "modalPatientVital",
        componentProps:{
            selectedPatient: this.data
      },
      });
      modal.present();

      modal.onDidDismiss().then((data) => {
        var d = JSON.stringify(data);
        console.log("Dismiss Data " + d);
        return;
      });
    },
    showPatientInfo(e) {
      this.ionRouter.push("/patientDetail/" + e.USER_ID + "/Patient");
    },

    acceptReviewMessage(e, m) {
      let user = this.userStore.user;
      let userId = this.userStore.userId;
      let u = {
        STAFFUSERID: userId,
        NAME: user.name,
        NICKNAME: user.nickname,
        PICTURE: user.picture,
        EMAIL: user.email,
      };

      if (e.target.innerText == "Accept") {
        m["ASSIGNEDSTAFF"] = this.userStore.userId;
        m["STAFFUSER"] = u;
        m["ASSIGNEDDATE"] = Date.now();
        m.buttonText = "Start";
        m.PatientIconText = "";
        m.Showdetails = false;
        let message = JSON.stringify(m);
        //console.log('accept user', m);

        //let topic = `digitaltriage_mypatients/${this.userStore.userId}`;
        //this.mqttStore.publishMqtt(topic, message);
        //mutate alert to add new event with assignedStaff
        let topicAlert = `digitaltriage_alerts/${m.USERID}`;
        this.mqttStore.publishMqtt(topicAlert, message);

        //console.log('publish alert',topicAlert, m);
        //console.log('Key :' +  this.keyValue);
        this.$emit("clearMessage", this.keyValue, m);

        /* let my = {
          userIdKey: this.userStore.userId,
          userId: this.userStore.userId,
          patientUserId: m.USER_ID
        }
        console.log('my',my)
        let topicMy = `mypatients/${this.userStore.userId}`;
        this.mqttStore.publishMqtt(topicMy, JSON.stringify(my)); */

        // subscribe to patientstaff when staff member clicks on Accept

        /* let userStaff = {         
          staffUserId: this.userStore.userId,
          userId: m.USER_ID,
          userIdKey: m.USER_ID
        }; */
        // console.log('userStaff',userStaff)
        // let topicStaff = `patientstaff/${m.USER_ID}`;
        //console.log('topic staff ' + topicStaff);
        // this.mqttStore.publishMqtt(topicStaff, JSON.stringify(userStaff));
      } else if (e.target.innerText == "Start") {
        //alert('Start button clicked');
        this.lexbotData = "";
        this.lexbotData = m;
        m.lexSessionId = `${m.userId}${this.keyValue}`;
        this.selectedMessage = null;
        this.selectedMessage = m;
        this.mqttStore.subscribeToPatientData(m.userId);
        //this.mqttStore.subscribeToPatientChat(m.userId)
        this.openModal();
        //this.ionRouter.push("/lexbot", "");
      }

      //this.data.splice(this.keyValue,1)
    },
  },
  setup() {
    const userStore = useUserStore();
    const mqttStore = useMqttStore();
    const ionRouter = useIonRouter();

    // console.log('reviewQueue Setup ', JSON.stringify(props));
    function closeModal() {
      console.log(this);
    }

    return {
      statsChart,
      closeModal,
      close,
      // action,
      patientVitalsModalPopup,
      addIcons,
      informationCircle,
      ellipsisHorizontalCircleOutline,
      alertCircle,
      userStore,
      mqttStore,
      ionRouter,
      async openModal() {
        const modal = await modalController.create({
          id: "DigitalModal",
          component: digitalTriageAssistant,
          cssClass: "modalDigital",
          componentProps: {
            message: this.selectedMessage,
          },
        });
        modal.present();

        modal.onDidDismiss().then((data) => {
          console.log("Dismiss Data " + data);
          return;
        });
      },
    };
  },
  data() {
    return {
      selectedMessage: null,
      lexSessionId: null,
    };
  },
});
</script>
<style scoped>
.PatientIconText {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: 1px;
}

.PatientStatus {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* or 18px */

  display: flex;
  align-items: center;
  letter-spacing: 1px;

  /* Main heading */
}

.PatientStatusLabel {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* or 18px */

  display: flex;
  align-items: center;
  /* letter-spacing: 1px; */

  /* Main heading */
}

.PatientDOBLabel {
  height: 16.96px;

  font-family: "OpenSansRegular";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  /* or 18px */

  display: flex;
  align-items: center;
  /* letter-spacing: 1px; */

  /* Main heading */
}
.PatientDOB {
  height: 16.96px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* or 18px */

  display: flex;
  align-items: center;
  letter-spacing: 1px;

  /* Main heading */
}
.PatientName {
  height: 21.67px;

  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* or 23px */

  /* display: flex; */
  align-items: center;
  letter-spacing: 1px;

  /* Main heading */
}
ion-button {
  --background: #0dba61;
  --color: white;
  font-family: "OpenSansRegular";
  font-style: normal;
  height: 30px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 1px;
  border-radius: 10px;
}

.close-btn {
  float: right;
  /* padding-top: 0px;
  top: -11px; */
}

.imgClass {
  max-width: 100%;
  /* border: 0; */
  width: 25px;
  height: 25px;
}

.content {
  box-sizing: border-box;
  border: 2px solid #f7c788;
  border-radius: 4px;
}
.ionIcon
{
    width: 25px;
    height: 25px;
    padding-bottom: 0px;
    vertical-align: -webkit-baseline-middle;
}
.column
{
    text-align: right;
    padding-top: 10px;
    padding-right: 35px;
    padding-bottom: 0px;
    padding-left: 0px;
}
</style>
