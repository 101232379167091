import { defineStore, acceptHMRUpdate } from "pinia";
import { useUserStore } from "../stores/user";
import { useKsqlStore } from "../stores/KSQL";
import { DateTime } from "luxon";
import mqtt from "mqtt/dist/mqtt";

export const useMqttStore = defineStore({
  id: "mqtt",
  state: () => ({
    mqttClient: null,
    mqttStatus: "mqtt disconnected",
    subscribeToUserId: null,
    patientMetrics: null,
    options7: {
      properties: {
        userProperties: {
          kafkaTopicStartTimestamp: `AVGPATIENT_7DAYS:${DateTime.now()
            .minus({ days: 7 })
            .toMillis()}`,
        },
      },
    },

    options30: {
      properties: {
        userProperties: {
          kafkaTopicStartTimestamp: `AVGPATIENT_30DAYS:${DateTime.now()
            .minus({ days: 30 })
            .toMillis()}`,
        },
      },
    },
    messages: {
      users: [],
      patientData: {
        t: [],
        hr: [],
        rr: [],
        bp: [],
        spo2: [],
      },
      patientData7: {
        t: [],
        hr: [],
        rr: [],
        bp: [],
        spo2: [],
      },
      patientData30: {
        t: [],
        hr: [],
        rr: [],
        bp: [],
        spo2: [],
      },
      chat: [],
      views: {
        thirty: {
          t: [],
          hr: [],
          rr: [],
          bp: [],
          spo2: [],
        },
      },
      commands: {
        digitalTriage: {
          action: [],
        },
      },
      events: {
        ui: {
          patient: {
            skintone: [],
            checkin: [],
            patientstaff: [],
            schedule: [],
          },
          staff: {
            mypatients: [],
            staffPatients: [],
            schedule: [],
          },
        },
        digitalTriage: {
          alert: [],
        },
      },
      notifications: [],
    },
  }),
  actions: {
    connectMqtt() {
      const clientIdUnique = Math.random().toString(16).substring(2, 10);
      const userStore = useUserStore();

      const options = {
        clientId: `com.rpmone.app-${userStore.userId}-${clientIdUnique}`,
        clean: true,
        keepalive: 60,
        username: process.env.VUE_APP_WATERSTREAM_USERNAME,
        password: userStore.userClaims.__raw,
        protocolId: "MQTT",
        protocolVersion: 5,
      };

      this.mqttClient = mqtt.connect(
        process.env.VUE_APP_WATERSTREAM_URL,
        options
      );

      this.mqttClient.on("connect", () => {
        this.setupMqtt();
      });

      this.mqttClient.on("reconnect", function () {
        console.log("reconnected");
      });
      this.mqttClient.on("disconnect", function () {
        console.log("disconnected");
      });

      this.mqttClient.on("error", function (a) {
        console.log("error!" + a);
      });

      this.mqttClient.on("offline", function (a) {
        console.log("lost connection!" + a);
      });

      this.mqttClient.on("close", function (a) {
        console.log("connection closed!" + a);
      });

      this.mqttClient.on("message", (topic, message) => {
        let m = JSON.parse(message.toString());
        let arr = topic.split("/");
        let t = arr[0];
        switch (t) {
          case "avgpatient":
            this.messageToAvgpatient(m);
            break;
          case "avgpatient_7days":
            this.messageToAvgpatient7(m);
            break;
          case "avgpatient_30days":
            this.messageToAvgpatient30(m);
            break;
          case "users_table":
            this.messageToUsers(m);
            break;
          case "notifications":
            this.messageToNotifications(m);
            break;
          case "chat":
            this.messageToChat(m);
            break;
          case "digitaltriage_actions":
            this.messageToActions(m);
            break;
          case "digitaltriage_alerts_unassigned":
            this.messageToAlerts(m);
            break;
          case "patient_skintone":
            this.messageToPatientSkintone(m);
            break;
          case "staffpatients_pull":
            this.messageToStaffPatientsPull(m);
            break;
          default:
            console.log("topic not found", topic);
        }
      });
    },
    async subscribeMqtt(topic, options) {
      console.log("subscribing to", topic);
      await this.mqttClient.subscribe(topic, options, function (err, granted) {
        if (!err) {
          console.log("subscribe granted", granted);
        } else {
          console.log("subscribe error", err);
        }
      });
    },
    unsubscribeMqtt(topic, options) {
      console.log("unsubscribing from", topic);
      this.mqttClient.unsubscribe(topic, options, function (err) {
        if (!err) {
          console.log("unsubscribe success");
        } else {
          console.log("unsubscribe error", err);
        }
      });
    },
    publishMqtt(topic, message) {
      console.log("publishing to", topic, message);
      let options = {
        qos: 1,
      };
      this.mqttClient.publish(topic, message, options, (err) => {
        if (err) {
          console.log(err);
        }
      });
    },
    publishUser() {
      const userStore = useUserStore();

      let u = userStore.user;

      let m = {
        role: u["com.rpmone.app/roles"][0],
        userId: u["com.rpmone.app/userId"],
        email: u.email,
        email_verified: u.email_verified,
        name: u.name,
        nickname: u.nickname,
        picture: u.picture,
        sub: u.sub,
        updated_at: u.updated_at,
      };
      let topic = `users/${m.userId}`;
      this.publishMqtt(topic, JSON.stringify(m));
    },
    setupMqtt() {
      const userStore = useUserStore();
      const ksqlStore = useKsqlStore();
      if (userStore.role == "patient") {
        this.messages.notifications = [];
        this.subscribeToPatientData(userStore.userId);
        this.subscribeToPatientChat(userStore.userId, 7);
        this.subscribeToPatientSkintone(userStore.userId);
        //this.subscribeToPatientCheckin(userStore.userId)
       // this.subscribeToNotifications(userStore.userId);
        ksqlStore.pullOpenNotifications(userStore.userId);
        //some pull queries require use of KSQL REST API
        ksqlStore.pullUser(userStore.userId);
        ksqlStore.pullSchedulePatient(userStore.userId);
        ksqlStore.pullAlertsPatient(userStore.userId);
      } else {
        this.subscribeToNotifications(userStore.userId);
        ksqlStore.pullAlerts();
        ksqlStore.pullActions(userStore.userId);
        ksqlStore.pullAlertsAssigned(userStore.userId);
        ksqlStore.pullOpenNotifications(userStore.userId);
        //some pull queries require use of KSQL REST API
        ksqlStore.pullUser(userStore.userId);
        ksqlStore.pullScheduleStaff(userStore.userId);
      }

      this.publishUser();
    },
    subscribeToPatientData(patientUserId) {
      //clear any patient data
      this.clearPatientDataLocally();

      this.subscribeToUserId = patientUserId;

      let topic = `avgpatient/${patientUserId}`;
      let topic7 = `avgpatient_7days/${patientUserId}`;
      let topic30 = `avgpatient_30days/${patientUserId}`;

      let ts7 = DateTime.now().minus({ days: 7 }).toMillis();
      let options7 = {
        qos: 0,

        properties: {
          userProperties: {
            kafkaTopicStartTimestamp: `AVGPATIENT_7DAYS:${ts7}`,
          },
        },
      };

      let ts30 = DateTime.now().minus({ days: 30 }).toMillis();
      let options30 = {
        qos: 0,
        properties: {
          userProperties: {
            kafkaTopicStartTimestamp: `AVGPATIENT_30DAYS:${ts30}`,
          },
        },
      };
      this.subscribeMqtt([topic], { qos: 0 });
      this.subscribeMqtt([topic7], options7);
      this.subscribeMqtt([topic30], options30);
    },
    clearPatientDataLocally() {
      this.patientMetrics = {
        USER_ID: null,
        AVGTEMP: 0,
        AVGHEARTRATE: 0,
        AVGRESPRATE: 0,
        AVGBP: 0,
        AVGSPO2: 0,
        MAXTIMESTAMP: null,
      };
      this.messages.patientData.t = [];
      (this.messages.patientData.hr = []),
        (this.messages.patientData.rr = []),
        (this.messages.patientData.bp = []),
        (this.messages.patientData.spo2 = []);

      this.messages.patientData7.t = [];
      (this.messages.patientData7.hr = []),
        (this.messages.patientData7.rr = []),
        (this.messages.patientData7.bp = []),
        (this.messages.patientData7.spo2 = []);

      this.messages.patientData30.t = [];
      (this.messages.patientData30.hr = []),
        (this.messages.patientData30.rr = []),
        (this.messages.patientData30.bp = []),
        (this.messages.patientData30.spo2 = []);
    },
    messageToAvgpatient(m) {
      this.patientMetrics = {
        USER_ID: m.USER_ID,
        AVGTEMP: parseInt(m.AVGTEMP),
        AVGHEARTRATE: parseInt(m.AVGHEARTRATE),
        AVGRESPRATE: parseInt(m.AVGRESPRATE),
        AVGBP: parseInt(m.AVGBP),
        AVGSPO2: parseInt(m.AVGSPO2),
        MAXTIMESTAMP: new Date(m.MAXTIMESTAMP),
      };
      console.log("metrics", this.patientMetrics);
      //update temp
      let ts = new Date(m.MAXTIMESTAMP);
      let t = {
        x: ts,
        y: this.patientMetrics.AVGTEMP,
      };
      this.messages.patientData.t.push(t);
      //update heartrate
      let hr = {
        x: ts,
        y: this.patientMetrics.AVGHEARTRATE,
      };
      this.messages.patientData.hr.push(hr);
      //update resprate
      let rr = {
        x: ts,
        y: this.patientMetrics.AVGRESPRATE,
      };
      this.messages.patientData.rr.push(rr);
      //update bp
      let bp = {
        x: ts,
        y: this.patientMetrics.AVGBP,
      };
      this.messages.patientData.bp.push(bp);
      //update spo2
      let spo2 = {
        x: ts,
        y: this.patientMetrics.AVGSPO2,
      };
      this.messages.patientData.spo2.push(spo2);
    },
    messageToAvgpatient7(m) {
      let ts = new Date(m.MAXTIMESTAMP);
      let t = {
        x: ts,
        y: parseInt(m.AVGTEMP),
      };
      this.messages.patientData7.t.push(t);
      //update heartrate
      let hr = {
        x: ts,
        y: parseInt(m.AVGHEARTRATE),
      };
      this.messages.patientData7.hr.push(hr);
      //update resprate
      let rr = {
        x: ts,
        y: parseInt(m.AVGRESPRATE),
      };
      this.messages.patientData7.rr.push(rr);
      //update bp
      let bp = {
        x: ts,
        y: parseInt(m.AVGBP),
      };
      this.messages.patientData7.bp.push(bp);
      //update spo2
      let spo2 = {
        x: ts,
        y: parseInt(m.AVGSPO2),
      };
      this.messages.patientData7.spo2.push(spo2);
      console.log("Avgpatient7", m);
    },
    messageToAvgpatient30(m) {
      console.log("Avgpatient30", m);
      let ts = new Date(m.MAXTIMESTAMP);
      let t = {
        x: ts,
        y: parseInt(m.AVGTEMP),
      };
      this.messages.patientData30.t.push(t);
      //update heartrate
      let hr = {
        x: ts,
        y: parseInt(m.AVGHEARTRATE),
      };
      this.messages.patientData30.hr.push(hr);
      //update resprate
      let rr = {
        x: ts,
        y: parseInt(m.AVGRESPRATE),
      };
      this.messages.patientData30.rr.push(rr);
      //update bp
      let bp = {
        x: ts,
        y: parseInt(m.AVGBP),
      };
      this.messages.patientData30.bp.push(bp);
      //update spo2
      let spo2 = {
        x: ts,
        y: parseInt(m.AVGSPO2),
      };
      this.messages.patientData30.spo2.push(spo2);
    },
    resetPatientData(numberToRemove) {
      this.messages.patientData.t = this.messages.patientData.t.slice(
        this.messages.patientData.t.length - numberToRemove,
        this.messages.patientData.t.length
      );
    },
    subscribeToUsers(userId) {
      let topic = `users_table/${userId}`;
      this.subscribeMqtt([topic], { qos: 1 });
    },
    messageToUsers(m) {
      this.messages.users.push(m);
    },
    subscribeToSchedulePatient(userId) {
      let topic = `schedule_patient/${userId}`;
      this.subscribeMqtt([topic], { qos: 1 });
    },
    messageToSchedulePatient(m) {
      this.messages.events.ui.staff.mySchedules.unshift(m);
    },

    subscribeToScheduleStaff(userId) {
      let topic = `schedule_staff/${userId}`;
      this.subscribeMqtt([topic], { qos: 1 });
    },
    messageToScheduleStaff(m) {
      this.messages.events.ui.patient.mySchedules.unshift(m);
    },

    subscribeToPatientChat(userId, rewindDays) {
      let topic = `chat/${userId}`;

      let ts = DateTime.now().minus({ days: rewindDays }).toMillis();
      let options = {
        qos: 1,
        properties: {
          userProperties: {
            kafkaTopicStartTimestamp: `chat:${ts}`,
          },
        },
      };

      this.subscribeMqtt([topic], options);
      this.messages.chat = [];
    },
    messageToChat(m) {
      this.messages.chat.push(m);
    },
    subscribeToNotifications(userId) {
      let topic = `notifications/${userId}`;
      let ts = DateTime.now().minus({ days: 0 }).toMillis();
      let options = {
        qos: 1,
        nl: false,
        rap: false,
        rh: 0,
        properties: {
          userProperties: {
            //kafkaTopicStartTimestamp: `notifications`,
            kafkaTopicStartTimestamp: `notification:${ts}`,
          },
        },
      };

      this.subscribeMqtt([topic], options);
    },
    messageToNotifications(m) {
      if (m.STATUS !== 'closed')
      this.messages.notifications.push(m);
    },
    subscribeToActions() {
      let topic = "digitaltriage_actions/#";
      let ts = DateTime.now().minus({ days: 7 }).toMillis();
      let options = {
        qos: 1,
        nl: false,
        rap: false,
        rh: 0,
        properties: {
          userProperties: {
            kafkaTopicStartTimestamp: `digitaltriage_actions:${ts}`,
          },
        },
      };

      this.subscribeMqtt([topic], options);
    },
    messageToActions(m) {
      this.messages.commands.digitalTriage.action.unshift(m);
    },
    subscribeToAlerts() {
      let topic = "digitaltriage_alerts_unassigned/#";

      let options = {
        qos: 1,
      };

      this.subscribeMqtt([topic], options);
    },
    messageToAlerts(m) {
      // console.log('message to alerts',m)
      this.messages.events.digitalTriage.alert.push(m);
    },

    subscribeToPatientSkintone(patientUserId) {
      let topic = `patient_skintone/${patientUserId}`;
      let options = {
        qos: 1,
      };
      this.subscribeMqtt([topic], options);
    },
    messageToPatientSkintone(m) {
      this.messages.events.ui.patient.skintone.push(m);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMqttStore, import.meta.hot));
}
