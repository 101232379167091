<template>
  <ion-page>
    <ion-content style="overflow:scroll">

  <ion-card style="margin:0px;padding:0px;background: black;">
    <ion-card-content class="span-class blur">
      <ion-label class="welcomeTo">Welcome to</ion-label>
      <ion-img
        class="rpm-image"
        src="assets//images//RPM-One_logo_Welcome.png"
      >
      </ion-img>
      <ion-label class="rpm-one">RPM-One</ion-label>
      <ion-label class="rpm-one-text"> {{ rpmOneText }}</ion-label>
    </ion-card-content>

      <ion-card-content class="rpm-div-second" style="padding-top: 0px; padding-top: 0px" :scroll-y="true"
    :scrollEvents="true">
      <ion-accordion-group ref="accordionGroup" text-wrap>
        <ion-accordion style="background-color: black"
          toggleIconSlot="start"
          v-for="(item, index) in rpmtext"
          :key="index"
          :value="item.value"
          toggleIcon="chevron-forward-outline"
        >
          <ion-item slot="header" class="ion-accordion-item" color="black">
            <ion-label text-wrap class="rpm-one-questions">{{
              item.text
            }}</ion-label>
          </ion-item>
          <div slot="content" class="rpm-one-answers" text-wrap>
            {{ item.content }}
          </div>
        </ion-accordion>
      </ion-accordion-group>
      <ion-card-content
        style="
          padding-top: 0px;
          padding-bottom: 0px;
          justify-content: center;
          display: flex;
        "
      >
        <ion-label class="rpm-ready-to-get-started">
          {{ rpmReadytogetStarted }}</ion-label
        >
      </ion-card-content>
      <ion-card-content style="">
        <router-link to="/skin" slot="button">
          <ion-button shape="round" expand="block">GET STARTED </ion-button>
        </router-link> 
      </ion-card-content>
    </ion-card-content>
  </ion-card>
  </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent } from "vue";
import {
   IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonCard,
 // IonIcon,
  IonButton,
  // IonGrid,
  // IonRow,
  // IonCol,
  IonCardContent,
  IonImg,
  IonItem,
  IonPage,
  IonContent
} from "@ionic/vue";
import { ref } from "vue";
import { addIcons } from "ionicons";
import { arrowBack, arrowForward, chevronForwardOutline } from "ionicons/icons";
import { useWelcomeStore } from "../../stores/welcome";

export default defineComponent({
  name: "WelcomeRpmOne",
  components: {
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonCard,
    //IonIcon,
    IonButton,
    // IonGrid,
    // IonRow,
    // IonCol,
    IonCardContent,
    IonImg,
    IonItem,
    IonPage,
    IonContent
  },
  created() {
    addIcons({
      "arrow-back": arrowBack,
      "chevron-forward-outline": chevronForwardOutline,
      forward: arrowForward,
    });
  },
  setup() {
    //const userStore = useUserStore();
    const welcomeStore = useWelcomeStore();
    const rpmtext = ref([
      {
        id: 0,
        icon: "forward",
        text: "Why am I enrolled in this program?",
        content: "You are enrolled because your healthcare provider has determined that you would benefit from continuous monitoring. You may refer to your signed Consent Form for additional information.",
        value: "first",
      },
      {
        id: 1,
        icon: "forward",
        text: 'What is "RPM"?',
        content: "RPM” stands for remote physiologic monitoring. Your health data will be securely collected and transmitted to a healthcare provider in another location for interpretation. ",
        value: "second",
      },
      {
        id: 2,
        icon: "forward",
        text: "What if I require additional assistance?",
        content: "You may select “Connect” within the app to contact customer support at any time. We are more than happy to answer any questions or concerns you may have. ",
        value: "third",
      },
    ]);

    const rpmOneText = ref(
      "RPM-One is dedicated to assisting you during all stages of your digital healthcare journey."
    );
    const rpmReadytogetStarted = ref("Ready to get started?");

    function setShowWelcome()
    {
      welcomeStore.setShowWelcome(false);
    }

    return { welcomeStore,rpmOneText, rpmtext, rpmReadytogetStarted, setShowWelcome };
  },
  mounted() {
 console.log('welcome loaded',this.welcomeStore.showWelcome)
  }
});
</script>
<style scoped>


.ion-accordion-item {
  --border-width: 0px 0px 0px 0px;
  color: white;
  background: black;
  /* backdrop-filter: blur(1px); */
}
.ion-accordion-toggle-icon {
  background-color: white;
  color: white;
}

.bg-transparent {
  background-color: transparent !important;
}
.rpm-one-thumbnail {
  background: transparent;
  /* backdrop-filter: blur(1px); */
}

.blur {
  position: relative;
  display: inline-block; /* make the div not 100% */
}
/* the 'blur' effect */
.blur:before {
  content: "";
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.blur img {
  display: block; /* remove bottom space */
}

.success {
  color: #20c933;
}

.rpm-one-padding {
  padding-bottom: 50px;
}
.welcomeTo {
  position: fixed;
  width: 250px;
  height: 43px;
  left: 84px;
  top: 35px;
  font-family: "OpenSansRegular", "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 150%;
  /* identical to box height, or 52px */

  text-align: center;
  letter-spacing: 1px;

  /* background */

  color: white;
}
.span-class {
  /* position: fixed; */
  width: 395px;
  height: 350px;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  background-image: url("../../../public/assets/images/rpm-one-background.png");
}
.rpm-image {
  position: fixed;
  width: 60px;
  height: 56.7px;
  left: 43px;
  top: 110px;
  /* background: transparent;
  backdrop-filter: blur(1px);
  background: url("../../public/assets/images/RPM-One_logo_Welcome.png"); */
}

.rpm-one {
  /* RPM-One */
  position: fixed;
  /*  width: 208px; */
  height: 59px;
  left: 133px;
  top: 110px;
  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 2px;

  /* background */

  color: #fefefe;
}

.rpm-one-text {
  position: fixed;
  width: 325px;
  height: 78px;
  left: 35px;
  top: 190px;

  font-family: "roboto-slabRegular";
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  white-space: pre-line;

  /* background */

  color: #fefefe;
}
.rpm-div-second {
  /* position: fixed; */
  width: 395px;
  left: 0px;
  /* top: 295px; */
  overflow: scroll;

  /* Main heading */
  background: black;
  height: 436px;
}

.rpm-one-questions-icon {
  color: white;
  font-weight: bold;
  padding-right: 8px;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  --ionicon-stroke-width: 64px;
  --ionicon-stroke-weight: 10px;
}

ion-icon {
  --ionicon-stroke-width: 66px;
}

.rpm-one-questions {
  font-family: "OpenSansRegular";
  font-style: normal;
  font-size: 18px;

  background-color: black;
  /* or 26px */

  letter-spacing: 1px;
  text-decoration-line: underline;

  /* background */

  color: white;
}

.rpm-one-answers {
  font-family: "OpenSansRegular";
  font-style: normal;
  background-color: black;
  letter-spacing: 1px;
  padding-left: 19%;
  /* background */
  color: white;
}

.rpm-ready-to-get-started {
  font-family: "OpenSansRegular";
  font-style: normal;
  align-content: center;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 1px;
  /* background */

  color: #fefefe;
}

ion-button {
  --background: #ef9116;
  --color: white;
  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  height: 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
</style>
