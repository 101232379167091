<template>
  <ion-page>
    <ion-button class="btn-complete" @click="completeCheckin">
      <ion-img
        slot="start"
        class="rpm-image"
        src="assets//images//RPM-One_logo_Welcome.png"
      >
      </ion-img>
      COMPLETE CHECK IN
    </ion-button>

    <LexBot ref="botRef" :message="message" :key="componentKey" @state="getState" />
  </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import LexBot from "@/components/LexBot.vue";
import { IonPage } from "@ionic/vue";
import { useUserStore } from "../../stores/user";

export default defineComponent({
  name: "CheckinBot",
  components: { IonPage, LexBot },
  setup() {
    const botRef = ref(null);
    const componentKey = ref(0);
    const userStore = useUserStore();
    const isSurveyCompleted = ref(false);
    return {
      userStore,
      botRef,
      componentKey,
      isSurveyCompleted,
    };
  },
  data() {
    return {
      message: null,
      showBot: false,
    };
  },
  mounted() {
    //console.log("Testing lexbox");
    //alert("Testing Lexbot");
  },
  created() {
    // alert("Created hook has been called");
    // console.log(`books is of type ${typeof this.books}`);
  },
  // beforeUpdate() {
  //   alert("beforeUpdate hook has been called");
  // },
  updated() {
    //alert("Updated hook has been called");
    this.forceRerender();
  },
  methods: {
    getState(value) {
      console.log(value);
      // alert(value);
      this.isSurveyCompleted = true;
    },
    forceRerender() {
      if (this.isSurveyCompleted) {
        this.componentKey += 1;
        this.isSurveyCompleted = false;
      }
    },
    clearMessages() {
      this.botRef.clearMessages();
    },
    completeCheckin() {
      this.showBot = true;
      this.clearMessages();
      const idUnique = Math.random().toString(16).substring(2, 10);
      let m = {
        lexSessionId: `${this.userStore.userId}${idUnique}`,
        UTTERANCE: `Check-in for ${this.userStore.user.nickname}`,
        USER_ID: this.userStore.userId,
        USERNAME: this.userStore.user.nickname,
        PICTURE: this.userStore.user.picture,
      };
      //uppercase is because digitaltriage passes upper to LexBot
      this.message = m;
      // console.log('survey bot call', this.message)
    },
  },
});
</script>
<style scoped>
.content {
  box-sizing: border-box;

  background: #fefefe;

  border: 2px solid #f7c788;
  border-radius: 4px;
}

.title {
  width: 175px;
  height: 32px;

  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 130%;
  /* or 32px */

  display: flex;
  align-items: center;
  letter-spacing: 1px;

  color: #000000;
}
.btn-complete {
  --background: #ef9116;
  --color: white;
  font-family: "roboto-slabRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  height: 50px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 15px;
  margin: 20px;
}

.rpm-image {
  position: fixed;
  left: 2%;
  width: 43px;
  height: 27px;
}
</style>
