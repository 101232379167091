<template>
  <ion-page>
    <ion-content>
      <ion-card style="margin: 0px">
        <ion-card-content>
          <ion-segment color="tertiary" value="primary">
            <ion-segment-button value="primary" @click="clickBasicTab">
              <ion-label>
                <ion-icon icon="gridout"></ion-icon>
              </ion-label>
            </ion-segment-button>
            <ion-segment-button value="segment" @click="clickLiveTab">
              <ion-label>
                <ion-icon icon="chart"></ion-icon>
              </ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-card-content>
      </ion-card>

      <!-- </ion-card-content>
    <ion-card-content v-if="isLive"> -->
      <div v-if="isLive">
        <ion-segment color="tertiary" value="today">
          <!-- <ion-segment-button value="lasthour" @click="chartSegmentClick">
          <ion-label>
            Last hour
          </ion-label>
           
        </ion-segment-button> -->
          <ion-segment-button value="today" @click="chartSegmentClick">
            <ion-label> Today </ion-label>
          </ion-segment-button>
          <ion-segment-button value="week" @click="chartSegmentClick">
            <ion-label> Week </ion-label>
          </ion-segment-button>
          <ion-segment-button value="month" @click="chartSegmentClick">
            <ion-label> Month </ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>

      <ion-grid> 
        <ion-row v-if="isBasic">
          <ion-col>
            <basic />
          </ion-col>
        </ion-row>
        <!-- <ion-row v-if="isLive && chartType == 'lasthour'">
            <ion-col>
               <live :chartType = "chartType" /> 
            </ion-col>
          </ion-row> -->
        <ion-row v-if="isLive && chartType == 'today'" style="padding-top:15%">
          <ion-col>
            <live :chartType="chartType" />
          </ion-col>
        </ion-row>
        <ion-row v-if="isLive && chartType == 'week'" style="padding-top:15%">
          <ion-col>
            <live :chartType="chartType" />
          </ion-col>
        </ion-row>
        <ion-row v-if="isLive && chartType == 'month'" style="padding-top:15%">
          <ion-col>
            <live :chartType="chartType" />
          </ion-col>
        </ion-row>

        <!-- <ion-row>
            <ion-col>
              <trend />
            </ion-col>
          </ion-row> -->
        <!-- <ion-row class="ion-grid-row">
            <ion-col>
              <live />
            </ion-col>
          </ion-row>
          <ion-row  class="ion-grid-row">
            <ion-col>
              <trend />
            </ion-col>
          </ion-row> -->
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent, ref } from "vue";
//import { browsers, stats-chart } from "ionicons/icons";
import { addIcons } from "ionicons";
import { browsers, statsChart, search, grid } from "ionicons/icons";
import {
  //   IonToolbar,
  //   IonButtons,
  //IonItem,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  //IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardContent,
  //   IonTabBar,
  //   IonTabButton,
  //   IonTabs,
  //   IonRouterOutlet,
  IonLabel,
  IonPage,
  IonContent,

  //IonBackdrop
} from "@ionic/vue";
import basic from "../../components/rpmMainBasic.vue";
import live from "../../components/rpmMainLive.vue";
// import trend from "../../components/rpmMainTrend.vue";
//import modalpopup from "../../components/rpmMainModalPopUp.vue";
import { useUserStore } from "../../stores/user";

export default defineComponent({
  name: "rpmPatientData",
  components: {
    IonSegment,
    IonSegmentButton,
    IonIcon,
    // IonItem,
    IonCard,
    IonCardContent,
    // IonToolbar,
    // IonButtons,
    //IonButton,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,

    basic,
    live,
    // trend,
    // IonTabBar,
    // IonTabButton,
    // IonTabs,
    // IonRouterOutlet,
    IonPage,
    IonContent,
  },
  created() {
    addIcons({
      chart: statsChart,
      browsers: browsers,
      search: search,
      gridout: grid,
    });
  },
  data() {
    return {
      isLive: false,
      isBasic: true,
    };
  },
  setup() {
    const userStore = useUserStore();
    const chartType = ref("today");

    // onBeforeUpdate(() => {
    //   isBasic = true;
    //   isLive = false;
    // })
    /*  watch(
          () => route.name,
          () => {
            console.log(
              "MyCoolComponent - watch route.name changed to ${route.name}"
            );
            // Do something here...
    
            // Optionally you can set immediate: true config for the watcher to run on init
            //}, { immediate: true });
          }
        );  */

    return {
      basic,
      live,
      confirm,
      close,
      userStore,
      chartType,
    };
  },
  /*created() {
        
        //this.openModal();
        this.resetStoreValue();
      }, */
  methods: {
    chartSegmentClick(e) {
      console.log(e.target.value);
      this.chartType = e.target.value;
      // alert(e);
    },
    //   async openModal() {
    //     console.log("userStore.showLaunchPad : " + this.userStore.showLaunchPad);
    //     if (this.userStore.showLaunchPad == false) {
    //       return;
    //     }

    //     const modal = await modalController.create({
    //       component: modalpopup,
    //       cssClass: "modal",
    //     });
    //     modal.present();

    //     modal.onDidDismiss().then((data) => {
    //       //const user = data['data']; // Here's your selected user!
    //       var d = JSON.stringify(data);
    //       console.log("Dismiss Data " + d);
    //       return;
    //     });

    //     this.userStore.setShowLaunchPad(false);
    //   },

    clickBasicTab() {
      this.isBasic = true;
      this.isLive = false;

      //this.openModal();
    },
    clickLiveTab() {
      // isBasic.value = false;
      // isLive.value = true;
      // isTrend.value = false;
      // alert('isLive value ' + isLive.value)
      this.isLive = true;
      this.isBasic = false;
      console.log("this.isLive  " + this.isLive);
    },
    //   const clickTrendTab = () => {
    //     //isBasic.value = false;
    //     isLive = false;
    //     isBasic = false;
    //    // isTrend.value = true;
    //   };

    /*  async resetStoreValue() {
          // const rpmstore = userpmBasicStore();
          // rpmstore.setRpmBasic('');
        }, */
  },
  mounted() {
    const toggle = document.querySelector("ion-tab-bar");
    toggle.setAttribute("selected-tab", "home");

    // this.openModal();
  },
  beforeUpdate() {
    this.isBasic = true;
    this.isLive = false;
  },
});
</script>
<style scoped></style>
