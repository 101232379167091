import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia'
import { createAuth0 } from '@auth0/auth0-vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { IonicVue,getPlatforms  } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { addIcons } from 'ionicons';
import { chatbubbles,send,arrowBack,arrowForward,chevronForwardOutline,personCircle,home,clipboard,calendar,checkbox,people,notifications,school,checkmark,bluetooth,create,book,megaphone,logOutOutline } from 'ionicons/icons';

addIcons({
  home: home,
  clipboard:clipboard,
  calendar:calendar,
  checkbox:checkbox,
  people:people,
  notifications:notifications,
  school:school,
  checkmark:checkmark,
  bluetooth:bluetooth,
  logOutOutline:logOutOutline,
  book:book,
  create:create,
  megaphone:megaphone,
  arrowBack:arrowBack,
  arrowForward:arrowForward,
  chevronForwardOutline:chevronForwardOutline,
  personCircle:personCircle,
  send:send,
  chatbubbles:chatbubbles
});

import VueApexCharts from "vue3-apexcharts";



const pinia = createPinia()
pinia.use(piniaPluginPersistedstate);

const platform = getPlatform()
 const app = createApp(App)
  .use(router)
  .use(pinia)
  .use(IonicVue)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      client_id: process.env.VUE_APP_AUTH0_CLIENTID,
      useRefreshTokens: true,
      redirect_uri: platform == 'ios'? "com.rpmone.app://rpmone.us.auth0.com/capacitor/com.rpmone.app/callback": window.location.origin

    })
  )
  
.use(VueApexCharts)

function getPlatform() {
        let platform;
        let platforms = getPlatforms(); // returns ["iphone", "ios", "mobile", "mobileweb"] from an iPhone
        
        if(platforms.includes('desktop') || platforms.includes('mobileweb') ){
            platform = 'web'
        }
        else if(platforms.includes('ios')){
            platform = 'ios'
        }
        else if(platforms.includes('android')){
            platform = 'android'
        }
        console.log('platform url detected',platform)
       return platform
    }

router.isReady().then(() => {
  app.mount('#app');
});
