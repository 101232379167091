<template>
  <ion-page>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/StaffSchedule"></ion-back-button>
      </ion-buttons>
      <ion-title> Schedule with staff : </ion-title>
    </ion-toolbar>
    <ion-content ref="content" :scroll-y="true" :scrollEvents="true">
      <ion-accordion-group :multiple="true" :value="['first', 'second']">
        <ion-accordion
          value="first"
          :toggle-icon="caretUpOutline"
          toggle-icon-slot="start"
        >
          <ion-item slot="header" color="light" style="font-size: xx-small">
            <ion-label class="accordionLabel">My Staff</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-list>
              <ion-item
                detail="false"
                lines="none"
                v-for="user in this.myStaffDistinctList"
                :key="user.STAFFUSERID"
                class="ion-item ion-no-padding chatuser"
              >
                <ion-avatar slot="start">
                  <img :src="user.PICTURE" />
                </ion-avatar>
                <ion-item
                  style="width: 100%"
                  class="ion-no-padding chatuser"
                  lines="none"
                  @click="sendtoScheduleView(user)"
                >
                  <div>
                    <h4 style="margin: 0px; font-weight: 600; font-size: 18px">
                      {{ user.NICKNAME }}
                    </h4>
                  </div>
                </ion-item>

                <ion-icon
                  :icon="chevronForward"
                  class="icon"
                  style="margin-right: 0px"
                  slot="end"
                >
                </ion-icon>
              </ion-item>
            </ion-list>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-content>
  </ion-page>
</template>

<script>
// import VueCal from 'vue-cal';
// import 'vue-cal/dist/vuecal.css';
import { defineComponent, ref, onMounted } from "vue";
import { useIonRouter } from "@ionic/vue";
import { caretUpOutline, chevronForward } from "ionicons/icons";
//import { CalendarComponent } from 'ionic2-calendar/calendar';
//import { Calendar } from '@ionic-native/calendar';
import {
  IonBackButton,
  IonButtons,
  IonAccordionGroup,
  IonAccordion,
  IonPage,
  IonList,
  IonTitle,
  IonToolbar,
  IonContent,
  IonItem,
  IonLabel,
  IonAvatar,
  IonIcon,
 // IonDatetime,
} from "@ionic/vue";
import { useScheduleStore } from "../stores/schedule";
import { useMqttStore } from "../stores/mqtt";
import { useUserStore } from "../stores/user";
import { useKsqlStore } from "../stores/KSQL";

export default defineComponent({
  name: "scheduleStaff",
  components: {
   // IonDatetime,
    IonBackButton,
    IonButtons,
    IonAccordionGroup,
    IonAccordion,
    IonPage,
    IonList,
    IonTitle,
    IonToolbar,
    IonContent,
    IonItem,
    IonLabel,
    IonAvatar,
    IonIcon,
   // VueCal
  },
  setup() {
    const scheduleStore = useScheduleStore();
    const mqttStore = useMqttStore();
    const userStore = useUserStore();
    const ionRouter = useIonRouter();
    const ksqlStore = useKsqlStore();
    const myStaffDistinctList = ref([]);
    const myStaff = ref(mqttStore.messages.events.ui.patient.patientstaff);
  //   const calendar = {
  //   mode: 'month',
  //   currentDate: new Date(),
  // };

    // console.log(
    //   "messages.events.ui.patient.patientstaff" +
    //     JSON.stringify(mqttStore.messages.events.ui.patient.patientstaff)
    // );

    const isWeekday = (dateString) => {
      const date = new Date(dateString);
      const utcDay = date.getUTCDay();

      /**
       * Date will be enabled if it is not
       * Sunday or Saturday
       */
      return utcDay !== 0 && utcDay !== 6;
    };

    // watchEffect(() => {
    //   mypatients.value = mqttStore.messages.events.ui.staff.mypatients;
    // });

    onMounted(() => {
      // this.unsubscribe(`chat/${this.subscribeToUserId}`);
      //var allusers = mypatients.value;
      if (myStaff.value.length > 0) {
        myStaffDistinctList.value = [
          ...new Map(
            myStaff.value.map((item) => [item["STAFFUSERID"], item])
          ).values(),
        ];
        console.log(
          "this.myStaffDistinctList.value",
          JSON.stringify(myStaffDistinctList.value)
        );
        // myStaff.value.forEach(user => {
        // console.log('calling unsubscrible for user : ' + JSON.stringify(user));
        //mqttStore.unsubscribeMqtt(`chat/#`);
        // const toggle = document.querySelector(".calendar-day");
        // // data-day="18" data-month="1" data-year="2023"
        // let val = toggle?.filter(x=> x['data-day'] = "18" && x['data-month'] == "1" && x['data-year'] =="2023");
        // console.log('val' + JSON.stringify(val));
      }
    });

    return {
      scheduleStore,
      mqttStore,
      userStore,
      ionRouter,
      myStaff,
      caretUpOutline,
      chevronForward,
      myStaffDistinctList,
      ksqlStore,
      isWeekday,
      //VueCal
      //VueCal
      //Calendar
      // calendar,
      // CalendarComponent
    };
  },
  data() {
    return {
      typedInput: "",
    };
  },
  watch: {
    myStaff: {
      handler: function (val, oldVal) {
        // console.log('Watch myStaff val' + JSON.stringify(val));
        console.log("Watch myStaff oldval" + JSON.stringify(oldVal));
        if (this.myStaffDistinctList.length == 0)
          this.myStaffDistinctList.push(val);
        else if (this.myStaffDistinctList.length > 0) {
          // check if
          let record = this.myStaffDistinctList?.value?.filter(
            (x) => x.STAFFUSERID == val.STAFFUSERID
          );
          if (record == null || record == undefined) {
            this.myStaffDistinctList.push(val);
          }
          console.log("val" + JSON.stringify(val));
        }
        // console.log(oldVal);
        // this.mqttStore.unsubscribeMqtt(`chat/#`);
      },

      deep: true,
    },
  },
  methods: {
    // goToPatientDetail(data) {
    //   console.log("data" + JSON.stringify(data));

    //   this.ionRouter.push("/patientDetail/" + data.STAFFUSERID + "/Patient");
    // },

    openModal() {
      alert("OpenModal");
    },

    sendtoScheduleView(user) {
      // console.log("sendtoChatView" + JSON.stringify(user));
      let PATIENTUSERID = user.STAFFUSERID;
      this.ionRouter.push("/schedule/" + PATIENTUSERID + "/patient");
      this.scheduleStore.setSelectedSchedule(user);
      this.ksqlStore.pullSchedulePatient(this.userStore.userId);
    },

    scrollToBottom() {
      // extra ion-item at end is required because this doesn't scroll up enough the see all of last one
      this.$refs.content.$el.scrollToBottom(500);
      console.log(
        "mqttStore.messages.users" +
          JSON.stringify(this.mqttStore.messages.users)
      );
    },
  },
});
</script>
<style scoped>
ion-icon {
  font-size: 12px;
}

ion-item {
  cursor: pointer;

  --background-hover: #ef9116;
}
ion-item:hover {
  cursor: hand;
  --background: #ffb555;
  --background-hover: #ef9116;
}

ion-icon:hover {
  cursor: hand;
  /* background:red; */
}

ion-icon {
  cursor: hand;
  /* background:lightgrey; */
}

.accordionLabel {
  font-size: 12px;
  /* left: 10px; */
  /* float: left; */
  font-family: "OpenSansRegular";
  font-weight: 700;
}
.ion-item {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 2px dotted var(--ion-color-medium);
  /* --background-color:#ef9116; */
  --padding-start: 5px;
  --padding-end: 5px;
}

ion-button {
  /* --background: #ef9116; */
  --color: white;
  font-family: "OpenSansRegular";
  font-style: normal;
  height: 30px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 1px;
}

/* General CSS Setup */
body {
  background-color: lightblue;
  font-family: "Ubuntu-Italic", "Lucida Sans", helvetica, sans;
}

/* container */
.container {
  padding: 5% 5%;
}
</style>
